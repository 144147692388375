import {Component} from '@angular/core';
import {DataSharingService} from "../../../../services/data-sharing.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-creer-une-instance',
    standalone: true,
    imports: [],
    templateUrl: './creer-une-instance.component.html',
    styleUrls: ['./creer-une-instance.component.scss', "../../wikis.scss"]
})
export class CreerUneInstanceComponent {
    constructor(private dataSharingService: DataSharingService, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.data.subscribe(data => {
            this.dataSharingService.setMap(data['isMap']);
        });
    }
}
