import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ServerStat} from "../../services/serveur-sub.service";
import {FormsModule} from "@angular/forms";
import {MainComponents} from "../main.components";
import {ClipboardModule} from '@angular/cdk/clipboard';

@Component({
    selector: 'app-server-edit',
    standalone: true,
    imports: [
        FormsModule,
        ClipboardModule
    ],
    templateUrl: './server-edit.component.html',
    styleUrl: './server-edit.component.scss'
})
export class ServerEditComponent extends MainComponents {
    @Input() server?: ServerStat;
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    close() {
        this.playClickSound();
        this.onClose.emit();
    }
}
