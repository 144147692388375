import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class YoutubeService {

    private googleStatsApi = 'https://www.googleapis.com/youtube/v3/';
    private googleStatsChannelApi = `${this.googleStatsApi}channels`;

    private channelId = environment.youtubeChannelId;
    private apiKey = environment.youtubeApiKey;

    constructor(
        private http: HttpClient,
        private domSanitizer: DomSanitizer) {
    }

    subscriberCount = (): Observable<YoutubeStat> => {
        const params = new HttpParams()
            .set('part', 'statistics')
            .set('id', this.channelId)
            .set('key', this.apiKey);
        const options = {params};
        return this.http.get<YoutubeStat>(this.googleStatsChannelApi, options);
    }

    latestVideos = (channel: string): Observable<YoutubeVideos[]> => {
        const url = `https://golriver.fr/api/youtube/videos/${channel}`;
        return this.http.get<YoutubeVideos[]>(url).pipe(
            map(videos => videos.map(video => {
                video.description = video.description
                    .replaceAll(/(\bhttps?:\/\/[^\s]+)\b/g, (url) => `<a href=${url} target="_blank">${url}</a>`)
                    .replaceAll('\n\n\n', '<br /><br />')
                    .replaceAll('\n\n', '<br />');
                video.published = new Date(video.published);
                video.embeddedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${video.id}?rel=0`);
                return video;
            }))
        );
    }
}

export interface YoutubeStat {
    etag: string;
    kind: string;
    items: YoutubeStatDetails[];
    pageInfo: { resultPerPage: number; totalResults: number };
}

export interface YoutubeStatDetails {
    etag: string;
    id: string;
    kind: string;
    statistics: {
        subscriberCount: string;
        videoCount: string;
        viewCount: string;
        hiddenSubscriber: boolean;
    }
}

export interface YoutubeVideos {
    id: string;
    title: string;
    url: string;
    description: string;
    published: Date;
    views: number;
    like: number;
    thumbnail: string;
    embeddedUrl: SafeResourceUrl;
    display?: boolean;
}
