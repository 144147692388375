<div class="options">
    <div class="minecraft-buttons">
        <div (click)="playClickSound()" [routerLink]="['/options-background']" class="minecraft-button">
            <div class="title">Arrière plan</div>
        </div>
        <div class="minecraft-sub-buttons">
            <div (click)="playClickSound()" [routerLink]="['/resource-pack']" class="minecraft-button">
                <div class="title">Packs de ressources<span class="material-symbols-outlined">texture</span></div>
            </div>
            <div (click)="playClickSound()" [routerLink]="['/shaders']" class="minecraft-button">
                <div class="title">Shaders<span class="material-symbols-outlined">rainy_light</span></div>
            </div>
        </div>
        <div (click)="playClickSound()" [routerLink]="['/home']" class="minecraft-button quitter">
            <div class="title">Terminé</div>
        </div>
    </div>
</div>
