import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DiscordService {
    private apiUrl = 'https://golriver.fr/api/discord/stats';

    constructor(private http: HttpClient) {
    }

    getDiscordData(): Observable<{ memberCount: number, presenceCount: number }> {
        return this.http.get<any>(this.apiUrl).pipe(
            map(response => ({
                memberCount: response.members_count,
                presenceCount: response.members_online
            }))
        );
    }
}
