import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {map, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ResourcePackService {

    private apiUrl = 'https://golriver.fr/api/ressource-pack';

    private minecraftColor = {
        "§0": "#000000",
        "§1": "#0000AA",
        "§2": "#00AA00",
        "§3": "#00AAAA",
        "§4": "#AA0000",
        "§5": "#AA00AA",
        "§6": "#FFAA00",
        "§7": "#AAAAAA",
        "§8": "#555555",
        "§9": "#5555FF",
        "§a": "#55FF55",
        "§b": "#55FFFF",
        "§c": "#FF5555",
        "§d": "#FF55FF",
        "§e": "#FFFF55",
        "§f": "#FFFFFF"
    };

    constructor(
        private http: HttpClient,
        private domSanitizer: DomSanitizer
    ) {
    }

    transformMinecraftText = (input: string): string => {
        return `${input.replaceAll(/§[0-9a-f]/g, match => {
            // @ts-ignore
            return `<span style="color:${this.minecraftColor[match]} !important">`;
        }).replaceAll(/§l/g, match => '')}</span>`;
    }

    resourcePack = () => {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http
            .get(`${this.apiUrl}/resource-pack.json`, {headers})
            .pipe(
                map(response => {
                    Object.keys(response).map((key: string) => {
                        // @ts-ignore
                        response[key].icon = this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.apiUrl}${response[key].basePath}${response[key].icon}`);
                        // @ts-ignore
                        response[key].name = this.domSanitizer.bypassSecurityTrustHtml(this.transformMinecraftText(response[key].name));
                        // @ts-ignore
                        response[key].description = this.domSanitizer.bypassSecurityTrustHtml(this.transformMinecraftText(response[key].description));

                        // @ts-ignore
                        Object.keys(response[key].version).map(version => {
                            // @ts-ignore
                            response[key].version[version].url = this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.apiUrl}${response[key].basePath}${version}/${response[key].version[version].url}`)
                            // @ts-ignore
                            response[key].version[version].compatibleWith = response[key].version[version].compatibleWith.sort((a, b) => a > b ? -1 : 1);
                        })

                    });
                    return response;
                })
            );
    };

    info = (basePath: string, file: string): Observable<Resource> => this.http.get<Resource>(`${this.apiUrl}${basePath}${file}`);
}


export interface Resource {
    [key: string]: ResourceData;
}

export interface ResourceData {
    basePath?: string;
    icon?: SafeResourceUrl;
    name?: string;
    description?: string;
    version: {
        [key: string]: {
            url?: string;
            compatibleWith?: string[];
        }
    };
}
