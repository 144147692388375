<h2>🌟 <b>Les petits + du Serveur Sub Survie</b></h2>
<p>Le Serveur Sub Survie est en Vanilla, mais quelques fonctionnalités ont été ajoutées pour améliorer l’expérience
    : </p>
<ul>
    <li>🎁 <b>Kit de démarrage</b> : Un cadeau de bienvenue pour bien débuter ta survie.</li>
    <li>🪞 <b>Verre instantanément cassé</b> : Gagne du temps dans tes projets.</li>
    <li>⚒️ <b>Crafts customisés</b> : Quelques recettes uniques pour faciliter ton gameplay (<a
        href="#/survival-server-sub/wiki">que tu peux retrouver ici</a>).
    </li>
    <li>📦 <b>Items minés directement dans l’inventaire</b> : Plus besoin de tout ramasser à la main.</li>
    <li>📂 <b>Shulkers automatiques</b> : En renommant une shulker &quot;restock same&quot;, elle se recharge
        automatiquement dans ton inventaire.
    </li>
    <li>🐚 <b>Shulkers Shells gratuites</b> : Disponibles dans l’End aux coordonnées <code>1200/780</code> pour les
        joueurs AFK réguliers.
    </li>
    <li>🔥 <b>Fournaise gratuite</b> : Située dans la zone shop, pour cuire tes items sans effort.</li>
    <li>🏡 <b>Zone Spawn</b> : Construis une maison décorative (pas une base).</li>
</ul>
<hr>
<h3>📜 <b>Documentation et informations sur Discord</b></h3>
<p>Retrouve un document épinglé sur le canal <b>Shops</b> contenant : </p>
<ul>
    <li>📜 <b>Listing des Shops</b> : Coordonnées et items vendus.</li>
    <li>🗺️ <b>Map de la Zone Shops</b> : Met à jour la map avec une bannière bleue au nom de ton shop.</li>
    <li>🚪 <b>Portails dans les tunnels du Nether</b> : Coordonnées des joueurs, à vérifier pour éviter les conflits.
    </li>
    <li>🏠 <b>Bases des joueurs</b> : Contours visibles sur la map en ligne.</li>
</ul>
<p><b>⚠️ N’oublie pas de lire les règles <a href="#/survival-server-sub/regles">ici</a> du serveur
    avant de commencer ton aventure !</b></p>
