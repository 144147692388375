import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MainComponents} from "../main.components";
import {Resource, ResourceData, ResourcePackService} from "../../services/resource-pack.service";

@Component({
    selector: 'app-ressource-pack',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
    ],
    templateUrl: './ressource-pack.component.html',
    styleUrl: './ressource-pack.component.scss'
})
export class RessourcePackComponent extends MainComponents {

    packs?: Resource;
    currentVideos?: ResourceData;

    constructor(private resourcePack: ResourcePackService) {
        super();
        resourcePack.resourcePack().subscribe((result: any) => {
            this.packs = result
        });
    }

    get packKeys() {
        return this.packs ? Object.keys(this.packs) : [];
    }

    get versionKeys() {
        // @ts-ignore
        return this.currentVideos ? Object.keys(this.currentVideos.version) : null;
    }
}
