<img alt="titre regle" class="logo" src="/assets/img/regles.png">
<h1>🌟 <span class="yellow-text">AVANT DE JOUER, MERCI DE BIEN VOULOIR LIRE LE REGLEMENT DU SERVEUR :</span></h1>
<blockquote>
    <p>Avant d'énumérer les règles basiques du serveur Sub, sache que ce n'est pas parce qu'une règle de base n'est pas
        mentionnée ici que tu peux faire n'importe quoi, que ce soit en jeu ou en vocal. Merci d'être au minimum <b>mature</b>,
        <b>logique</b>, et <b>respectueux</b>. </p>
    <p>Toute personne qui joue sur le serveur ou communique sur la section <b>GOLRISUB</b> doit comprendre que ces
        règles s'appliquent. Si nous devons te sanctionner, nous ne te demanderons pas si tu as bien lu les règles :
        elles te seront appliquées. Les règles pourront être mises à jour et, si cela arrive, tu seras informé des
        changements. </p>
</blockquote>
<hr>
<h2>💬 <b>Importance de la courtoisie</b></h2>
<p>Le serveur est avant tout une <b>communauté</b> 🤝. À ce titre, il est important de respecter quelques notions
    élémentaires afin de maintenir une bonne entente lorsque nous jouons ou discutons ensemble. </p>
<p>Bien entendu, il est impossible d'apprécier tout le monde, car nous sommes tous différents, et tu n'es pas dans
    l'obligation de côtoyer tout le monde. Cependant, nous te demandons de rester <b>courtois dans tes propos</b> en
    toute occasion. </p>
<p>🌟 <b>Exemples de base :</b></p>
<ul>
    <li><em>Bonjour</em> 👋</li>
    <li><em>S'il te plait</em> 🙏</li>
    <li><em>Merci</em> 🙌</li>
</ul>
<hr>
<h2>🏗️ <b>Réglementation des constructions en zone commune</b></h2>
<h3>1️⃣ <b>Limitations au niveau du spawn et de la Zone Shop</b></h3>
<p><img alt="Carte du spawn" src="https://golriver.fr/api/wiki/images/spawn_overworld.png"></p>
<p>👉 Merci de respecter : </p>
<ul>
    <li>Les limitations au niveau du <b>Spawn</b>, de la <b>Zone Shop</b> et du <b>Futur Terraforming</b> (se référer au
        schéma ci-dessus).
    </li>
    <li><b>Ne pas construire de base ou de portail du Nether</b> dans le cercle MAGENTA et le cercle JAUNE.</li>
    <li>Essaye de t'éloigner du Spawn et des bases des autres joueurs.</li>
</ul>
<p>💡 En cas de doute, demande à la modération si le lieu est occupé par un autre joueur. </p>
<h3>2️⃣ <b>Hub du Nether et Chemins</b></h3>
<p><img alt="Hub du nether" src="https://golriver.fr/api/wiki/images/spawn_nether.png"></p>
<p>Pour le <b>Hub du Nether</b> et ses chemins, suis ces règles : </p>
<ul>
    <li>Ton portail dans le Nether vers ta base doit être à la couche <b>112</b>.</li>
    <li>Quatre axes principaux (Nord, Sud, Est, Ouest) sont déjà construits. <b>Ne modifie pas ces axes.</b></li>
    <li>Tu pourras te connecter à l'un de ces axes en fonction de ta position dans le Nether.</li>
</ul>
<hr>
<h2>⚖️ <b>Règles et Sanctions</b></h2>
<h3>🔒 <b>1. Vol et destruction</b></h3>
<p><b>Interdiction de voler ou de casser les biens d'autres joueurs sans leur permission.</b></p>
<p>💡 Si tu endommages un bien par accident : </p>
<ul>
    <li><b>Contacte la modération</b> via le salon <a
        href="https://discord.com/channels/747377606479052810/1126579695438266419">support</a>.
    </li>
    <li>Une discussion sera organisée pour convenir d'un délai réaliste pour les réparations.</li>
</ul>
<p><b>Sanctions :</b></p>
<ul>
    <li>🟡 <b>1<sup>er</sup> Vol :</b> Avertissement</li>
    <li>🔴 <b>Si récidive :</b> BAN<sup>1</sup></li>
</ul>
<hr>
<h3>🛠️ <b>2. Utilisation de Mods et outils</b></h3>
<p><b>Interdiction d'utiliser des outils ou mods considérés comme des moyens de triche (exemple : X-ray).</b></p>
<p><b>Sanctions :</b></p>
<ul>
    <li>🟡 <b>1<sup>ère</sup> utilisation :</b> Avertissement</li>
    <li>🔴 <b>Si récidive :</b> BAN<sup>1</sup></li>
</ul>
<hr>
<h3>⚔️ <b>3. Interactions entre Joueurs</b></h3>
<p><b>Interdiction de tuer des joueurs sans raison.</b></p>
<p>💡 <em>Exception</em> : Si les deux joueurs sont d'accord, rendez-vous dans l'<b>arène PvP</b> dans le couloir bleu
    pour vous défouler. </p>
<p><b>Sanctions :</b></p>
<ul>
    <li>🟡 <b>1<sup>ère</sup> infraction :</b> Avertissement</li>
    <li>🔴 <b>Si récidive :</b> BAN<sup>1</sup></li>
</ul>
<hr>
<h3>🏰 <b>4. Utilisation de Builds</b></h3>
<p>Pour respecter les builders : </p>
<ul>
    <li><b>Ne copie pas leurs constructions.</b></li>
    <li>Apporte des modifications notables si tu recrées un build similaire.</li>
    <li>Mentionne le builder originel sur un panneau si le build est très proche.</li>
</ul>
<p><b>Sanctions :</b></p>
<ul>
    <li>🟡 <b>1<sup>ère</sup> infraction :</b> Avertissement</li>
</ul>
<hr>
<h3>💬 <b>5. Respect sur le Tchat</b></h3>
<p><b>Ne pas insulter sur le tchat, Discord, ou en vocal.</b></p>
<p>Interdiction d’avoir des propos inacceptables, haineux ou discriminatoires. </p>
<p><b>Sanctions :</b></p>
<ul>
    <li>🟡 <b>1<sup>ère</sup> infraction :</b> Avertissement</li>
    <li>🔴 <b>Si récidive :</b> BAN<sup>1</sup></li>
</ul>
<hr>
<h3>🐾 <b>6. Gestion des Mobs/Items</b></h3>
<p><b>Interdiction de créer des fermes</b> ou de stocker de grosses quantités de mobs/items au Spawn. </p>
<p><b>Sanctions :</b></p>
<ul>
    <li>🟡 <b>1<sup>ère</sup> infraction :</b> Avertissement</li>
    <li>🔴 <b>Si récidive :</b> BAN<sup>1</sup></li>
</ul>
<hr>
<h2>🛡️ <b>Signalement des infractions</b></h2>
<p>Pour <b>signaler</b> un comportement allant à l'encontre des règles :<br>1️⃣ Ouvre un <b>ticket </b> <a
    href="https://discord.com/channels/747377606479052810/1126579695438266419">ici</a>.<br>2️⃣ Décris le problème
    rencontré et les joueurs concernés. </p>
<p>🎤 <b>Toutes les parties seront entendues avant que des sanctions soient appliquées.</b></p>
<blockquote>
    <p><sup>1</sup> <b>Durée du bannissement :</b> Définie en fonction de la gravité de l'action à sanctionner. </p>
</blockquote>
