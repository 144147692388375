<div class="ressouce-pack">
    <div class="contenu"> <!--PARTIE SUPERIEURE-->
        <div class="left-content"> <!--// PARTIE DROITE + SCROLL-->
            <div class="version-container"> <!--// PARTIE DROITE-->
                <div (click)="playClickSound()" class="versions">
                    <ng-container *ngIf="packs">
                        <div (click)="currentVideos=packs[key]" *ngFor="let key of packKeys"
                             [class.current]="currentVideos===packs[key]"
                             class="version">
                            <div class="version-icon arrow">
                                <img [src]="packs[key].icon" alt="Icone">
                            </div>
                            <div class="version-info">
                                <div [innerHTML]="packs[key].name" class="title"></div>
                                <div [innerHTML]="packs[key].description" class="description">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="right-content">
            <div class="version-container">
                <div *ngIf="currentVideos" class="versions">
                    <a (click)="playClickSound()" *ngFor="let version of versionKeys"
                       [href]="currentVideos.version[version].url" download>
                        <div class="version">
                            <div class="version-icon arrow downloadable">
                                <img [src]="currentVideos.icon" alt="Icone"/>
                                <div [textContent]="version" class="version-value"></div>
                            </div>
                            <div (click)="playClickSound()" class="version-infos">
                                Compatible avec :
                                <div class="version-compatibility">
                                    <div *ngFor="let minecraftVersion of currentVideos.version[version].compatibleWith"
                                         [textContent]="minecraftVersion"
                                         class="minecraft-version"></div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="minecraft-buttons">
        <div (click)="playClickSound()" [routerLink]="['/options']" class="minecraft-button quitter">
            <div class="title">Terminé</div>
        </div>
    </div>
</div>
