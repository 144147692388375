<img alt="titre instance" class="logo" src="/assets/img/creer-une-instance.png">
<h3><b><span
    class="yellow-text">GUIDE POUR CRÉER UNE INSTANCE EN VERSION 1.21.4 ET INSTALLER LES MODS</span></b> 🌟</h3>
<p><b><span class="yellow-text">⚠️ Les captures de ce tutoriel ont été faites pour la 1.21 mais reste les mêmes pour la 1.21.4</span></b>
</p>
<hr>
<h2><b>Étape 1 : CRÉATION D’UNE INSTANCE EN VERSION 1.21.4</b> 🛠️</h2>
<p>Pour te connecter au serveur SUB de la <b>CHOMAGERIE</b>, suis ces étapes depuis le <b>Launcher
    Minecraft</b> :</p>
<p>1️⃣ <b>Clique sur Minecraft Java Edition</b> :</p>
<p><img alt="Instance1" src="https://golriver.fr/api/wiki/images/launcher_choose_java.png"></p>
<p>2️⃣ <b>Va dans l’onglet Configurations</b>, renomme la configuration (par exemple : 1.21.4), puis clique sur
    <b>Nouvelle configuration</b>.</p>
<p><img alt="Instance2" src="https://golriver.fr/api/wiki/images/btn_create_instance.png"></p>
<p>3️⃣ Dans la nouvelle page :</p>
<ul>
    <li><b>Nom</b> : Renomme en <b>1.21.4</b>.</li>
    <li><b>Version</b> : Sélectionne <b>release 1.21.4</b> dans le menu déroulant.</li>
    <li><p>Clique sur <b>Créer</b>.</p>
        <p><img alt="Instance3" src="https://golriver.fr/api/wiki/images/creation_instance.png"></p>
    </li>
</ul>
<p>4️⃣ Lance Minecraft 1.21.4 une première fois en cliquant sur <b>Jouer</b>.</p>
<p><img alt="Instance4" src="https://golriver.fr/api/wiki/images/launch_fabric_first.png"></p>
<p><b><span>⚠️ Une fois la partie lancée, ferme et quitte le jeu ainsi que le launcher.</span></b></p>
<hr>
<h2><b>Étape 2 : INSTALLATION DU FABRIC LAUNCHER</b> 🚀</h2>
<p>1️⃣ Télécharge <b>Fabric Launcher</b> en cliquant sur &quot;<b>Download for Windows</b>&quot;
    depuis le lien suivant : <a href="https://fabricmc.net/use/installer/">Lien Fabric</a></p>
<p><img alt="Fabric1" src="https://golriver.fr/api/wiki/images/download_fabric_web.png"><br> <img
    alt="Fabric2" src="https://golriver.fr/api/wiki/images/download_fabric.png"></p>
<p>2️⃣ Lance le fichier téléchargé (<b>fabric-installer-1.0.1.exe</b>).</p>
<p><img alt="Fabric3" src="https://golriver.fr/api/wiki/images/instal_fabric.png"></p>
<p>3️⃣ Sélectionne <b>Minecraft 1.21.4</b> dans le menu déroulant, puis clique sur <b>Installer.</b>
    <em><span>❌ Ne touche pas aux autres options !</span></em></p>
<p>4️⃣ Retourne dans ton <b>Launcher Minecraft</b>, sélectionne la version <b>fabric-loader-1.21.4</b>,
    et lance-la une première fois en cliquant sur <b>Jouer</b>.</p>
<p><img alt="Fabric4" src="https://golriver.fr/api/wiki/images/menu_launcher.png"></p>
<p>5️⃣ ✅ <b>Si tout fonctionne, ferme le jeu.</b></p>
<hr>
<h2><b>Étape 3 : INSTALLATION DES MODS</b> 🎮</h2>
<h3><b>Pour accéder au dossier .minecraft</b> 📁</h3>
<h4><b>Méthode 1</b> :</h4>
<p>1️⃣ Appuie sur les touches <b>Win+R</b>.</p>
<p><img alt="Minecraft1" src="https://golriver.fr/api/wiki/images/clavier.png"></p>
<p>2️⃣ Tape <b>%appdata%</b>, puis clique sur <b>OK</b>.</p>
<p><img alt="Minecraft2" src="https://golriver.fr/api/wiki/images/exec_appdata.png"></p>
<h4><b>Méthode 2</b> :</h4>
<p>Dans ton Launcher Minecraft, retrouve les paramètres de ta version fabric-loader-1.21.4 et accède au chemin du
    dossier
    .minecraft.<br> <img alt="Minecraft3" src="https://golriver.fr/api/wiki/images/btn_instance_dossier.png"></p>
<hr>
<h3><b>Téléchargement des Mods</b> 🧩</h3>
<p>1️⃣ Dans le dossier <b>.minecraft</b>, ouvre ou crée un sous-dossier nommé <b>mods</b>.</p>
<p><img alt="Mods1" src="https://golriver.fr/api/wiki/images/dossier_mods.png"></p>
<p>2️⃣ Ajoute-y les mods suivants :</p>
<ul>
    <li><a href="https://modrinth.com/mod/fabric-api/versions?l=fabric&amp;g=1.21.4">Fabric API (1.21.4)</a></li>
    <li><a href="https://modrinth.com/mod/sodium/versions?l=fabric&amp;g=1.21.4">Sodium (1.21.4)</a></li>
    <li><a href="https://modrinth.com/plugin/simple-voice-chat/versions?g=1.21.4&amp;l=fabric">Simple Voice Chat
        (1.21.4)</a></li>
</ul>
<hr>
<p>🎉 <b>Tu es prêt à rejoindre le serveur SUB ! Amuse-toi bien !</b></p>
<h1>Voici l'étape suivante : <a href="home/Serveur-Sub-Survie/Comment-tu-peux-nous-rejoindre/Comment-se-connecter">Comment
    se connecter ?</a></h1>
