<img alt="titre outils" class="logo" src="/assets/img/outils.png">
<h2><strong>Ressources utiles pour ta survie sur le serveur</strong> 🛠️</h2>
<p>Pour t'aider à progresser sur le serveur et dans ton aventure Minecraft, voici quelques liens pratiques :</p>
<ul>
    <li>🌍 <a href="https://chomagerie.golriver.fr"><strong>Map GOLRIVER</strong></a> - Explore la carte du serveur
        GOLRIVER.
    </li>
    <li>📚 <a href="https://minecraft.wiki/"><strong>Wiki Minecraft</strong></a> - Une ressource complète pour tout
        savoir sur Minecraft.
    </li>
    <li>🔮 <a href="https://iamcal.github.io/enchant-order/"><strong>Minecraft Enchantment Ordering Tool</strong></a> -
        Outil pour optimiser tes enchantements dans Minecraft.
    </li>
    <li>🎨 <a href="https://blockcolors.app/"><strong>Block Colors</strong></a> - Découvre les couleurs des blocs dans
        Minecraft.
    </li>
    <li>🏗️ <a href="https://www.blockpalettes.com/"><strong>Block Palettes</strong></a> - Une ressource pour trouver des
        palettes de blocs pour vos constructions.
    </li>
    <li>📖 <a href="https://joakimthorsen.github.io/MCPropertyEncyclopedia/"><strong>Minecraft Block Property
        Encyclopedia</strong></a> - En savoir plus sur les propriétés des blocs Minecraft.
    </li>
    <li>🗺️ <a href="https://www.chunkbase.com/apps/seed-map#55145150"><strong>Chunkbase avec la seed du serveur</strong></a>
        - Visualise la seed du serveur sur Chunkbase pour mieux te repérer.
    </li>
</ul>
<p>Ces liens te fourniront tout ce dont tu as besoin pour être prêt à tout sur le serveur ! 🎮</p>
