<div id="server-list">
    <ng-container *ngIf="!currentServer || !showEdit; else editServer ">
        <div class="list"><h3>Multijoueurs</h3>
            <div class="servers">
                <ng-container *ngFor="let server of (servers | async)">
                    <div (click)="goToServer(server)" [class.current]="currentServer === server" class="server">
                        <div (click)="playClickSound()" [routerLink]="server.navigateTo" class="server-icon arrow"><img
                            [src]="server.icon" alt="icone du serveur"></div>
                        <div class="server-info">
                            <div [textContent]="server.name" class="name"></div>
                            <div [innerHTML]="server.motd.html[0]" class="title"></div>
                            <div [innerHTML]="server.motd.html[1]" class="subtitle"></div>
                        </div>
                        <div class="players">
                            <div [title]="server.playersName" class="player-count"><span
                                [textContent]="server.players.length"></span>/<span
                                [textContent]="server.limit"></span></div>
                            <img alt="icon de ping" src="/assets/img/ping.png" title="21ms"/></div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="actions">
            <div class="minecraft-buttons">
                <div (click)="playClickSound()" [routerLink]="currentServer?.navigateTo" class="minecraft-button">
                    <div class="title">Rejoindre le serveur</div>
                </div>
                <div class="minecraft-sub-buttons">
                    <div (click)="currentServer && toggleEdit()" class="minecraft-button">
                        <div class="title">Éditer <span class="material-symbols-outlined">edit</span></div>
                    </div>
                    <div (click)="refrechServerStat()" class="minecraft-button">
                        <div class="title">Actualiser <span class="material-symbols-outlined">refresh</span></div>
                    </div>
                    <div (click)="playClickSound()" [routerLink]="['/home']" class="minecraft-button">
                        <div class="title">Retour<span class="material-symbols-outlined">undo</span></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #editServer>
        <app-server-edit (onClose)="showEdit=false" [server]="currentServer">
        </app-server-edit>
    </ng-template>
</div>
