import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CapeService {

    api = 'https://api.capes.dev/load/golriver';

    constructor(private http: HttpClient) {
    }

    capeUrl = () => {
        return this.http.get(this.api)
            .pipe(map((data: any) => {
                return data['minecraft']['imageUrl'];
            }));
    }
}
