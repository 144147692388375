import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RouterLink} from '@angular/router';
import {TwitchService} from '../../services/twitch.service';
import {CommonModule} from '@angular/common';
import {environment} from '../../environments/environment';
import {SkinRenderComponent} from "../skin-render/skin-render.component";
import {MainComponents} from "../main.components";

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [RouterLink, CommonModule, SkinRenderComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends MainComponents implements OnInit {
    twitchInfo: any;
    twitchStreamer = environment.twitchStreamer;
    youtubeStreamer = environment.youtubeStreamer;
    splashMessage: string = '';

    private messages: string[] = [
        'Point faible, trop fort.',
        'Un jour ce sera fait, un jour ...',
        'Squalala !!',
        'On va faire un p\'tit build.',
        'Si ça marche, je suis un génie ; si ça marche pas... c\'est de la faute du jeu.',
        'Je suis un peu marseillais, je sais.',
        'C\'est Stonks !',
        'Je crois que je viens d\'inventer un nouveau bug.',
        'M0jang : \'Pourquoi réparer un bug quand on peut en ajouter trois nouveaux ?\'',
        'Il est mauvais le type, il est mauvais !',
        'Ce s\'rait trop stylée en vrai.',
        'J\'reviens faut que je sorte le chien.',
        'A quand l\'ender Update ? M0jang !!',
        'J\'comprend rien moi, j\'comprend rien.'
    ];

    constructor(private twitch: TwitchService) {
        super();
    }

    ngOnInit() {
        this.splashMessage = this.getRandomMessage();
        this.twitch.authentificate().subscribe(token => {
            this.twitch.channelInfo(token.access_token).subscribe((info: any) => this.twitchInfo = info.data[0]);
        });
    }

    getRandomMessage(): string {
        const randomIndex = Math.floor(Math.random() * this.messages.length);
        return this.messages[randomIndex];
    }

    close = () => {
        this.playClickSound();
        window.location.href = "https://www.google.com";
        close();
    };
}
