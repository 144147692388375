<div class="options">
    <div class="header">
        <div class="titre">
            Arrière plan
        </div>
        <div class="legende">
            Selectionnez
        </div>
    </div>
    <div class="options">
        <ng-container>
            <div class="options-list">
                <div (click)="currentBackground=bg" *ngFor="let bg of (backgrounds|async)"
                     [class.current]="currentBackground===bg"
                     class="option">
                    <div class="option-info">
                        <div [innerHTML]="bg.name" class="title"></div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="buttons">
        <div (click)="playClickSound()" [routerLink]="['/options']" class="button">Retour</div>
        <div (click)="updateBackground()" class="button">Séléctionner</div>
    </div>
</div>
