export const TRANSLATE: { [key: string]: string } = {
    "minecraft:acacia_button": "Bouton en acacia",
    "minecraft:acacia_door": "Porte en acacia",
    "minecraft:acacia_fence": "Barrière en acacia",
    "minecraft:acacia_fence_gate": "Portillon en acacia",
    "minecraft:acacia_hanging_sign": "Pancarte suspendue en acacia",
    "minecraft:acacia_leaves": "Feuilles d'acacia",
    "minecraft:acacia_log": "Bûche d'acacia",
    "minecraft:acacia_planks": "Planches d'acacia",
    "minecraft:acacia_pressure_plate": "Plaque de pression en acacia",
    "minecraft:acacia_sapling": "Pousse d'acacia",
    "minecraft:acacia_sign": "Pancarte en acacia",
    "minecraft:acacia_slab": "Dalle en acacia",
    "minecraft:acacia_stairs": "Escalier en acacia",
    "minecraft:acacia_trapdoor": "Trappe en acacia",
    "minecraft:acacia_wall_hanging_sign": "Pancarte suspendue en acacia murale",
    "minecraft:acacia_wall_sign": "Pancarte en acacia murale",
    "minecraft:acacia_wood": "Bois d'acacia",
    "minecraft:activator_rail": "Rails déclencheurs",
    "minecraft:air": "Air",
    "minecraft:allium": "Allium",
    "minecraft:amethyst_block": "Bloc d'améthyste",
    "minecraft:amethyst_cluster": "Amas d'améthyste",
    "minecraft:ancient_debris": "Débris antiques",
    "minecraft:andesite": "Andésite",
    "minecraft:andesite_slab": "Dalle d'andésite",
    "minecraft:andesite_stairs": "Escalier d'andésite",
    "minecraft:andesite_wall": "Muret d'andésite",
    "minecraft:anvil": "Enclume",
    "minecraft:attached_melon_stem": "Tige de pastèque attachée",
    "minecraft:attached_pumpkin_stem": "Tige de citrouille attachée",
    "minecraft:azalea": "Azalée",
    "minecraft:azalea_leaves": "Feuilles d'azalée",
    "minecraft:azure_bluet": "Houstonie bleue",
    "minecraft:bamboo": "Bambou",
    "minecraft:bamboo_block": "Bloc de bambou",
    "minecraft:bamboo_button": "Bouton en bambou",
    "minecraft:bamboo_door": "Porte en bambou",
    "minecraft:bamboo_fence": "Barrière en bambou",
    "minecraft:bamboo_fence_gate": "Portillon en bambou",
    "minecraft:bamboo_hanging_sign": "Pancarte suspendue en bambou",
    "minecraft:bamboo_mosaic": "Mosaïque de bambou",
    "minecraft:bamboo_mosaic_slab": "Dalle en mosaïque de bambou",
    "minecraft:bamboo_mosaic_stairs": "Escalier en mosaïque de bambou",
    "minecraft:bamboo_planks": "Planches de bambou",
    "minecraft:bamboo_pressure_plate": "Plaque de pression en bambou",
    "minecraft:bamboo_sapling": "Pousse de bambou",
    "minecraft:bamboo_sign": "Pancarte en bambou",
    "minecraft:bamboo_slab": "Dalle en bambou",
    "minecraft:bamboo_stairs": "Escalier en bambou",
    "minecraft:bamboo_trapdoor": "Trappe en bambou",
    "minecraft:bamboo_wall_hanging_sign": "Pancarte suspendue en bambou murale",
    "minecraft:bamboo_wall_sign": "Pancarte en bambou murale",
    "minecraft:banner.base.black": "Champ de sable plain",
    "minecraft:banner.base.blue": "Champ d'azur plain",
    "minecraft:banner.base.brown": "Champ de tanné plain",
    "minecraft:banner.base.cyan": "Champ de cyan plain",
    "minecraft:banner.base.gray": "Champ d'acier plain",
    "minecraft:banner.base.green": "Champ de sinople plain",
    "minecraft:banner.base.light_blue": "Champ de bleu céleste plain",
    "minecraft:banner.base.light_gray": "Champ d'argent grisé plain",
    "minecraft:banner.base.lime": "Champ de sinople clair plain",
    "minecraft:banner.base.magenta": "Champ de pourpre plain",
    "minecraft:banner.base.orange": "Champ d'orangé plain",
    "minecraft:banner.base.pink": "Champ de carnation plain",
    "minecraft:banner.base.purple": "Champ de mûre plain",
    "minecraft:banner.base.red": "Champ de gueules plain",
    "minecraft:banner.base.white": "Champ d'argent plain",
    "minecraft:banner.base.yellow": "Champ d'or plain",
    "minecraft:banner.border.black": "Bordure de sable",
    "minecraft:banner.border.blue": "Bordure d'azur",
    "minecraft:banner.border.brown": "Bordure de tanné",
    "minecraft:banner.border.cyan": "Bordure de cyan",
    "minecraft:banner.border.gray": "Bordure d'acier",
    "minecraft:banner.border.green": "Bordure de sinople",
    "minecraft:banner.border.light_blue": "Bordure de bleu céleste",
    "minecraft:banner.border.light_gray": "Bordure d'argent grisé",
    "minecraft:banner.border.lime": "Bordure de sinople clair",
    "minecraft:banner.border.magenta": "Bordure de pourpre",
    "minecraft:banner.border.orange": "Bordure d'orangé",
    "minecraft:banner.border.pink": "Bordure de carnation",
    "minecraft:banner.border.purple": "Bordure de mûre",
    "minecraft:banner.border.red": "Bordure de gueules",
    "minecraft:banner.border.white": "Bordure d'argent",
    "minecraft:banner.border.yellow": "Bordure d'or",
    "minecraft:banner.bricks.black": "Champ maçonné de sable",
    "minecraft:banner.bricks.blue": "Champ maçonné d'azur",
    "minecraft:banner.bricks.brown": "Champ maçonné de tanné",
    "minecraft:banner.bricks.cyan": "Champ maçonné de cyan",
    "minecraft:banner.bricks.gray": "Champ maçonné d'acier",
    "minecraft:banner.bricks.green": "Champ maçonné de sinople",
    "minecraft:banner.bricks.light_blue": "Champ maçonné de bleu céleste",
    "minecraft:banner.bricks.light_gray": "Champ maçonné d'argent grisé",
    "minecraft:banner.bricks.lime": "Champ maçonné sinople clair",
    "minecraft:banner.bricks.magenta": "Champ maçonné de pourpre",
    "minecraft:banner.bricks.orange": "Champ maçonné d'orangé",
    "minecraft:banner.bricks.pink": "Champ maçonné de carnation",
    "minecraft:banner.bricks.purple": "Champ maçonné de mûre",
    "minecraft:banner.bricks.red": "Champ maçonné de gueules",
    "minecraft:banner.bricks.white": "Champ maçonné d'argent",
    "minecraft:banner.bricks.yellow": "Champ maçonné d'or",
    "minecraft:banner.circle.black": "Tourteau de sable",
    "minecraft:banner.circle.blue": "Tourteau d'azur",
    "minecraft:banner.circle.brown": "Tourteau de tanné",
    "minecraft:banner.circle.cyan": "Tourteau de cyan",
    "minecraft:banner.circle.gray": "Tourteau d'acier",
    "minecraft:banner.circle.green": "Tourteau de sinople",
    "minecraft:banner.circle.light_blue": "Tourteau de bleu céleste",
    "minecraft:banner.circle.light_gray": "Tourteau d'argent grisé",
    "minecraft:banner.circle.lime": "Tourteau de sinople clair",
    "minecraft:banner.circle.magenta": "Tourteau de pourpre",
    "minecraft:banner.circle.orange": "Tourteau d'orangé",
    "minecraft:banner.circle.pink": "Tourteau de carnation",
    "minecraft:banner.circle.purple": "Tourteau de mûre",
    "minecraft:banner.circle.red": "Tourteau de gueules",
    "minecraft:banner.circle.white": "Tourteau d'argent",
    "minecraft:banner.circle.yellow": "Tourteau d'or",
    "minecraft:banner.creeper.black": "Face de Creeper de sable",
    "minecraft:banner.creeper.blue": "Face de Creeper d'azur",
    "minecraft:banner.creeper.brown": "Face de Creeper de tanné",
    "minecraft:banner.creeper.cyan": "Face de Creeper de cyan",
    "minecraft:banner.creeper.gray": "Face de Creeper d'acier",
    "minecraft:banner.creeper.green": "Face de Creeper de sinople",
    "minecraft:banner.creeper.light_blue": "Face de Creeper de bleu céleste",
    "minecraft:banner.creeper.light_gray": "Face de Creeper d'argent grisé",
    "minecraft:banner.creeper.lime": "Face de Creeper de sinople clair",
    "minecraft:banner.creeper.magenta": "Face de Creeper de pourpre",
    "minecraft:banner.creeper.orange": "Face de Creeper d'orangé",
    "minecraft:banner.creeper.pink": "Face de Creeper de carnation",
    "minecraft:banner.creeper.purple": "Face de Creeper de mûre",
    "minecraft:banner.creeper.red": "Face de Creeper de gueules",
    "minecraft:banner.creeper.white": "Face de Creeper d'argent",
    "minecraft:banner.creeper.yellow": "Face de Creeper d'or",
    "minecraft:banner.cross.black": "Sautoir de sable",
    "minecraft:banner.cross.blue": "Sautoir d'azur",
    "minecraft:banner.cross.brown": "Sautoir de tanné",
    "minecraft:banner.cross.cyan": "Sautoir de cyan",
    "minecraft:banner.cross.gray": "Sautoir d'acier",
    "minecraft:banner.cross.green": "Sautoir de sinople",
    "minecraft:banner.cross.light_blue": "Sautoir de bleu céleste",
    "minecraft:banner.cross.light_gray": "Sautoir d'argent grisé",
    "minecraft:banner.cross.lime": "Sautoir de sinople clair",
    "minecraft:banner.cross.magenta": "Sautoir de pourpre",
    "minecraft:banner.cross.orange": "Sautoir d'orangé",
    "minecraft:banner.cross.pink": "Sautoir de carnation",
    "minecraft:banner.cross.purple": "Sautoir de mûre",
    "minecraft:banner.cross.red": "Sautoir de gueules",
    "minecraft:banner.cross.white": "Sautoir d'argent",
    "minecraft:banner.cross.yellow": "Sautoir d'or",
    "minecraft:banner.curly_border.black": "Bordure cannelée de sable",
    "minecraft:banner.curly_border.blue": "Bordure cannelée d'azur",
    "minecraft:banner.curly_border.brown": "Bordure cannelée de tanné",
    "minecraft:banner.curly_border.cyan": "Bordure cannelée de cyan",
    "minecraft:banner.curly_border.gray": "Bordure cannelée d'acier",
    "minecraft:banner.curly_border.green": "Bordure cannelée de sinople",
    "minecraft:banner.curly_border.light_blue": "Bordure cannelée de bleu céleste",
    "minecraft:banner.curly_border.light_gray": "Bordure cannelée d'argent grisé",
    "minecraft:banner.curly_border.lime": "Bordure cannelée de sinople clair",
    "minecraft:banner.curly_border.magenta": "Bordure cannelée de pourpre",
    "minecraft:banner.curly_border.orange": "Bordure cannelée d'orangé",
    "minecraft:banner.curly_border.pink": "Bordure cannelée de carnation",
    "minecraft:banner.curly_border.purple": "Bordure cannelée de mûre",
    "minecraft:banner.curly_border.red": "Bordure cannelée de gueules",
    "minecraft:banner.curly_border.white": "Bordure cannelée d'argent",
    "minecraft:banner.curly_border.yellow": "Bordure cannelée d'or",
    "minecraft:banner.diagonal_left.black": "Taillé, au premier de sable",
    "minecraft:banner.diagonal_left.blue": "Taillé, au premier d'azur",
    "minecraft:banner.diagonal_left.brown": "Taillé, au premier de tanné",
    "minecraft:banner.diagonal_left.cyan": "Taillé, au premier de cyan",
    "minecraft:banner.diagonal_left.gray": "Taillé, au premier d'acier",
    "minecraft:banner.diagonal_left.green": "Taillé, au premier de sinople",
    "minecraft:banner.diagonal_left.light_blue": "Taillé, au premier de bleu céleste",
    "minecraft:banner.diagonal_left.light_gray": "Taillé, au premier d'argent grisé",
    "minecraft:banner.diagonal_left.lime": "Taillé, au premier de sinople clair",
    "minecraft:banner.diagonal_left.magenta": "Taillé, au premier de pourpre",
    "minecraft:banner.diagonal_left.orange": "Taillé, au premier d'orangé",
    "minecraft:banner.diagonal_left.pink": "Taillé, au premier de carnation",
    "minecraft:banner.diagonal_left.purple": "Taillé, au premier de mûre",
    "minecraft:banner.diagonal_left.red": "Taillé, au premier de gueules",
    "minecraft:banner.diagonal_left.white": "Taillé, au premier d'argent",
    "minecraft:banner.diagonal_left.yellow": "Taillé, au premier d'or",
    "minecraft:banner.diagonal_right.black": "Tranché, au premier de sable",
    "minecraft:banner.diagonal_right.blue": "Tranché, au premier d'azur",
    "minecraft:banner.diagonal_right.brown": "Tranché, au premier de tanné",
    "minecraft:banner.diagonal_right.cyan": "Tranché, au premier de cyan",
    "minecraft:banner.diagonal_right.gray": "Tranché, au premier d'acier",
    "minecraft:banner.diagonal_right.green": "Tranché, au premier de sinople",
    "minecraft:banner.diagonal_right.light_blue": "Tranché, au premier de bleu céleste",
    "minecraft:banner.diagonal_right.light_gray": "Tranché, au premier d'argent grisé",
    "minecraft:banner.diagonal_right.lime": "Tranché, au premier de sinople clair",
    "minecraft:banner.diagonal_right.magenta": "Tranché, au premier de pourpre",
    "minecraft:banner.diagonal_right.orange": "Tranché, au premier d'orangé",
    "minecraft:banner.diagonal_right.pink": "Tranché, au premier de carnation",
    "minecraft:banner.diagonal_right.purple": "Tranché, au premier de mûre",
    "minecraft:banner.diagonal_right.red": "Tranché, au premier de gueules",
    "minecraft:banner.diagonal_right.white": "Tranché, au premier d'argent",
    "minecraft:banner.diagonal_right.yellow": "Tranché, au premier d'or",
    "minecraft:banner.diagonal_up_left.black": "Tranché, au second de sable",
    "minecraft:banner.diagonal_up_left.blue": "Tranché, au second d'azur",
    "minecraft:banner.diagonal_up_left.brown": "Tranché, au second de tanné",
    "minecraft:banner.diagonal_up_left.cyan": "Tranché, au second de cyan",
    "minecraft:banner.diagonal_up_left.gray": "Tranché, au second d'acier",
    "minecraft:banner.diagonal_up_left.green": "Tranché, au second de sinople",
    "minecraft:banner.diagonal_up_left.light_blue": "Tranché, au second de bleu céleste",
    "minecraft:banner.diagonal_up_left.light_gray": "Tranché, au second d'argent grisé",
    "minecraft:banner.diagonal_up_left.lime": "Tranché, au second de sinople clair",
    "minecraft:banner.diagonal_up_left.magenta": "Tranché, au second de pourpre",
    "minecraft:banner.diagonal_up_left.orange": "Tranché, au second d'orangé",
    "minecraft:banner.diagonal_up_left.pink": "Tranché, au second de carnation",
    "minecraft:banner.diagonal_up_left.purple": "Tranché, au second de mûre",
    "minecraft:banner.diagonal_up_left.red": "Tranché, au second de gueules",
    "minecraft:banner.diagonal_up_left.white": "Tranché, au second d'argent",
    "minecraft:banner.diagonal_up_left.yellow": "Tranché, au second d'or",
    "minecraft:banner.diagonal_up_right.black": "Taillé, au second de sable",
    "minecraft:banner.diagonal_up_right.blue": "Taillé, au second d'azur",
    "minecraft:banner.diagonal_up_right.brown": "Taillé, au second de tanné",
    "minecraft:banner.diagonal_up_right.cyan": "Taillé, au second de cyan",
    "minecraft:banner.diagonal_up_right.gray": "Taillé, au second d'acier",
    "minecraft:banner.diagonal_up_right.green": "Taillé, au second de sinople",
    "minecraft:banner.diagonal_up_right.light_blue": "Taillé, au second de bleu céleste",
    "minecraft:banner.diagonal_up_right.light_gray": "Taillé, au second d'argent grisé",
    "minecraft:banner.diagonal_up_right.lime": "Taillé, au second de sinople clair",
    "minecraft:banner.diagonal_up_right.magenta": "Taillé, au second de pourpre",
    "minecraft:banner.diagonal_up_right.orange": "Taillé, au second d'orangé",
    "minecraft:banner.diagonal_up_right.pink": "Taillé, au second de carnation",
    "minecraft:banner.diagonal_up_right.purple": "Taillé, au second de mûre",
    "minecraft:banner.diagonal_up_right.red": "Taillé, au second de gueules",
    "minecraft:banner.diagonal_up_right.white": "Taillé, au second d'argent",
    "minecraft:banner.diagonal_up_right.yellow": "Taillé, au second d'or",
    "minecraft:banner.flower.black": "Fleur de sable",
    "minecraft:banner.flower.blue": "Fleur d'azur",
    "minecraft:banner.flower.brown": "Fleur de tanné",
    "minecraft:banner.flower.cyan": "Fleur de cyan",
    "minecraft:banner.flower.gray": "Fleur d'acier",
    "minecraft:banner.flower.green": "Fleur de sinople",
    "minecraft:banner.flower.light_blue": "Fleur de bleu céleste",
    "minecraft:banner.flower.light_gray": "Fleur d'argent grisé",
    "minecraft:banner.flower.lime": "Fleur de sinople clair",
    "minecraft:banner.flower.magenta": "Fleur de pourpre",
    "minecraft:banner.flower.orange": "Fleur d'orangé",
    "minecraft:banner.flower.pink": "Fleur de carnation",
    "minecraft:banner.flower.purple": "Fleur de mûre",
    "minecraft:banner.flower.red": "Fleur de gueules",
    "minecraft:banner.flower.white": "Fleur d'argent",
    "minecraft:banner.flower.yellow": "Fleur d'or",
    "minecraft:banner.globe.black": "Globe de sable",
    "minecraft:banner.globe.blue": "Globe d'azur",
    "minecraft:banner.globe.brown": "Globe de tanné",
    "minecraft:banner.globe.cyan": "Globe de cyan",
    "minecraft:banner.globe.gray": "Globe d'acier",
    "minecraft:banner.globe.green": "Globe de sinople",
    "minecraft:banner.globe.light_blue": "Globe de bleu céleste",
    "minecraft:banner.globe.light_gray": "Globe d'argent grisé",
    "minecraft:banner.globe.lime": "Globe de sinople clair",
    "minecraft:banner.globe.magenta": "Globe de pourpre",
    "minecraft:banner.globe.orange": "Globe d'orangé",
    "minecraft:banner.globe.pink": "Globe de carnation",
    "minecraft:banner.globe.purple": "Globe de mûre",
    "minecraft:banner.globe.red": "Globe de gueules",
    "minecraft:banner.globe.white": "Globe d'argent",
    "minecraft:banner.globe.yellow": "Globe d'or",
    "minecraft:banner.gradient.black": "Dégradé de sable",
    "minecraft:banner.gradient.blue": "Dégradé d'azur",
    "minecraft:banner.gradient.brown": "Dégradé de tanné",
    "minecraft:banner.gradient.cyan": "Dégradé de cyan",
    "minecraft:banner.gradient.gray": "Dégradé d'acier",
    "minecraft:banner.gradient.green": "Dégradé de sinople",
    "minecraft:banner.gradient.light_blue": "Dégradé de bleu céleste",
    "minecraft:banner.gradient.light_gray": "Dégradé d'argent grisé",
    "minecraft:banner.gradient.lime": "Dégradé de sinople clair",
    "minecraft:banner.gradient.magenta": "Dégradé de pourpre",
    "minecraft:banner.gradient.orange": "Dégradé d'orangé",
    "minecraft:banner.gradient.pink": "Dégradé de carnation",
    "minecraft:banner.gradient.purple": "Dégradé de mûre",
    "minecraft:banner.gradient.red": "Dégradé de gueules",
    "minecraft:banner.gradient.white": "Dégradé d'argent",
    "minecraft:banner.gradient.yellow": "Dégradé d'or",
    "minecraft:banner.gradient_up.black": "Dégradé de sable en pointe",
    "minecraft:banner.gradient_up.blue": "Dégradé d'azur en pointe",
    "minecraft:banner.gradient_up.brown": "Dégradé de tanné en pointe",
    "minecraft:banner.gradient_up.cyan": "Dégradé de cyan en pointe",
    "minecraft:banner.gradient_up.gray": "Dégradé d'acier en pointe",
    "minecraft:banner.gradient_up.green": "Dégradé de sinople en pointe",
    "minecraft:banner.gradient_up.light_blue": "Dégradé de bleu céleste en pointe",
    "minecraft:banner.gradient_up.light_gray": "Dégradé d'argent grisé en pointe",
    "minecraft:banner.gradient_up.lime": "Dégradé de sinople clair en pointe",
    "minecraft:banner.gradient_up.magenta": "Dégradé de pourpre en pointe",
    "minecraft:banner.gradient_up.orange": "Dégradé d'orangé en pointe",
    "minecraft:banner.gradient_up.pink": "Dégradé de carnation en pointe",
    "minecraft:banner.gradient_up.purple": "Dégradé de mûre en pointe",
    "minecraft:banner.gradient_up.red": "Dégradé de gueules en pointe",
    "minecraft:banner.gradient_up.white": "Dégradé d'argent en pointe",
    "minecraft:banner.gradient_up.yellow": "Dégradé d'or en pointe",
    "minecraft:banner.half_horizontal.black": "Coupé, au premier de sable",
    "minecraft:banner.half_horizontal.blue": "Coupé, au premier d'azur",
    "minecraft:banner.half_horizontal.brown": "Coupé, au premier de tanné",
    "minecraft:banner.half_horizontal.cyan": "Coupé, au premier de cyan",
    "minecraft:banner.half_horizontal.gray": "Coupé, au premier d'acier",
    "minecraft:banner.half_horizontal.green": "Coupé, au premier de sinople",
    "minecraft:banner.half_horizontal.light_blue": "Coupé, au premier de bleu céleste",
    "minecraft:banner.half_horizontal.light_gray": "Coupé, au premier d'argent grisé",
    "minecraft:banner.half_horizontal.lime": "Coupé, au premier de sinople clair",
    "minecraft:banner.half_horizontal.magenta": "Coupé, au premier de pourpre",
    "minecraft:banner.half_horizontal.orange": "Coupé, au premier d'orangé",
    "minecraft:banner.half_horizontal.pink": "Coupé, au premier de carnation",
    "minecraft:banner.half_horizontal.purple": "Coupé, au premier de mûre",
    "minecraft:banner.half_horizontal.red": "Coupé, au premier de gueules",
    "minecraft:banner.half_horizontal.white": "Coupé, au premier d'argent",
    "minecraft:banner.half_horizontal.yellow": "Coupé, au premier d'or",
    "minecraft:banner.half_horizontal_bottom.black": "Coupé, au second de sable",
    "minecraft:banner.half_horizontal_bottom.blue": "Coupé, au second d'azur",
    "minecraft:banner.half_horizontal_bottom.brown": "Coupé, au second de tanné",
    "minecraft:banner.half_horizontal_bottom.cyan": "Coupé, au second de cyan",
    "minecraft:banner.half_horizontal_bottom.gray": "Coupé, au second d'acier",
    "minecraft:banner.half_horizontal_bottom.green": "Coupé, au second de sinople",
    "minecraft:banner.half_horizontal_bottom.light_blue": "Coupé, au second de bleu céleste",
    "minecraft:banner.half_horizontal_bottom.light_gray": "Coupé, au second d'argent grisé",
    "minecraft:banner.half_horizontal_bottom.lime": "Coupé, au second de sinople clair",
    "minecraft:banner.half_horizontal_bottom.magenta": "Coupé, au second de pourpre",
    "minecraft:banner.half_horizontal_bottom.orange": "Coupé, au second d'orangé",
    "minecraft:banner.half_horizontal_bottom.pink": "Coupé, au second de carnation",
    "minecraft:banner.half_horizontal_bottom.purple": "Coupé, au second de mûre",
    "minecraft:banner.half_horizontal_bottom.red": "Coupé, au second de gueules",
    "minecraft:banner.half_horizontal_bottom.white": "Coupé, au second d'argent",
    "minecraft:banner.half_horizontal_bottom.yellow": "Coupé, au second d'or",
    "minecraft:banner.half_vertical.black": "Parti, au premier de sable",
    "minecraft:banner.half_vertical.blue": "Parti, au premier d'azur",
    "minecraft:banner.half_vertical.brown": "Parti, au premier de tanné",
    "minecraft:banner.half_vertical.cyan": "Parti, au premier de cyan",
    "minecraft:banner.half_vertical.gray": "Parti, au premier d'acier",
    "minecraft:banner.half_vertical.green": "Parti, au premier de sinople",
    "minecraft:banner.half_vertical.light_blue": "Parti, au premier de bleu céleste",
    "minecraft:banner.half_vertical.light_gray": "Parti, au premier d'argent grisé",
    "minecraft:banner.half_vertical.lime": "Parti, au premier de sinople clair",
    "minecraft:banner.half_vertical.magenta": "Parti, au premier de pourpre",
    "minecraft:banner.half_vertical.orange": "Parti, au premier d'orangé",
    "minecraft:banner.half_vertical.pink": "Parti, au premier de carnation",
    "minecraft:banner.half_vertical.purple": "Parti, au premier de mûre",
    "minecraft:banner.half_vertical.red": "Parti, au premier de gueules",
    "minecraft:banner.half_vertical.white": "Parti, au premier d'argent",
    "minecraft:banner.half_vertical.yellow": "Parti, au premier d'or",
    "minecraft:banner.half_vertical_right.black": "Parti, au second de sable",
    "minecraft:banner.half_vertical_right.blue": "Parti, au second d'azur",
    "minecraft:banner.half_vertical_right.brown": "Parti, au second de tanné",
    "minecraft:banner.half_vertical_right.cyan": "Parti, au second de cyan",
    "minecraft:banner.half_vertical_right.gray": "Parti, au second d'acier",
    "minecraft:banner.half_vertical_right.green": "Parti, au second de sinople",
    "minecraft:banner.half_vertical_right.light_blue": "Parti, au second de bleu céleste",
    "minecraft:banner.half_vertical_right.light_gray": "Parti, au second d'argent grisé",
    "minecraft:banner.half_vertical_right.lime": "Parti, au second de sinople clair",
    "minecraft:banner.half_vertical_right.magenta": "Parti, au second de pourpre",
    "minecraft:banner.half_vertical_right.orange": "Parti, au second d'orangé",
    "minecraft:banner.half_vertical_right.pink": "Parti, au second de carnation",
    "minecraft:banner.half_vertical_right.purple": "Parti, au second de mûre",
    "minecraft:banner.half_vertical_right.red": "Parti, au second de gueules",
    "minecraft:banner.half_vertical_right.white": "Parti, au second d'argent",
    "minecraft:banner.half_vertical_right.yellow": "Parti, au second d'or",
    "minecraft:banner.mojang.black": "Symbole de sable",
    "minecraft:banner.mojang.blue": "Symbole d'azur",
    "minecraft:banner.mojang.brown": "Symbole de tanné",
    "minecraft:banner.mojang.cyan": "Symbole de cyan",
    "minecraft:banner.mojang.gray": "Symbole d'acier",
    "minecraft:banner.mojang.green": "Symbole de sinople",
    "minecraft:banner.mojang.light_blue": "Symbole de bleu céleste",
    "minecraft:banner.mojang.light_gray": "Symbole d'argent grisé",
    "minecraft:banner.mojang.lime": "Symbole de sinople clair",
    "minecraft:banner.mojang.magenta": "Symbole de pourpre",
    "minecraft:banner.mojang.orange": "Symbole d'orangé",
    "minecraft:banner.mojang.pink": "Symbole de carnation",
    "minecraft:banner.mojang.purple": "Symbole de mûre",
    "minecraft:banner.mojang.red": "Symbole de gueules",
    "minecraft:banner.mojang.white": "Symbole d'argent",
    "minecraft:banner.mojang.yellow": "Symbole d'or",
    "minecraft:banner.piglin.black": "Groin de sable",
    "minecraft:banner.piglin.blue": "Groin d'azur",
    "minecraft:banner.piglin.brown": "Groin de tanné",
    "minecraft:banner.piglin.cyan": "Groin de cyan",
    "minecraft:banner.piglin.gray": "Groin d'acier",
    "minecraft:banner.piglin.green": "Groin de sinople",
    "minecraft:banner.piglin.light_blue": "Groin de bleu céleste",
    "minecraft:banner.piglin.light_gray": "Groin d'argent grisé",
    "minecraft:banner.piglin.lime": "Groin de sinople clair",
    "minecraft:banner.piglin.magenta": "Groin de pourpre",
    "minecraft:banner.piglin.orange": "Groin d'orangé",
    "minecraft:banner.piglin.pink": "Groin de carnation",
    "minecraft:banner.piglin.purple": "Groin de mûre",
    "minecraft:banner.piglin.red": "Groin de gueules",
    "minecraft:banner.piglin.white": "Groin d'argent",
    "minecraft:banner.piglin.yellow": "Groin d'or",
    "minecraft:banner.rhombus.black": "Losange de sable",
    "minecraft:banner.rhombus.blue": "Losange d'azur",
    "minecraft:banner.rhombus.brown": "Losange de tanné",
    "minecraft:banner.rhombus.cyan": "Losange de cyan",
    "minecraft:banner.rhombus.gray": "Losange d'acier",
    "minecraft:banner.rhombus.green": "Losange de sinople",
    "minecraft:banner.rhombus.light_blue": "Losange de bleu céleste",
    "minecraft:banner.rhombus.light_gray": "Losange d'argent grisé",
    "minecraft:banner.rhombus.lime": "Losange de sinople clair",
    "minecraft:banner.rhombus.magenta": "Losange de pourpre",
    "minecraft:banner.rhombus.orange": "Losange d'orangé",
    "minecraft:banner.rhombus.pink": "Losange de carnation",
    "minecraft:banner.rhombus.purple": "Losange de mûre",
    "minecraft:banner.rhombus.red": "Losange de gueules",
    "minecraft:banner.rhombus.white": "Losange d'argent",
    "minecraft:banner.rhombus.yellow": "Losange d'or",
    "minecraft:banner.skull.black": "Crâne de sable",
    "minecraft:banner.skull.blue": "Crâne d'azur",
    "minecraft:banner.skull.brown": "Crâne de tanné",
    "minecraft:banner.skull.cyan": "Crâne de cyan",
    "minecraft:banner.skull.gray": "Crâne d'acier",
    "minecraft:banner.skull.green": "Crâne de sinople",
    "minecraft:banner.skull.light_blue": "Crâne de bleu céleste",
    "minecraft:banner.skull.light_gray": "Crâne d'argent grisé",
    "minecraft:banner.skull.lime": "Crâne de sinople clair",
    "minecraft:banner.skull.magenta": "Crâne de pourpre",
    "minecraft:banner.skull.orange": "Crâne d'orangé",
    "minecraft:banner.skull.pink": "Crâne de carnation",
    "minecraft:banner.skull.purple": "Crâne de mûre",
    "minecraft:banner.skull.red": "Crâne de gueules",
    "minecraft:banner.skull.white": "Crâne d'argent",
    "minecraft:banner.skull.yellow": "Crâne d'or",
    "minecraft:banner.small_stripes.black": "Quatre pals de sable",
    "minecraft:banner.small_stripes.blue": "Quatre pals d'azur",
    "minecraft:banner.small_stripes.brown": "Quatre pals de tanné",
    "minecraft:banner.small_stripes.cyan": "Quatre pals de cyan",
    "minecraft:banner.small_stripes.gray": "Quatre pals d'acier",
    "minecraft:banner.small_stripes.green": "Quatre pals de sinople",
    "minecraft:banner.small_stripes.light_blue": "Quatre pals de bleu céleste",
    "minecraft:banner.small_stripes.light_gray": "Quatre pals d'argent grisé",
    "minecraft:banner.small_stripes.lime": "Quatre pals de sinople clair",
    "minecraft:banner.small_stripes.magenta": "Quatre pals de pourpre",
    "minecraft:banner.small_stripes.orange": "Quatre pals d'orangé",
    "minecraft:banner.small_stripes.pink": "Quatre pals de carnation",
    "minecraft:banner.small_stripes.purple": "Quatre pals de mûre",
    "minecraft:banner.small_stripes.red": "Quatre pals de gueules",
    "minecraft:banner.small_stripes.white": "Quatre pals d'argent",
    "minecraft:banner.small_stripes.yellow": "Quatre pals d'or",
    "minecraft:banner.square_bottom_left.black": "Canton de sable en pointe à dextre",
    "minecraft:banner.square_bottom_left.blue": "Canton d'azur en pointe à dextre",
    "minecraft:banner.square_bottom_left.brown": "Canton de tanné en pointe à dextre",
    "minecraft:banner.square_bottom_left.cyan": "Canton de cyan en pointe à dextre",
    "minecraft:banner.square_bottom_left.gray": "Canton d'acier en pointe à dextre",
    "minecraft:banner.square_bottom_left.green": "Canton de sinople en pointe à dextre",
    "minecraft:banner.square_bottom_left.light_blue": "Canton de bleu céleste en pointe à dextre",
    "minecraft:banner.square_bottom_left.light_gray": "Canton d'argent grisé en pointe à dextre",
    "minecraft:banner.square_bottom_left.lime": "Canton de sinople clair en pointe à dextre",
    "minecraft:banner.square_bottom_left.magenta": "Canton de pourpre en pointe à dextre",
    "minecraft:banner.square_bottom_left.orange": "Canton d'orangé en pointe à dextre",
    "minecraft:banner.square_bottom_left.pink": "Canton de carnation en pointe à dextre",
    "minecraft:banner.square_bottom_left.purple": "Canton de mûre en pointe à dextre",
    "minecraft:banner.square_bottom_left.red": "Canton de gueules en pointe à dextre",
    "minecraft:banner.square_bottom_left.white": "Canton d'argent en pointe à dextre",
    "minecraft:banner.square_bottom_left.yellow": "Canton d'or en pointe à dextre",
    "minecraft:banner.square_bottom_right.black": "Canton de sable en pointe à sénestre",
    "minecraft:banner.square_bottom_right.blue": "Canton d'azur en pointe à sénestre",
    "minecraft:banner.square_bottom_right.brown": "Canton de tanné en pointe à sénestre",
    "minecraft:banner.square_bottom_right.cyan": "Canton de cyan en pointe à sénestre",
    "minecraft:banner.square_bottom_right.gray": "Canton d'acier en pointe à sénestre",
    "minecraft:banner.square_bottom_right.green": "Canton de sinople en pointe à sénestre",
    "minecraft:banner.square_bottom_right.light_blue": "Canton de bleu céleste en pointe à sénestre",
    "minecraft:banner.square_bottom_right.light_gray": "Canton d'argent grisé en pointe à sénestre",
    "minecraft:banner.square_bottom_right.lime": "Canton de sinople clair en pointe à sénestre",
    "minecraft:banner.square_bottom_right.magenta": "Canton de pourpre en pointe à sénestre",
    "minecraft:banner.square_bottom_right.orange": "Canton d'orangé en pointe à sénestre",
    "minecraft:banner.square_bottom_right.pink": "Canton de carnation en pointe à sénestre",
    "minecraft:banner.square_bottom_right.purple": "Canton de mûre en pointe à sénestre",
    "minecraft:banner.square_bottom_right.red": "Canton de gueules en pointe à sénestre",
    "minecraft:banner.square_bottom_right.white": "Canton d'argent en pointe à sénestre",
    "minecraft:banner.square_bottom_right.yellow": "Canton d'or en pointe à sénestre",
    "minecraft:banner.square_top_left.black": "Canton de sable en chef à dextre",
    "minecraft:banner.square_top_left.blue": "Canton d'azur en chef à dextre",
    "minecraft:banner.square_top_left.brown": "Canton de tanné en chef à dextre",
    "minecraft:banner.square_top_left.cyan": "Canton de cyan en chef à dextre",
    "minecraft:banner.square_top_left.gray": "Canton d'acier en chef à dextre",
    "minecraft:banner.square_top_left.green": "Canton de sinople en chef à dextre",
    "minecraft:banner.square_top_left.light_blue": "Canton de bleu céleste en chef à dextre",
    "minecraft:banner.square_top_left.light_gray": "Canton d'argent grisé en chef à dextre",
    "minecraft:banner.square_top_left.lime": "Canton de sinople clair en chef à dextre",
    "minecraft:banner.square_top_left.magenta": "Canton de pourpre en chef à dextre",
    "minecraft:banner.square_top_left.orange": "Canton d'orangé en chef à dextre",
    "minecraft:banner.square_top_left.pink": "Canton de carnation en chef à dextre",
    "minecraft:banner.square_top_left.purple": "Canton de mûre en chef à dextre",
    "minecraft:banner.square_top_left.red": "Canton de gueules en chef à dextre",
    "minecraft:banner.square_top_left.white": "Canton d'argent en chef à dextre",
    "minecraft:banner.square_top_left.yellow": "Canton d'or en chef à dextre",
    "minecraft:banner.square_top_right.black": "Canton de sable en chef à sénestre",
    "minecraft:banner.square_top_right.blue": "Canton d'azur en chef à sénestre",
    "minecraft:banner.square_top_right.brown": "Canton de tanné en chef à sénestre",
    "minecraft:banner.square_top_right.cyan": "Canton de cyan en chef à sénestre",
    "minecraft:banner.square_top_right.gray": "Canton d'acier en chef à sénestre",
    "minecraft:banner.square_top_right.green": "Canton de sinople en chef à sénestre",
    "minecraft:banner.square_top_right.light_blue": "Canton de bleu céleste en chef à sénestre",
    "minecraft:banner.square_top_right.light_gray": "Canton d'argent grisé en chef à sénestre",
    "minecraft:banner.square_top_right.lime": "Canton de sinople clair en chef à sénestre",
    "minecraft:banner.square_top_right.magenta": "Canton de pourpre en chef à sénestre",
    "minecraft:banner.square_top_right.orange": "Canton d'orangé en chef à sénestre",
    "minecraft:banner.square_top_right.pink": "Canton de carnation en chef à sénestre",
    "minecraft:banner.square_top_right.purple": "Canton de mûre en chef à sénestre",
    "minecraft:banner.square_top_right.red": "Canton de gueules en chef à sénestre",
    "minecraft:banner.square_top_right.white": "Canton d'argent en chef à sénestre",
    "minecraft:banner.square_top_right.yellow": "Canton d'or en chef à sénestre",
    "minecraft:banner.straight_cross.black": "Croix de sable",
    "minecraft:banner.straight_cross.blue": "Croix d'azur",
    "minecraft:banner.straight_cross.brown": "Croix de tanné",
    "minecraft:banner.straight_cross.cyan": "Croix de cyan",
    "minecraft:banner.straight_cross.gray": "Croix d'acier",
    "minecraft:banner.straight_cross.green": "Croix de sinople",
    "minecraft:banner.straight_cross.light_blue": "Croix de bleu céleste",
    "minecraft:banner.straight_cross.light_gray": "Croix d'argent grisé",
    "minecraft:banner.straight_cross.lime": "Croix de sinople clair",
    "minecraft:banner.straight_cross.magenta": "Croix de pourpre",
    "minecraft:banner.straight_cross.orange": "Croix d'orangé",
    "minecraft:banner.straight_cross.pink": "Croix de carnation",
    "minecraft:banner.straight_cross.purple": "Croix de mûre",
    "minecraft:banner.straight_cross.red": "Croix de gueules",
    "minecraft:banner.straight_cross.white": "Croix d'argent",
    "minecraft:banner.straight_cross.yellow": "Croix d'or",
    "minecraft:banner.stripe_bottom.black": "Champagne de sable",
    "minecraft:banner.stripe_bottom.blue": "Champagne d'azur",
    "minecraft:banner.stripe_bottom.brown": "Champagne de tanné",
    "minecraft:banner.stripe_bottom.cyan": "Champagne de cyan",
    "minecraft:banner.stripe_bottom.gray": "Champagne d'acier",
    "minecraft:banner.stripe_bottom.green": "Champagne de sinople",
    "minecraft:banner.stripe_bottom.light_blue": "Champagne de bleu céleste",
    "minecraft:banner.stripe_bottom.light_gray": "Champagne d'argent grisé",
    "minecraft:banner.stripe_bottom.lime": "Champagne de sinople clair",
    "minecraft:banner.stripe_bottom.magenta": "Champagne de pourpre",
    "minecraft:banner.stripe_bottom.orange": "Champagne d'orangé",
    "minecraft:banner.stripe_bottom.pink": "Champagne de carnation",
    "minecraft:banner.stripe_bottom.purple": "Champagne de mûre",
    "minecraft:banner.stripe_bottom.red": "Champagne de gueules",
    "minecraft:banner.stripe_bottom.white": "Champagne d'argent",
    "minecraft:banner.stripe_bottom.yellow": "Champagne d'or",
    "minecraft:banner.stripe_center.black": "Pal de sable",
    "minecraft:banner.stripe_center.blue": "Pal d'azur",
    "minecraft:banner.stripe_center.brown": "Pal de tanné",
    "minecraft:banner.stripe_center.cyan": "Pal de cyan",
    "minecraft:banner.stripe_center.gray": "Pal d'acier",
    "minecraft:banner.stripe_center.green": "Pal de sinople",
    "minecraft:banner.stripe_center.light_blue": "Pal de bleu céleste",
    "minecraft:banner.stripe_center.light_gray": "Pal d'argent grisé",
    "minecraft:banner.stripe_center.lime": "Pal de sinople clair",
    "minecraft:banner.stripe_center.magenta": "Pal de pourpre",
    "minecraft:banner.stripe_center.orange": "Pal d'orangé",
    "minecraft:banner.stripe_center.pink": "Pal de carnation",
    "minecraft:banner.stripe_center.purple": "Pal de mûre",
    "minecraft:banner.stripe_center.red": "Pal de gueules",
    "minecraft:banner.stripe_center.white": "Pal d'argent",
    "minecraft:banner.stripe_center.yellow": "Pal d'or",
    "minecraft:banner.stripe_downleft.black": "Barre de sable à sénestre",
    "minecraft:banner.stripe_downleft.blue": "Barre d'azur à sénestre",
    "minecraft:banner.stripe_downleft.brown": "Barre de tanné à sénestre",
    "minecraft:banner.stripe_downleft.cyan": "Barre de cyan à sénestre",
    "minecraft:banner.stripe_downleft.gray": "Barre d'acier à sénestre",
    "minecraft:banner.stripe_downleft.green": "Barre de sinople à sénestre",
    "minecraft:banner.stripe_downleft.light_blue": "Barre de bleu céleste à sénestre",
    "minecraft:banner.stripe_downleft.light_gray": "Barre d'argent grisé à sénestre",
    "minecraft:banner.stripe_downleft.lime": "Barre de sinople clair à sénestre",
    "minecraft:banner.stripe_downleft.magenta": "Barre de pourpre à sénestre",
    "minecraft:banner.stripe_downleft.orange": "Barre d'orangé à sénestre",
    "minecraft:banner.stripe_downleft.pink": "Barre de carnation à sénestre",
    "minecraft:banner.stripe_downleft.purple": "Barre de mûre à sénestre",
    "minecraft:banner.stripe_downleft.red": "Barre de gueules à sénestre",
    "minecraft:banner.stripe_downleft.white": "Barre d'argent à sénestre",
    "minecraft:banner.stripe_downleft.yellow": "Barre d'or à sénestre",
    "minecraft:banner.stripe_downright.black": "Bande de sable",
    "minecraft:banner.stripe_downright.blue": "Bande d'azur",
    "minecraft:banner.stripe_downright.brown": "Bande de tanné",
    "minecraft:banner.stripe_downright.cyan": "Bande de cyan",
    "minecraft:banner.stripe_downright.gray": "Bande d'acier",
    "minecraft:banner.stripe_downright.green": "Bande de sinople",
    "minecraft:banner.stripe_downright.light_blue": "Bande de bleu céleste",
    "minecraft:banner.stripe_downright.light_gray": "Bande d'argent grisé",
    "minecraft:banner.stripe_downright.lime": "Bande de sinople clair",
    "minecraft:banner.stripe_downright.magenta": "Bande de pourpre",
    "minecraft:banner.stripe_downright.orange": "Bande d'orangé",
    "minecraft:banner.stripe_downright.pink": "Bande de carnation",
    "minecraft:banner.stripe_downright.purple": "Bande de mûre",
    "minecraft:banner.stripe_downright.red": "Bande de gueules",
    "minecraft:banner.stripe_downright.white": "Bande d'argent",
    "minecraft:banner.stripe_downright.yellow": "Bande d'or",
    "minecraft:banner.stripe_left.black": "Pal de sable à dextre",
    "minecraft:banner.stripe_left.blue": "Pal d'azur à dextre",
    "minecraft:banner.stripe_left.brown": "Pal de tanné à dextre",
    "minecraft:banner.stripe_left.cyan": "Pal de cyan à dextre",
    "minecraft:banner.stripe_left.gray": "Pal d'acier à dextre",
    "minecraft:banner.stripe_left.green": "Pal de sinople à dextre",
    "minecraft:banner.stripe_left.light_blue": "Pal de bleu céleste à dextre",
    "minecraft:banner.stripe_left.light_gray": "Pal d'argent grisé à dextre",
    "minecraft:banner.stripe_left.lime": "Pal de sinople clair à dextre",
    "minecraft:banner.stripe_left.magenta": "Pal de pourpre à dextre",
    "minecraft:banner.stripe_left.orange": "Pal d'orangé à dextre",
    "minecraft:banner.stripe_left.pink": "Pal de carnation à dextre",
    "minecraft:banner.stripe_left.purple": "Pal de mûre à dextre",
    "minecraft:banner.stripe_left.red": "Pal de gueules à dextre",
    "minecraft:banner.stripe_left.white": "Pal d'argent à dextre",
    "minecraft:banner.stripe_left.yellow": "Pal d'or à dextre",
    "minecraft:banner.stripe_middle.black": "Fasce de sable",
    "minecraft:banner.stripe_middle.blue": "Fasce d'azur",
    "minecraft:banner.stripe_middle.brown": "Fasce de tanné",
    "minecraft:banner.stripe_middle.cyan": "Fasce de cyan",
    "minecraft:banner.stripe_middle.gray": "Fasce d'acier",
    "minecraft:banner.stripe_middle.green": "Fasce de sinople",
    "minecraft:banner.stripe_middle.light_blue": "Fasce de bleu céleste",
    "minecraft:banner.stripe_middle.light_gray": "Fasce d'argent grisé",
    "minecraft:banner.stripe_middle.lime": "Fasce de sinople clair",
    "minecraft:banner.stripe_middle.magenta": "Fasce de pourpre",
    "minecraft:banner.stripe_middle.orange": "Fasce d'orangé",
    "minecraft:banner.stripe_middle.pink": "Fasce de carnation",
    "minecraft:banner.stripe_middle.purple": "Fasce de mûre",
    "minecraft:banner.stripe_middle.red": "Fasce de gueules",
    "minecraft:banner.stripe_middle.white": "Fasce d'argent",
    "minecraft:banner.stripe_middle.yellow": "Fasce d'or",
    "minecraft:banner.stripe_right.black": "Pal de sable à sénestre",
    "minecraft:banner.stripe_right.blue": "Pal d'azur à sénestre",
    "minecraft:banner.stripe_right.brown": "Pal de tanné à sénestre",
    "minecraft:banner.stripe_right.cyan": "Pal de cyan à sénestre",
    "minecraft:banner.stripe_right.gray": "Pal d'acier à sénestre",
    "minecraft:banner.stripe_right.green": "Pal de sinople à sénestre",
    "minecraft:banner.stripe_right.light_blue": "Pal de bleu céleste à sénestre",
    "minecraft:banner.stripe_right.light_gray": "Pal d'argent grisé à sénestre",
    "minecraft:banner.stripe_right.lime": "Pal de sinople clair à sénestre",
    "minecraft:banner.stripe_right.magenta": "Pal de pourpre à sénestre",
    "minecraft:banner.stripe_right.orange": "Pal d'orangé à sénestre",
    "minecraft:banner.stripe_right.pink": "Pal de carnation à sénestre",
    "minecraft:banner.stripe_right.purple": "Pal de mûre à sénestre",
    "minecraft:banner.stripe_right.red": "Pal de gueules à sénestre",
    "minecraft:banner.stripe_right.white": "Pal d'argent à sénestre",
    "minecraft:banner.stripe_right.yellow": "Pal d'or à sénestre",
    "minecraft:banner.stripe_top.black": "Chef de sable",
    "minecraft:banner.stripe_top.blue": "Chef d'azur",
    "minecraft:banner.stripe_top.brown": "Chef de tanné",
    "minecraft:banner.stripe_top.cyan": "Chef de cyan",
    "minecraft:banner.stripe_top.gray": "Chef d'acier",
    "minecraft:banner.stripe_top.green": "Chef de sinople",
    "minecraft:banner.stripe_top.light_blue": "Chef de bleu céleste",
    "minecraft:banner.stripe_top.light_gray": "Chef d'argent grisé",
    "minecraft:banner.stripe_top.lime": "Chef de sinople clair",
    "minecraft:banner.stripe_top.magenta": "Chef de pourpre",
    "minecraft:banner.stripe_top.orange": "Chef d'orangé",
    "minecraft:banner.stripe_top.pink": "Chef de carnation",
    "minecraft:banner.stripe_top.purple": "Chef de mûre",
    "minecraft:banner.stripe_top.red": "Chef de gueules",
    "minecraft:banner.stripe_top.white": "Chef d'argent",
    "minecraft:banner.stripe_top.yellow": "Chef d'or",
    "minecraft:banner.triangle_bottom.black": "Mantelé de sable",
    "minecraft:banner.triangle_bottom.blue": "Mantelé d'azur",
    "minecraft:banner.triangle_bottom.brown": "Mantelé de tanné",
    "minecraft:banner.triangle_bottom.cyan": "Mantelé de cyan",
    "minecraft:banner.triangle_bottom.gray": "Mantelé d'acier",
    "minecraft:banner.triangle_bottom.green": "Mantelé de sinople",
    "minecraft:banner.triangle_bottom.light_blue": "Mantelé de bleu céleste",
    "minecraft:banner.triangle_bottom.light_gray": "Mantelé d'argent grisé",
    "minecraft:banner.triangle_bottom.lime": "Mantelé de sinople clair",
    "minecraft:banner.triangle_bottom.magenta": "Mantelé de pourpre",
    "minecraft:banner.triangle_bottom.orange": "Mantelé d'orangé",
    "minecraft:banner.triangle_bottom.pink": "Mantelé de carnation",
    "minecraft:banner.triangle_bottom.purple": "Mantelé de mûre",
    "minecraft:banner.triangle_bottom.red": "Mantelé de gueules",
    "minecraft:banner.triangle_bottom.white": "Mantelé d'argent",
    "minecraft:banner.triangle_bottom.yellow": "Mantelé d'or",
    "minecraft:banner.triangle_top.black": "Mantelé renversé de sable",
    "minecraft:banner.triangle_top.blue": "Mantelé renversé d'azur",
    "minecraft:banner.triangle_top.brown": "Mantelé renversé de tanné",
    "minecraft:banner.triangle_top.cyan": "Mantelé renversé de cyan",
    "minecraft:banner.triangle_top.gray": "Mantelé renversé d'acier",
    "minecraft:banner.triangle_top.green": "Mantelé renversé de sinople",
    "minecraft:banner.triangle_top.light_blue": "Mantelé renversé de bleu céleste",
    "minecraft:banner.triangle_top.light_gray": "Mantelé renversé d'argent grisé",
    "minecraft:banner.triangle_top.lime": "Mantelé renversé de sinople clair",
    "minecraft:banner.triangle_top.magenta": "Mantelé renversé de pourpre",
    "minecraft:banner.triangle_top.orange": "Mantelé renversé d'orangé",
    "minecraft:banner.triangle_top.pink": "Mantelé renversé de carnation",
    "minecraft:banner.triangle_top.purple": "Mantelé renversé de mûre",
    "minecraft:banner.triangle_top.red": "Mantelé renversé de gueules",
    "minecraft:banner.triangle_top.white": "Mantelé renversé d'argent",
    "minecraft:banner.triangle_top.yellow": "Mantelé renversé d'or",
    "minecraft:banner.triangles_bottom.black": "Émanchures de sable en pointe",
    "minecraft:banner.triangles_bottom.blue": "Émanchures d'azur en pointe",
    "minecraft:banner.triangles_bottom.brown": "Émanchures de tanné en pointe",
    "minecraft:banner.triangles_bottom.cyan": "Émanchures de cyan en pointe",
    "minecraft:banner.triangles_bottom.gray": "Émanchures d'acier en pointe",
    "minecraft:banner.triangles_bottom.green": "Émanchures de sinople en pointe",
    "minecraft:banner.triangles_bottom.light_blue": "Émanchures de bleu céleste en pointe",
    "minecraft:banner.triangles_bottom.light_gray": "Émanchures d'argent grisé en pointe",
    "minecraft:banner.triangles_bottom.lime": "Émanchures de sinople clair en pointe",
    "minecraft:banner.triangles_bottom.magenta": "Émanchures de pourpre en pointe",
    "minecraft:banner.triangles_bottom.orange": "Émanchures d'orangé en pointe",
    "minecraft:banner.triangles_bottom.pink": "Émanchures de carnation en pointe",
    "minecraft:banner.triangles_bottom.purple": "Émanchures de mûre en pointe",
    "minecraft:banner.triangles_bottom.red": "Émanchures de gueules en pointe",
    "minecraft:banner.triangles_bottom.white": "Émanchures d'argent en pointe",
    "minecraft:banner.triangles_bottom.yellow": "Émanchures d'or en pointe",
    "minecraft:banner.triangles_top.black": "Émanchures de sable en chef",
    "minecraft:banner.triangles_top.blue": "Émanchures d'azur en chef",
    "minecraft:banner.triangles_top.brown": "Émanchures de tanné en chef",
    "minecraft:banner.triangles_top.cyan": "Émanchures de cyan en chef",
    "minecraft:banner.triangles_top.gray": "Émanchures d'acier en chef",
    "minecraft:banner.triangles_top.green": "Émanchures de sinople en chef",
    "minecraft:banner.triangles_top.light_blue": "Émanchures de bleu céleste en chef",
    "minecraft:banner.triangles_top.light_gray": "Émanchures d'argent grisé en chef",
    "minecraft:banner.triangles_top.lime": "Émanchures de sinople clair en chef",
    "minecraft:banner.triangles_top.magenta": "Émanchures de pourpre en chef",
    "minecraft:banner.triangles_top.orange": "Émanchures d'orangé en chef",
    "minecraft:banner.triangles_top.pink": "Émanchures de carnation en chef",
    "minecraft:banner.triangles_top.purple": "Émanchures de mûre en chef",
    "minecraft:banner.triangles_top.red": "Émanchures de gueules en chef",
    "minecraft:banner.triangles_top.white": "Émanchures d'argent en chef",
    "minecraft:banner.triangles_top.yellow": "Émanchures d'or en chef",
    "minecraft:barrel": "Tonneau",
    "minecraft:barrier": "Barrière invisible",
    "minecraft:basalt": "Basalte",
    "minecraft:beacon": "Balise",
    "minecraft:beacon.primary": "Pouvoir primaire",
    "minecraft:beacon.secondary": "Pouvoir secondaire",
    "minecraft:bed.no_sleep": "Vous ne pouvez dormir que la nuit ou durant les orages",
    "minecraft:bed.not_safe": "Vous ne pouvez pas dormir maintenant, il y a des monstres à proximité",
    "minecraft:bed.obstructed": "Ce lit est obstrué",
    "minecraft:bed.occupied": "Ce lit est occupé",
    "minecraft:bed.too_far_away": "Vous ne pouvez pas dormir maintenant, le lit est trop éloigné",
    "minecraft:bedrock": "Bedrock",
    "minecraft:bee_nest": "Nid d'abeilles",
    "minecraft:beehive": "Ruche",
    "minecraft:beetroots": "Betteraves",
    "minecraft:bell": "Cloche",
    "minecraft:big_dripleaf": "Grande foliogoutte",
    "minecraft:big_dripleaf_stem": "Tige de grande foliogoutte",
    "minecraft:birch_button": "Bouton en bouleau",
    "minecraft:birch_door": "Porte en bouleau",
    "minecraft:birch_fence": "Barrière en bouleau",
    "minecraft:birch_fence_gate": "Portillon en bouleau",
    "minecraft:birch_hanging_sign": "Pancarte suspendue en bouleau",
    "minecraft:birch_leaves": "Feuilles de bouleau",
    "minecraft:birch_log": "Bûche de bouleau",
    "minecraft:birch_planks": "Planches de bouleau",
    "minecraft:birch_pressure_plate": "Plaque de pression en bouleau",
    "minecraft:birch_sapling": "Pousse de bouleau",
    "minecraft:birch_sign": "Pancarte en bouleau",
    "minecraft:birch_slab": "Dalle en bouleau",
    "minecraft:birch_stairs": "Escalier en bouleau",
    "minecraft:birch_trapdoor": "Trappe en bouleau",
    "minecraft:birch_wall_hanging_sign": "Pancarte suspendue en bouleau murale",
    "minecraft:birch_wall_sign": "Pancarte en bouleau murale",
    "minecraft:birch_wood": "Bois de bouleau",
    "minecraft:black_banner": "Bannière noire",
    "minecraft:black_bed": "Lit noir",
    "minecraft:black_candle": "Bougie noire",
    "minecraft:black_candle_cake": "Gâteau à bougie noire",
    "minecraft:black_carpet": "Tapis noir",
    "minecraft:black_concrete": "Béton noir",
    "minecraft:black_concrete_powder": "Béton noir en poudre",
    "minecraft:black_glazed_terracotta": "Terre cuite émaillée noire",
    "minecraft:black_shulker_box": "Boîte de Shulker noire",
    "minecraft:black_stained_glass": "Verre noir",
    "minecraft:black_stained_glass_pane": "Vitre noire",
    "minecraft:black_terracotta": "Terre cuite noire",
    "minecraft:black_wool": "Laine noire",
    "minecraft:blackstone": "Roche noire",
    "minecraft:blackstone_slab": "Dalle de roche noire",
    "minecraft:blackstone_stairs": "Escalier de roche noire",
    "minecraft:blackstone_wall": "Muret de roche noire",
    "minecraft:blast_furnace": "Haut fourneau",
    "minecraft:blue_banner": "Bannière bleue",
    "minecraft:blue_bed": "Lit bleu",
    "minecraft:blue_candle": "Bougie bleue",
    "minecraft:blue_candle_cake": "Gâteau à bougie bleue",
    "minecraft:blue_carpet": "Tapis bleu",
    "minecraft:blue_concrete": "Béton bleu",
    "minecraft:blue_concrete_powder": "Béton bleu en poudre",
    "minecraft:blue_glazed_terracotta": "Terre cuite émaillée bleue",
    "minecraft:blue_ice": "Glace bleue",
    "minecraft:blue_orchid": "Orchidée bleue",
    "minecraft:blue_shulker_box": "Boîte de Shulker bleue",
    "minecraft:blue_stained_glass": "Verre bleu",
    "minecraft:blue_stained_glass_pane": "Vitre bleue",
    "minecraft:blue_terracotta": "Terre cuite bleue",
    "minecraft:blue_wool": "Laine bleue",
    "minecraft:bone_block": "Bloc d'os",
    "minecraft:bookshelf": "Bibliothèque",
    "minecraft:brain_coral": "Corail cerveau",
    "minecraft:brain_coral_block": "Bloc de corail cerveau",
    "minecraft:brain_coral_fan": "Gorgone de corail cerveau",
    "minecraft:brain_coral_wall_fan": "Gorgone de corail cerveau murale",
    "minecraft:brewing_stand": "Alambic",
    "minecraft:brick_slab": "Dalle en briques",
    "minecraft:brick_stairs": "Escalier en briques",
    "minecraft:brick_wall": "Muret en briques",
    "minecraft:bricks": "Briques",
    "minecraft:brown_banner": "Bannière marron",
    "minecraft:brown_bed": "Lit marron",
    "minecraft:brown_candle": "Bougie marron",
    "minecraft:brown_candle_cake": "Gâteau à bougie marron",
    "minecraft:brown_carpet": "Tapis marron",
    "minecraft:brown_concrete": "Béton marron",
    "minecraft:brown_concrete_powder": "Béton marron en poudre",
    "minecraft:brown_glazed_terracotta": "Terre cuite émaillée marron",
    "minecraft:brown_mushroom": "Champignon brun",
    "minecraft:brown_mushroom_block": "Bloc de champignon brun",
    "minecraft:brown_shulker_box": "Boîte de Shulker marron",
    "minecraft:brown_stained_glass": "Verre marron",
    "minecraft:brown_stained_glass_pane": "Vitre marron",
    "minecraft:brown_terracotta": "Terre cuite marron",
    "minecraft:brown_wool": "Laine marron",
    "minecraft:bubble_column": "Colonne de bulles",
    "minecraft:bubble_coral": "Corail bulles",
    "minecraft:bubble_coral_block": "Bloc de corail bulles",
    "minecraft:bubble_coral_fan": "Gorgone de corail bulles",
    "minecraft:bubble_coral_wall_fan": "Gorgone de corail bulles murale",
    "minecraft:budding_amethyst": "Druse d'améthyste",
    "minecraft:cactus": "Cactus",
    "minecraft:cake": "Gâteau",
    "minecraft:calcite": "Calcite",
    "minecraft:calibrated_sculk_sensor": "Capteur sculk calibré",
    "minecraft:campfire": "Feu de camp",
    "minecraft:candle": "Bougie",
    "minecraft:candle_cake": "Gâteau à bougie",
    "minecraft:carrots": "Carottes",
    "minecraft:cartography_table": "Table de cartographie",
    "minecraft:carved_pumpkin": "Citrouille sculptée",
    "minecraft:cave_air": "Air de caverne",
    "minecraft:cave_vines": "Lianes des cavernes",
    "minecraft:cave_vines_plant": "Plante de lianes des cavernes",
    "minecraft:chain": "Chaîne",
    "minecraft:chain_command_block": "Bloc de commande en chaîne",
    "minecraft:cherry_button": "Bouton en cerisier",
    "minecraft:cherry_door": "Porte en cerisier",
    "minecraft:cherry_fence": "Barrière en cerisier",
    "minecraft:cherry_fence_gate": "Portillon en cerisier",
    "minecraft:cherry_hanging_sign": "Pancarte suspendue en cerisier",
    "minecraft:cherry_leaves": "Feuilles de cerisier",
    "minecraft:cherry_log": "Bûche de cerisier",
    "minecraft:cherry_planks": "Planches de cerisier",
    "minecraft:cherry_pressure_plate": "Plaque de pression en cerisier",
    "minecraft:cherry_sapling": "Pousse de cerisier",
    "minecraft:cherry_sign": "Pancarte en cerisier",
    "minecraft:cherry_slab": "Dalle en cerisier",
    "minecraft:cherry_stairs": "Escalier en cerisier",
    "minecraft:cherry_trapdoor": "Trappe en cerisier",
    "minecraft:cherry_wall_hanging_sign": "Pancarte suspendue en cerisier murale",
    "minecraft:cherry_wall_sign": "Pancarte en cerisier murale",
    "minecraft:cherry_wood": "Bois de cerisier",
    "minecraft:chest": "Coffre",
    "minecraft:chipped_anvil": "Enclume ébréchée",
    "minecraft:chiseled_bookshelf": "Bibliothèque sculptée",
    "minecraft:chiseled_copper": "Cuivre sculpté",
    "minecraft:chiseled_deepslate": "Ardoise des abîmes sculptée",
    "minecraft:chiseled_nether_bricks": "Briques du Nether sculptées",
    "minecraft:chiseled_polished_blackstone": "Pierre noire sculptée",
    "minecraft:chiseled_quartz_block": "Bloc de quartz sculpté",
    "minecraft:chiseled_red_sandstone": "Grès rouge sculpté",
    "minecraft:chiseled_sandstone": "Grès sculpté",
    "minecraft:chiseled_stone_bricks": "Pierre sculptée",
    "minecraft:chiseled_tuff": "Tuf sculpté",
    "minecraft:chiseled_tuff_bricks": "Briques de tuf sculptées",
    "minecraft:chorus_flower": "Fleur de chorus",
    "minecraft:chorus_plant": "Plante de chorus",
    "minecraft:clay": "Argile",
    "minecraft:coal_block": "Bloc de charbon",
    "minecraft:coal_ore": "Minerai de charbon",
    "minecraft:coarse_dirt": "Terre stérile",
    "minecraft:cobbled_deepslate": "Pierres des abîmes",
    "minecraft:cobbled_deepslate_slab": "Dalle de pierres des abîmes",
    "minecraft:cobbled_deepslate_stairs": "Escalier en pierres des abîmes",
    "minecraft:cobbled_deepslate_wall": "Muret de pierres des abîmes",
    "minecraft:cobblestone": "Pierres",
    "minecraft:cobblestone_slab": "Dalle de pierres",
    "minecraft:cobblestone_stairs": "Escalier en pierre",
    "minecraft:cobblestone_wall": "Muret de pierres",
    "minecraft:cobweb": "Toile d'araignée",
    "minecraft:cocoa": "Cacao",
    "minecraft:command_block": "Bloc de commande",
    "minecraft:comparator": "Comparateur de redstone",
    "minecraft:composter": "Composteur",
    "minecraft:conduit": "Conduit",
    "minecraft:copper_block": "Bloc de cuivre",
    "minecraft:copper_bulb": "Ampoule en cuivre",
    "minecraft:copper_door": "Porte en cuivre",
    "minecraft:copper_grate": "Grille en cuivre",
    "minecraft:copper_ore": "Minerai de cuivre",
    "minecraft:copper_trapdoor": "Trappe en cuivre",
    "minecraft:cornflower": "Bleuet",
    "minecraft:cracked_deepslate_bricks": "Ardoise des abîmes taillée craquelée",
    "minecraft:cracked_deepslate_tiles": "Ardoise des abîmes carrelée craquelée",
    "minecraft:cracked_nether_bricks": "Briques du Nether craquelées",
    "minecraft:cracked_polished_blackstone_bricks": "Pierre noire taillée craquelée",
    "minecraft:cracked_stone_bricks": "Pierre taillée craquelée",
    "minecraft:crafter": "Fabricateur",
    "minecraft:crafting_table": "Établi",
    "minecraft:creeper_head": "Tête de Creeper",
    "minecraft:creeper_wall_head": "Tête de Creeper murale",
    "minecraft:crimson_button": "Bouton carmin",
    "minecraft:crimson_door": "Porte carmin",
    "minecraft:crimson_fence": "Barrière carmin",
    "minecraft:crimson_fence_gate": "Portillon carmin",
    "minecraft:crimson_fungus": "Champignon carmin",
    "minecraft:crimson_hanging_sign": "Pancarte suspendue carmin",
    "minecraft:crimson_hyphae": "Hyphes carmin",
    "minecraft:crimson_nylium": "Nylium carmin",
    "minecraft:crimson_planks": "Planches carmin",
    "minecraft:crimson_pressure_plate": "Plaque de pression carmin",
    "minecraft:crimson_roots": "Racines carmin",
    "minecraft:crimson_sign": "Pancarte carmin",
    "minecraft:crimson_slab": "Dalle carmin",
    "minecraft:crimson_stairs": "Escalier carmin",
    "minecraft:crimson_stem": "Tige carmin",
    "minecraft:crimson_trapdoor": "Trappe carmin",
    "minecraft:crimson_wall_hanging_sign": "Pancarte suspendue carmin murale",
    "minecraft:crimson_wall_sign": "Pancarte carmin murale",
    "minecraft:crying_obsidian": "Obsidienne pleureuse",
    "minecraft:cut_copper": "Cuivre taillé",
    "minecraft:cut_copper_slab": "Dalle en cuivre taillé",
    "minecraft:cut_copper_stairs": "Escalier en cuivre taillé",
    "minecraft:cut_red_sandstone": "Grès rouge taillé",
    "minecraft:cut_red_sandstone_slab": "Dalle en grès rouge taillé",
    "minecraft:cut_sandstone": "Grès taillé",
    "minecraft:cut_sandstone_slab": "Dalle en grès taillé",
    "minecraft:cyan_banner": "Bannière cyan",
    "minecraft:cyan_bed": "Lit cyan",
    "minecraft:cyan_candle": "Bougie cyan",
    "minecraft:cyan_candle_cake": "Gâteau à bougie cyan",
    "minecraft:cyan_carpet": "Tapis cyan",
    "minecraft:cyan_concrete": "Béton cyan",
    "minecraft:cyan_concrete_powder": "Béton cyan en poudre",
    "minecraft:cyan_glazed_terracotta": "Terre cuite émaillée cyan",
    "minecraft:cyan_shulker_box": "Boîte de Shulker cyan",
    "minecraft:cyan_stained_glass": "Verre cyan",
    "minecraft:cyan_stained_glass_pane": "Vitre cyan",
    "minecraft:cyan_terracotta": "Terre cuite cyan",
    "minecraft:cyan_wool": "Laine cyan",
    "minecraft:damaged_anvil": "Enclume endommagée",
    "minecraft:dandelion": "Pissenlit",
    "minecraft:dark_oak_button": "Bouton en chêne noir",
    "minecraft:dark_oak_door": "Porte en chêne noir",
    "minecraft:dark_oak_fence": "Barrière en chêne noir",
    "minecraft:dark_oak_fence_gate": "Portillon en chêne noir",
    "minecraft:dark_oak_hanging_sign": "Pancarte suspendue en chêne noir",
    "minecraft:dark_oak_leaves": "Feuilles de chêne noir",
    "minecraft:dark_oak_log": "Bûche de chêne noir",
    "minecraft:dark_oak_planks": "Planches de chêne noir",
    "minecraft:dark_oak_pressure_plate": "Plaque de pression en chêne noir",
    "minecraft:dark_oak_sapling": "Pousse de chêne noir",
    "minecraft:dark_oak_sign": "Pancarte en chêne noir",
    "minecraft:dark_oak_slab": "Dalle en chêne noir",
    "minecraft:dark_oak_stairs": "Escalier en chêne noir",
    "minecraft:dark_oak_trapdoor": "Trappe en chêne noir",
    "minecraft:dark_oak_wall_hanging_sign": "Pancarte suspendue en chêne noir murale",
    "minecraft:dark_oak_wall_sign": "Pancarte en chêne noir murale",
    "minecraft:dark_oak_wood": "Bois de chêne noir",
    "minecraft:dark_prismarine": "Prismarine sombre",
    "minecraft:dark_prismarine_slab": "Dalle de prismarine sombre",
    "minecraft:dark_prismarine_stairs": "Escalier en prismarine sombre",
    "minecraft:daylight_detector": "Capteur de lumière",
    "minecraft:dead_brain_coral": "Corail cerveau mort",
    "minecraft:dead_brain_coral_block": "Bloc de corail cerveau mort",
    "minecraft:dead_brain_coral_fan": "Gorgone de corail cerveau morte",
    "minecraft:dead_brain_coral_wall_fan": "Gorgone de corail cerveau morte murale",
    "minecraft:dead_bubble_coral": "Corail bulles mort",
    "minecraft:dead_bubble_coral_block": "Bloc de corail bulles mort",
    "minecraft:dead_bubble_coral_fan": "Gorgone de corail bulles morte",
    "minecraft:dead_bubble_coral_wall_fan": "Gorgone de corail bulles morte murale",
    "minecraft:dead_bush": "Arbuste mort",
    "minecraft:dead_fire_coral": "Corail de feu mort",
    "minecraft:dead_fire_coral_block": "Bloc de corail de feu mort",
    "minecraft:dead_fire_coral_fan": "Gorgone de corail de feu morte",
    "minecraft:dead_fire_coral_wall_fan": "Gorgone de corail de feu morte murale",
    "minecraft:dead_horn_coral": "Corail corné mort",
    "minecraft:dead_horn_coral_block": "Bloc de corail corné mort",
    "minecraft:dead_horn_coral_fan": "Gorgone de corail corné morte",
    "minecraft:dead_horn_coral_wall_fan": "Gorgone de corail corné morte murale",
    "minecraft:dead_tube_coral": "Corail tubulaire mort",
    "minecraft:dead_tube_coral_block": "Bloc de corail tubulaire mort",
    "minecraft:dead_tube_coral_fan": "Gorgone de corail tubulaire morte",
    "minecraft:dead_tube_coral_wall_fan": "Gorgone de corail tubulaire morte murale",
    "minecraft:decorated_pot": "Vase décoré",
    "minecraft:deepslate": "Ardoise des abîmes",
    "minecraft:deepslate_brick_slab": "Dalle en ardoise des abîmes taillée",
    "minecraft:deepslate_brick_stairs": "Escalier en ardoise des abîmes taillée",
    "minecraft:deepslate_brick_wall": "Muret en ardoise des abîmes taillée",
    "minecraft:deepslate_bricks": "Ardoise des abîmes taillée",
    "minecraft:deepslate_coal_ore": "Minerai de charbon des abîmes",
    "minecraft:deepslate_copper_ore": "Minerai de cuivre des abîmes",
    "minecraft:deepslate_diamond_ore": "Minerai de diamant des abîmes",
    "minecraft:deepslate_emerald_ore": "Minerai d'émeraude des abîmes",
    "minecraft:deepslate_gold_ore": "Minerai d'or des abîmes",
    "minecraft:deepslate_iron_ore": "Minerai de fer des abîmes",
    "minecraft:deepslate_lapis_ore": "Minerai de lapis-lazuli des abîmes",
    "minecraft:deepslate_redstone_ore": "Minerai de redstone des abîmes",
    "minecraft:deepslate_tile_slab": "Dalle en ardoise des abîmes carrelée",
    "minecraft:deepslate_tile_stairs": "Escalier en ardoise des abîmes carrelée",
    "minecraft:deepslate_tile_wall": "Muret en ardoise des abîmes carrelée",
    "minecraft:deepslate_tiles": "Ardoise des abîmes carrelée",
    "minecraft:detector_rail": "Rails détecteurs",
    "minecraft:diamond_block": "Bloc de diamant",
    "minecraft:diamond_ore": "Minerai de diamant",
    "minecraft:diorite": "Diorite",
    "minecraft:diorite_slab": "Dalle de diorite",
    "minecraft:diorite_stairs": "Escalier de diorite",
    "minecraft:diorite_wall": "Muret de diorite",
    "minecraft:dirt": "Terre",
    "minecraft:dirt_path": "Chemin de terre",
    "minecraft:dispenser": "Distributeur",
    "minecraft:dragon_egg": "Oeuf de dragon",
    "minecraft:dragon_head": "Tête de dragon",
    "minecraft:dragon_wall_head": "Tête de dragon murale",
    "minecraft:dried_kelp_block": "Bloc d'algues séchées",
    "minecraft:dripstone_block": "Bloc de spéléothème",
    "minecraft:dropper": "Dropper",
    "minecraft:emerald_block": "Bloc d'émeraude",
    "minecraft:emerald_ore": "Minerai d'émeraude",
    "minecraft:enchanting_table": "Table d'enchantement",
    "minecraft:end_gateway": "Passerelle de l'End",
    "minecraft:end_portal": "Portail de l'End",
    "minecraft:end_portal_frame": "Cadre de portail de l'End",
    "minecraft:end_rod": "Barre de l'End",
    "minecraft:end_stone": "Pierre de l'End",
    "minecraft:end_stone_brick_slab": "Dalle en pierre taillée de l'End",
    "minecraft:end_stone_brick_stairs": "Escalier en pierre taillée de l'End",
    "minecraft:end_stone_brick_wall": "Muret en pierre taillée de l'End",
    "minecraft:end_stone_bricks": "Pierre taillée de l'End",
    "minecraft:ender_chest": "Coffre de l'Ender",
    "minecraft:exposed_chiseled_copper": "Cuivre sculpté exposé",
    "minecraft:exposed_copper": "Cuivre exposé",
    "minecraft:exposed_copper_bulb": "Ampoule en cuivre exposée",
    "minecraft:exposed_copper_door": "Porte en cuivre exposée",
    "minecraft:exposed_copper_grate": "Grille en cuivre exposée",
    "minecraft:exposed_copper_trapdoor": "Trappe en cuivre exposée",
    "minecraft:exposed_cut_copper": "Cuivre taillé exposé",
    "minecraft:exposed_cut_copper_slab": "Dalle en cuivre taillé exposé",
    "minecraft:exposed_cut_copper_stairs": "Escalier en cuivre taillé exposé",
    "minecraft:farmland": "Terre labourée",
    "minecraft:fern": "Fougère",
    "minecraft:fire": "Feu",
    "minecraft:fire_coral": "Corail de feu",
    "minecraft:fire_coral_block": "Bloc de corail de feu",
    "minecraft:fire_coral_fan": "Gorgone de corail de feu",
    "minecraft:fire_coral_wall_fan": "Gorgone de corail de feu murale",
    "minecraft:fletching_table": "Table d'archerie",
    "minecraft:flowering_azalea": "Azalée fleurie",
    "minecraft:flowering_azalea_leaves": "Feuilles d'azalée fleurie",
    "minecraft:frogspawn": "Oeufs de grenouille",
    "minecraft:frosted_ice": "Glace givrée",
    "minecraft:furnace": "Fourneau",
    "minecraft:gilded_blackstone": "Roche noire dorée",
    "minecraft:glass": "Verre",
    "minecraft:glass_pane": "Vitre",
    "minecraft:glow_lichen": "Lichen lumineux",
    "minecraft:glowstone": "Pierre lumineuse",
    "minecraft:gold_block": "Bloc d'or",
    "minecraft:gold_ore": "Minerai d'or",
    "minecraft:granite": "Granite",
    "minecraft:granite_slab": "Dalle de granite",
    "minecraft:granite_stairs": "Escalier de granite",
    "minecraft:granite_wall": "Muret de granite",
    "minecraft:grass": "Herbe",
    "minecraft:grass_block": "Bloc d'herbe",
    "minecraft:gravel": "Gravier",
    "minecraft:gray_banner": "Bannière grise",
    "minecraft:gray_bed": "Lit gris",
    "minecraft:gray_candle": "Bougie grise",
    "minecraft:gray_candle_cake": "Gâteau à bougie grise",
    "minecraft:gray_carpet": "Tapis gris",
    "minecraft:gray_concrete": "Béton gris",
    "minecraft:gray_concrete_powder": "Béton gris en poudre",
    "minecraft:gray_glazed_terracotta": "Terre cuite émaillée grise",
    "minecraft:gray_shulker_box": "Boîte de Shulker grise",
    "minecraft:gray_stained_glass": "Verre gris",
    "minecraft:gray_stained_glass_pane": "Vitre grise",
    "minecraft:gray_terracotta": "Terre cuite grise",
    "minecraft:gray_wool": "Laine grise",
    "minecraft:green_banner": "Bannière verte",
    "minecraft:green_bed": "Lit vert",
    "minecraft:green_candle": "Bougie verte",
    "minecraft:green_candle_cake": "Gâteau à bougie verte",
    "minecraft:green_carpet": "Tapis vert",
    "minecraft:green_concrete": "Béton vert",
    "minecraft:green_concrete_powder": "Béton vert en poudre",
    "minecraft:green_glazed_terracotta": "Terre cuite émaillée verte",
    "minecraft:green_shulker_box": "Boîte de Shulker verte",
    "minecraft:green_stained_glass": "Verre vert",
    "minecraft:green_stained_glass_pane": "Vitre verte",
    "minecraft:green_terracotta": "Terre cuite verte",
    "minecraft:green_wool": "Laine verte",
    "minecraft:grindstone": "Meule",
    "minecraft:hanging_roots": "Racines suspendues",
    "minecraft:hay_block": "Botte de paille",
    "minecraft:heavy_weighted_pressure_plate": "Plaque de pression pondérée lourde",
    "minecraft:honey_block": "Bloc de miel",
    "minecraft:honeycomb_block": "Bloc de rayon de miel",
    "minecraft:hopper": "Entonnoir",
    "minecraft:horn_coral": "Corail corné",
    "minecraft:horn_coral_block": "Bloc de corail corné",
    "minecraft:horn_coral_fan": "Gorgone de corail corné",
    "minecraft:horn_coral_wall_fan": "Gorgone de corail corné murale",
    "minecraft:ice": "Glace",
    "minecraft:infested_chiseled_stone_bricks": "Pierre sculptée infestée",
    "minecraft:infested_cobblestone": "Pierres infestées",
    "minecraft:infested_cracked_stone_bricks": "Pierre taillée craquelée infestée",
    "minecraft:infested_deepslate": "Ardoise des abîmes infestée",
    "minecraft:infested_mossy_stone_bricks": "Pierre taillée moussue infestée",
    "minecraft:infested_stone": "Roche infestée",
    "minecraft:infested_stone_bricks": "Pierre taillée infestée",
    "minecraft:iron_bars": "Barreaux de fer",
    "minecraft:iron_block": "Bloc de fer",
    "minecraft:iron_door": "Porte en fer",
    "minecraft:iron_ore": "Minerai de fer",
    "minecraft:iron_trapdoor": "Trappe en fer",
    "minecraft:jack_o_lantern": "Citrouille-lanterne",
    "minecraft:jigsaw": "Bloc de puzzle",
    "minecraft:jukebox": "Jukebox",
    "minecraft:jungle_button": "Bouton en acajou",
    "minecraft:jungle_door": "Porte en acajou",
    "minecraft:jungle_fence": "Barrière en acajou",
    "minecraft:jungle_fence_gate": "Portillon en acajou",
    "minecraft:jungle_hanging_sign": "Pancarte suspendue en acajou",
    "minecraft:jungle_leaves": "Feuilles d'acajou",
    "minecraft:jungle_log": "Bûche d'acajou",
    "minecraft:jungle_planks": "Planches d'acajou",
    "minecraft:jungle_pressure_plate": "Plaque de pression en acajou",
    "minecraft:jungle_sapling": "Pousse d'acajou",
    "minecraft:jungle_sign": "Pancarte en acajou",
    "minecraft:jungle_slab": "Dalle en acajou",
    "minecraft:jungle_stairs": "Escalier en acajou",
    "minecraft:jungle_trapdoor": "Trappe en acajou",
    "minecraft:jungle_wall_hanging_sign": "Pancarte suspendue en acajou murale",
    "minecraft:jungle_wall_sign": "Pancarte en acajou murale",
    "minecraft:jungle_wood": "Bois d'acajou",
    "minecraft:kelp": "Algue",
    "minecraft:kelp_plant": "Tige d'algue",
    "minecraft:ladder": "Échelle",
    "minecraft:lantern": "Lanterne",
    "minecraft:lapis_block": "Bloc de lapis-lazuli",
    "minecraft:lapis_ore": "Minerai de lapis-lazuli",
    "minecraft:large_amethyst_bud": "Grand cristal d'améthyste",
    "minecraft:large_fern": "Grande fougère",
    "minecraft:lava": "Lave",
    "minecraft:lava_cauldron": "Chaudron de lave",
    "minecraft:lectern": "Pupitre",
    "minecraft:lever": "Levier",
    "minecraft:light": "Lumière",
    "minecraft:light_blue_banner": "Bannière bleu clair",
    "minecraft:light_blue_bed": "Lit bleu clair",
    "minecraft:light_blue_candle": "Bougie bleu clair",
    "minecraft:light_blue_candle_cake": "Gâteau à bougie bleu clair",
    "minecraft:light_blue_carpet": "Tapis bleu clair",
    "minecraft:light_blue_concrete": "Béton bleu clair",
    "minecraft:light_blue_concrete_powder": "Béton bleu clair en poudre",
    "minecraft:light_blue_glazed_terracotta": "Terre cuite émaillée bleu clair",
    "minecraft:light_blue_shulker_box": "Boîte de Shulker bleu clair",
    "minecraft:light_blue_stained_glass": "Verre bleu clair",
    "minecraft:light_blue_stained_glass_pane": "Vitre bleu clair",
    "minecraft:light_blue_terracotta": "Terre cuite bleu clair",
    "minecraft:light_blue_wool": "Laine bleu clair",
    "minecraft:light_gray_banner": "Bannière gris clair",
    "minecraft:light_gray_bed": "Lit gris clair",
    "minecraft:light_gray_candle": "Bougie gris clair",
    "minecraft:light_gray_candle_cake": "Gâteau à bougie gris clair",
    "minecraft:light_gray_carpet": "Tapis gris clair",
    "minecraft:light_gray_concrete": "Béton gris clair",
    "minecraft:light_gray_concrete_powder": "Béton gris clair en poudre",
    "minecraft:light_gray_glazed_terracotta": "Terre cuite émaillée gris clair",
    "minecraft:light_gray_shulker_box": "Boîte de Shulker gris clair",
    "minecraft:light_gray_stained_glass": "Verre gris clair",
    "minecraft:light_gray_stained_glass_pane": "Vitre gris clair",
    "minecraft:light_gray_terracotta": "Terre cuite gris clair",
    "minecraft:light_gray_wool": "Laine gris clair",
    "minecraft:light_weighted_pressure_plate": "Plaque de pression pondérée légère",
    "minecraft:lightning_rod": "Paratonnerre",
    "minecraft:lilac": "Lilas",
    "minecraft:lily_of_the_valley": "Muguet",
    "minecraft:lily_pad": "Nénuphar",
    "minecraft:lime_banner": "Bannière vert clair",
    "minecraft:lime_bed": "Lit vert clair",
    "minecraft:lime_candle": "Bougie vert clair",
    "minecraft:lime_candle_cake": "Gâteau à bougie vert clair",
    "minecraft:lime_carpet": "Tapis vert clair",
    "minecraft:lime_concrete": "Béton vert clair",
    "minecraft:lime_concrete_powder": "Béton vert clair en poudre",
    "minecraft:lime_glazed_terracotta": "Terre cuite émaillée vert clair",
    "minecraft:lime_shulker_box": "Boîte de Shulker vert clair",
    "minecraft:lime_stained_glass": "Verre vert clair",
    "minecraft:lime_stained_glass_pane": "Vitre vert clair",
    "minecraft:lime_terracotta": "Terre cuite vert clair",
    "minecraft:lime_wool": "Laine vert clair",
    "minecraft:lodestone": "Magnétite",
    "minecraft:loom": "Métier à tisser",
    "minecraft:magenta_banner": "Bannière magenta",
    "minecraft:magenta_bed": "Lit magenta",
    "minecraft:magenta_candle": "Bougie magenta",
    "minecraft:magenta_candle_cake": "Gâteau à bougie magenta",
    "minecraft:magenta_carpet": "Tapis magenta",
    "minecraft:magenta_concrete": "Béton magenta",
    "minecraft:magenta_concrete_powder": "Béton magenta en poudre",
    "minecraft:magenta_glazed_terracotta": "Terre cuite émaillée magenta",
    "minecraft:magenta_shulker_box": "Boîte de Shulker magenta",
    "minecraft:magenta_stained_glass": "Verre magenta",
    "minecraft:magenta_stained_glass_pane": "Vitre magenta",
    "minecraft:magenta_terracotta": "Terre cuite magenta",
    "minecraft:magenta_wool": "Laine magenta",
    "minecraft:magma_block": "Bloc de magma",
    "minecraft:mangrove_button": "Bouton en palétuvier",
    "minecraft:mangrove_door": "Porte en palétuvier",
    "minecraft:mangrove_fence": "Barrière en palétuvier",
    "minecraft:mangrove_fence_gate": "Portillon en palétuvier",
    "minecraft:mangrove_hanging_sign": "Pancarte suspendue en palétuvier",
    "minecraft:mangrove_leaves": "Feuilles de palétuvier",
    "minecraft:mangrove_log": "Bûche de palétuvier",
    "minecraft:mangrove_planks": "Planches de palétuvier",
    "minecraft:mangrove_pressure_plate": "Plaque de pression en palétuvier",
    "minecraft:mangrove_propagule": "Propagule de palétuvier",
    "minecraft:mangrove_roots": "Racines de palétuvier",
    "minecraft:mangrove_sign": "Pancarte en palétuvier",
    "minecraft:mangrove_slab": "Dalle en palétuvier",
    "minecraft:mangrove_stairs": "Escalier en palétuvier",
    "minecraft:mangrove_trapdoor": "Trappe en palétuvier",
    "minecraft:mangrove_wall_hanging_sign": "Pancarte suspendue en palétuvier murale",
    "minecraft:mangrove_wall_sign": "Pancarte en palétuvier murale",
    "minecraft:mangrove_wood": "Bois de palétuvier",
    "minecraft:medium_amethyst_bud": "Cristal moyen d'améthyste",
    "minecraft:melon": "Pastèque",
    "minecraft:melon_stem": "Tige de pastèque",
    "minecraft:moss_block": "Bloc de mousse",
    "minecraft:moss_carpet": "Tapis de mousse",
    "minecraft:mossy_cobblestone": "Pierres moussues",
    "minecraft:mossy_cobblestone_slab": "Dalle en pierres moussues",
    "minecraft:mossy_cobblestone_stairs": "Escalier en pierres moussues",
    "minecraft:mossy_cobblestone_wall": "Muret de pierres moussues",
    "minecraft:mossy_stone_brick_slab": "Dalle en pierre taillée moussue",
    "minecraft:mossy_stone_brick_stairs": "Escalier en pierre taillée moussue",
    "minecraft:mossy_stone_brick_wall": "Muret en pierre taillée moussue",
    "minecraft:mossy_stone_bricks": "Pierre taillée moussue",
    "minecraft:moving_piston": "Bloc en mouvement",
    "minecraft:mud": "Boue",
    "minecraft:mud_brick_slab": "Dalle en briques de terre crue",
    "minecraft:mud_brick_stairs": "Escalier en briques de terre crue",
    "minecraft:mud_brick_wall": "Muret en briques de terre crue",
    "minecraft:mud_bricks": "Briques de terre crue",
    "minecraft:muddy_mangrove_roots": "Racines de palétuvier boueuses",
    "minecraft:mushroom_stem": "Tige de champignon",
    "minecraft:mycelium": "Mycélium",
    "minecraft:nether_brick_fence": "Barrière en briques du Nether",
    "minecraft:nether_brick_slab": "Dalle en briques du Nether",
    "minecraft:nether_brick_stairs": "Escalier en briques du Nether",
    "minecraft:nether_brick_wall": "Muret en briques du Nether",
    "minecraft:nether_bricks": "Briques du Nether",
    "minecraft:nether_gold_ore": "Minerai d'or du Nether",
    "minecraft:nether_portal": "Portail du Nether",
    "minecraft:nether_quartz_ore": "Minerai de quartz du Nether",
    "minecraft:nether_sprouts": "Germes du Nether",
    "minecraft:nether_wart": "Verrues du Nether",
    "minecraft:nether_wart_block": "Bloc de verrues du Nether",
    "minecraft:netherite_block": "Bloc de Netherite",
    "minecraft:netherrack": "Netherrack",
    "minecraft:note_block": "Bloc musical",
    "minecraft:oak_button": "Bouton en chêne",
    "minecraft:oak_door": "Porte en chêne",
    "minecraft:oak_fence": "Barrière en chêne",
    "minecraft:oak_fence_gate": "Portillon en chêne",
    "minecraft:oak_hanging_sign": "Pancarte suspendue en chêne",
    "minecraft:oak_leaves": "Feuilles de chêne",
    "minecraft:oak_log": "Bûche de chêne",
    "minecraft:oak_planks": "Planches de chêne",
    "minecraft:oak_pressure_plate": "Plaque de pression en chêne",
    "minecraft:oak_sapling": "Pousse de chêne",
    "minecraft:oak_sign": "Pancarte en chêne",
    "minecraft:oak_slab": "Dalle en chêne",
    "minecraft:oak_stairs": "Escalier en chêne",
    "minecraft:oak_trapdoor": "Trappe en chêne",
    "minecraft:oak_wall_hanging_sign": "Pancarte suspendue en chêne murale",
    "minecraft:oak_wall_sign": "Pancarte en chêne murale",
    "minecraft:oak_wood": "Bois de chêne",
    "minecraft:observer": "Observateur",
    "minecraft:obsidian": "Obsidienne",
    "minecraft:ochre_froglight": "Grelampe ocrée",
    "minecraft:ominous_banner": "Bannière menaçante",
    "minecraft:orange_banner": "Bannière orange",
    "minecraft:orange_bed": "Lit orange",
    "minecraft:orange_candle": "Bougie orange",
    "minecraft:orange_candle_cake": "Gâteau à bougie orange",
    "minecraft:orange_carpet": "Tapis orange",
    "minecraft:orange_concrete": "Béton orange",
    "minecraft:orange_concrete_powder": "Béton orange en poudre",
    "minecraft:orange_glazed_terracotta": "Terre cuite émaillée orange",
    "minecraft:orange_shulker_box": "Boîte de Shulker orange",
    "minecraft:orange_stained_glass": "Verre orange",
    "minecraft:orange_stained_glass_pane": "Vitre orange",
    "minecraft:orange_terracotta": "Terre cuite orange",
    "minecraft:orange_tulip": "Tulipe orange",
    "minecraft:orange_wool": "Laine orange",
    "minecraft:oxeye_daisy": "Marguerite",
    "minecraft:oxidized_chiseled_copper": "Cuivre sculpté oxydé",
    "minecraft:oxidized_copper": "Cuivre oxydé",
    "minecraft:oxidized_copper_bulb": "Ampoule en cuivre oxydée",
    "minecraft:oxidized_copper_door": "Porte en cuivre oxydée",
    "minecraft:oxidized_copper_grate": "Grille en cuivre oxydée",
    "minecraft:oxidized_copper_trapdoor": "Trappe en cuivre oxydée",
    "minecraft:oxidized_cut_copper": "Cuivre taillé oxydé",
    "minecraft:oxidized_cut_copper_slab": "Dalle en cuivre taillé oxydé",
    "minecraft:oxidized_cut_copper_stairs": "Escalier en cuivre taillé oxydé",
    "minecraft:packed_ice": "Glace compactée",
    "minecraft:packed_mud": "Terre crue",
    "minecraft:pearlescent_froglight": "Grelampe nacrée",
    "minecraft:peony": "Pivoine",
    "minecraft:petrified_oak_slab": "Dalle en chêne pétrifiée",
    "minecraft:piglin_head": "Tête de Piglin",
    "minecraft:piglin_wall_head": "Tête de Piglin murale",
    "minecraft:pink_banner": "Bannière rose",
    "minecraft:pink_bed": "Lit rose",
    "minecraft:pink_candle": "Bougie rose",
    "minecraft:pink_candle_cake": "Gâteau à bougie rose",
    "minecraft:pink_carpet": "Tapis rose",
    "minecraft:pink_concrete": "Béton rose",
    "minecraft:pink_concrete_powder": "Béton rose en poudre",
    "minecraft:pink_glazed_terracotta": "Terre cuite émaillée rose",
    "minecraft:pink_petals": "Pétales roses",
    "minecraft:pink_shulker_box": "Boîte de Shulker rose",
    "minecraft:pink_stained_glass": "Verre rose",
    "minecraft:pink_stained_glass_pane": "Vitre rose",
    "minecraft:pink_terracotta": "Terre cuite rose",
    "minecraft:pink_tulip": "Tulipe rose",
    "minecraft:pink_wool": "Laine rose",
    "minecraft:piston": "Piston",
    "minecraft:piston_head": "Tête de piston",
    "minecraft:pitcher_crop": "Plant de planturne",
    "minecraft:pitcher_plant": "Planturne",
    "minecraft:player_head": "Tête de joueur",
    "minecraft:player_head.named": "Tête de %s",
    "minecraft:player_wall_head": "Tête de joueur murale",
    "minecraft:podzol": "Podzol",
    "minecraft:pointed_dripstone": "Spéléothème pointu",
    "minecraft:polished_andesite": "Andésite polie",
    "minecraft:polished_andesite_slab": "Dalle d'andésite polie",
    "minecraft:polished_andesite_stairs": "Escalier d'andésite polie",
    "minecraft:polished_basalt": "Basalte poli",
    "minecraft:polished_blackstone": "Pierre noire",
    "minecraft:polished_blackstone_brick_slab": "Dalle en pierre noire taillée",
    "minecraft:polished_blackstone_brick_stairs": "Escalier en pierre noire taillée",
    "minecraft:polished_blackstone_brick_wall": "Muret en pierre noire taillée",
    "minecraft:polished_blackstone_bricks": "Pierre noire taillée",
    "minecraft:polished_blackstone_button": "Bouton en pierre noire",
    "minecraft:polished_blackstone_pressure_plate": "Plaque de pression en pierre noire",
    "minecraft:polished_blackstone_slab": "Dalle en pierre noire",
    "minecraft:polished_blackstone_stairs": "Escalier en pierre noire",
    "minecraft:polished_blackstone_wall": "Muret en pierre noire",
    "minecraft:polished_deepslate": "Ardoise des abîmes polie",
    "minecraft:polished_deepslate_slab": "Dalle en ardoise des abîmes polie",
    "minecraft:polished_deepslate_stairs": "Escalier en ardoise des abîmes polie",
    "minecraft:polished_deepslate_wall": "Muret en ardoise des abîmes polie",
    "minecraft:polished_diorite": "Diorite polie",
    "minecraft:polished_diorite_slab": "Dalle en diorite polie",
    "minecraft:polished_diorite_stairs": "Escalier en diorite polie",
    "minecraft:polished_granite": "Granite poli",
    "minecraft:polished_granite_slab": "Dalle en granite poli",
    "minecraft:polished_granite_stairs": "Escalier en granite poli",
    "minecraft:polished_tuff": "Tuf poli",
    "minecraft:polished_tuff_slab": "Dalle en tuf poli",
    "minecraft:polished_tuff_stairs": "Escalier en tuf poli",
    "minecraft:polished_tuff_wall": "Muret en tuf poli",
    "minecraft:poppy": "Coquelicot",
    "minecraft:potatoes": "Pommes de terre",
    "minecraft:potted_acacia_sapling": "Pousse d'acacia en pot",
    "minecraft:potted_allium": "Allium en pot",
    "minecraft:potted_azalea_bush": "Azalée en pot",
    "minecraft:potted_azure_bluet": "Houstonie bleue en pot",
    "minecraft:potted_bamboo": "Bambou en pot",
    "minecraft:potted_birch_sapling": "Pousse de bouleau en pot",
    "minecraft:potted_blue_orchid": "Orchidée bleue en pot",
    "minecraft:potted_brown_mushroom": "Champignon brun en pot",
    "minecraft:potted_cactus": "Cactus en pot",
    "minecraft:potted_cherry_sapling": "Pousse de cerisier en pot",
    "minecraft:potted_cornflower": "Bleuet en pot",
    "minecraft:potted_crimson_fungus": "Champignon carmin en pot",
    "minecraft:potted_crimson_roots": "Racines carmin en pot",
    "minecraft:potted_dandelion": "Pissenlit en pot",
    "minecraft:potted_dark_oak_sapling": "Pousse de chêne noir en pot",
    "minecraft:potted_dead_bush": "Arbuste mort en pot",
    "minecraft:potted_fern": "Fougère en pot",
    "minecraft:potted_flowering_azalea_bush": "Azalée fleurie en pot",
    "minecraft:potted_jungle_sapling": "Pousse d'acajou en pot",
    "minecraft:potted_lily_of_the_valley": "Muguet en pot",
    "minecraft:potted_mangrove_propagule": "Propagule de palétuvier en pot",
    "minecraft:potted_oak_sapling": "Pousse de chêne en pot",
    "minecraft:potted_orange_tulip": "Tulipe orange en pot",
    "minecraft:potted_oxeye_daisy": "Marguerite en pot",
    "minecraft:potted_pink_tulip": "Tulipe rose en pot",
    "minecraft:potted_poppy": "Coquelicot en pot",
    "minecraft:potted_red_mushroom": "Champignon rouge en pot",
    "minecraft:potted_red_tulip": "Tulipe rouge en pot",
    "minecraft:potted_spruce_sapling": "Pousse de sapin en pot",
    "minecraft:potted_torchflower": "Torche-fleur en pot",
    "minecraft:potted_warped_fungus": "Champignon biscornu en pot",
    "minecraft:potted_warped_roots": "Racines biscornues en pot",
    "minecraft:potted_white_tulip": "Tulipe blanche en pot",
    "minecraft:potted_wither_rose": "Rose de Wither en pot",
    "minecraft:powder_snow": "Neige poudreuse",
    "minecraft:powder_snow_cauldron": "Chaudron de neige poudreuse",
    "minecraft:powered_rail": "Rails de propulsion",
    "minecraft:prismarine": "Prismarine",
    "minecraft:prismarine_brick_slab": "Dalle de prismarine taillée",
    "minecraft:prismarine_brick_stairs": "Escalier en prismarine taillée",
    "minecraft:prismarine_bricks": "Prismarine taillée",
    "minecraft:prismarine_slab": "Dalle de prismarine",
    "minecraft:prismarine_stairs": "Escalier en prismarine",
    "minecraft:prismarine_wall": "Muret de prismarine",
    "minecraft:pumpkin": "Citrouille",
    "minecraft:pumpkin_stem": "Tige de citrouille",
    "minecraft:purple_banner": "Bannière violette",
    "minecraft:purple_bed": "Lit violet",
    "minecraft:purple_candle": "Bougie violette",
    "minecraft:purple_candle_cake": "Gâteau à bougie violette",
    "minecraft:purple_carpet": "Tapis violet",
    "minecraft:purple_concrete": "Béton violet",
    "minecraft:purple_concrete_powder": "Béton violet en poudre",
    "minecraft:purple_glazed_terracotta": "Terre cuite émaillée violette",
    "minecraft:purple_shulker_box": "Boîte de Shulker violette",
    "minecraft:purple_stained_glass": "Verre violet",
    "minecraft:purple_stained_glass_pane": "Vitre violette",
    "minecraft:purple_terracotta": "Terre cuite violette",
    "minecraft:purple_wool": "Laine violette",
    "minecraft:purpur_block": "Bloc de purpur",
    "minecraft:purpur_pillar": "Pilier de purpur",
    "minecraft:purpur_slab": "Dalle de purpur",
    "minecraft:purpur_stairs": "Escalier de purpur",
    "minecraft:quartz_block": "Bloc de quartz",
    "minecraft:quartz_bricks": "Briques de quartz",
    "minecraft:quartz_pillar": "Pilier de quartz",
    "minecraft:quartz_slab": "Dalle de quartz",
    "minecraft:quartz_stairs": "Escalier en quartz",
    "minecraft:rail": "Rails",
    "minecraft:raw_copper_block": "Bloc de cuivre brut",
    "minecraft:raw_gold_block": "Bloc d'or brut",
    "minecraft:raw_iron_block": "Bloc de fer brut",
    "minecraft:red_banner": "Bannière rouge",
    "minecraft:red_bed": "Lit rouge",
    "minecraft:red_candle": "Bougie rouge",
    "minecraft:red_candle_cake": "Gâteau à bougie rouge",
    "minecraft:red_carpet": "Tapis rouge",
    "minecraft:red_concrete": "Béton rouge",
    "minecraft:red_concrete_powder": "Béton rouge en poudre",
    "minecraft:red_glazed_terracotta": "Terre cuite émaillée rouge",
    "minecraft:red_mushroom": "Champignon rouge",
    "minecraft:red_mushroom_block": "Bloc de champignon rouge",
    "minecraft:red_nether_brick_slab": "Dalle en briques rouges du Nether",
    "minecraft:red_nether_brick_stairs": "Escalier en briques rouges du Nether",
    "minecraft:red_nether_brick_wall": "Muret en briques rouges du Nether",
    "minecraft:red_nether_bricks": "Briques rouges du Nether",
    "minecraft:red_sand": "Sable rouge",
    "minecraft:red_sandstone": "Grès rouge",
    "minecraft:red_sandstone_slab": "Dalle de grès rouge",
    "minecraft:red_sandstone_stairs": "Escalier en grès rouge",
    "minecraft:red_sandstone_wall": "Muret en grès rouge",
    "minecraft:red_shulker_box": "Boîte de Shulker rouge",
    "minecraft:red_stained_glass": "Verre rouge",
    "minecraft:red_stained_glass_pane": "Vitre rouge",
    "minecraft:red_terracotta": "Terre cuite rouge",
    "minecraft:red_tulip": "Tulipe rouge",
    "minecraft:red_wool": "Laine rouge",
    "minecraft:redstone_block": "Bloc de redstone",
    "minecraft:redstone_lamp": "Lampe à redstone",
    "minecraft:redstone_ore": "Minerai de redstone",
    "minecraft:redstone_torch": "Torche de redstone",
    "minecraft:redstone_wall_torch": "Torche de redstone murale",
    "minecraft:redstone_wire": "Câble de redstone",
    "minecraft:reinforced_deepslate": "Ardoise des abîmes renforcée",
    "minecraft:repeater": "Répéteur de redstone",
    "minecraft:repeating_command_block": "Bloc de commande à répétition",
    "minecraft:respawn_anchor": "Ancre de réapparition",
    "minecraft:rooted_dirt": "Terre racineuse",
    "minecraft:rose_bush": "Rosier",
    "minecraft:sand": "Sable",
    "minecraft:sandstone": "Grès",
    "minecraft:sandstone_slab": "Dalle de grès",
    "minecraft:sandstone_stairs": "Escalier en grès",
    "minecraft:sandstone_wall": "Muret en grès",
    "minecraft:scaffolding": "Échafaudage",
    "minecraft:sculk": "Sculk",
    "minecraft:sculk_catalyst": "Catalyseur de sculk",
    "minecraft:sculk_sensor": "Capteur sculk",
    "minecraft:sculk_shrieker": "Hurleur sculk",
    "minecraft:sculk_vein": "Veine de sculk",
    "minecraft:sea_lantern": "Lanterne aquatique",
    "minecraft:sea_pickle": "Cornichon de mer",
    "minecraft:seagrass": "Herbe aquatique",
    "minecraft:set_spawn": "Point de réapparition défini",
    "minecraft:shroomlight": "Champilampe",
    "minecraft:shulker_box": "Boîte de Shulker",
    "minecraft:skeleton_skull": "Crâne de squelette",
    "minecraft:skeleton_wall_skull": "Crâne de squelette mural",
    "minecraft:slime_block": "Bloc de Slime",
    "minecraft:small_amethyst_bud": "Petit cristal d'améthyste",
    "minecraft:small_dripleaf": "Petite foliogoutte",
    "minecraft:smithing_table": "Table de forge",
    "minecraft:smoker": "Fumoir",
    "minecraft:smooth_basalt": "Basalte lisse",
    "minecraft:smooth_quartz": "Bloc de quartz lisse",
    "minecraft:smooth_quartz_slab": "Dalle en quartz lisse",
    "minecraft:smooth_quartz_stairs": "Escalier en quartz lisse",
    "minecraft:smooth_red_sandstone": "Grès rouge lisse",
    "minecraft:smooth_red_sandstone_slab": "Dalle en grès rouge lisse",
    "minecraft:smooth_red_sandstone_stairs": "Escalier en grès rouge lisse",
    "minecraft:smooth_sandstone": "Grès lisse",
    "minecraft:smooth_sandstone_slab": "Dalle en grès lisse",
    "minecraft:smooth_sandstone_stairs": "Escalier en grès lisse",
    "minecraft:smooth_stone": "Roche lisse",
    "minecraft:smooth_stone_slab": "Dalle de roche lisse",
    "minecraft:sniffer_egg": "Oeuf de renifleur",
    "minecraft:snow": "Neige",
    "minecraft:snow_block": "Bloc de neige",
    "minecraft:soul_campfire": "Feu de camp des âmes",
    "minecraft:soul_fire": "Feu des âmes",
    "minecraft:soul_lantern": "Lanterne des âmes",
    "minecraft:soul_sand": "Sable des âmes",
    "minecraft:soul_soil": "Terre des âmes",
    "minecraft:soul_torch": "Torche des âmes",
    "minecraft:soul_wall_torch": "Torche des âmes murale",
    "minecraft:spawn.not_valid": "Vous n'avez pas de lit ou d'ancre de réapparition chargée, ou le bloc a été obstrué",
    "minecraft:spawner": "Générateur de créatures",
    "minecraft:spawner.desc1": "Interaction avec un œuf d'apparition :",
    "minecraft:spawner.desc2": "Définit le type de créature",
    "minecraft:sponge": "Éponge",
    "minecraft:spore_blossom": "Fleur sporifère",
    "minecraft:spruce_button": "Bouton en sapin",
    "minecraft:spruce_door": "Porte en sapin",
    "minecraft:spruce_fence": "Barrière en sapin",
    "minecraft:spruce_fence_gate": "Portillon en sapin",
    "minecraft:spruce_hanging_sign": "Pancarte suspendue en sapin",
    "minecraft:spruce_leaves": "Feuilles de sapin",
    "minecraft:spruce_log": "Bûche de sapin",
    "minecraft:spruce_planks": "Planches de sapin",
    "minecraft:spruce_pressure_plate": "Plaque de pression en sapin",
    "minecraft:spruce_sapling": "Pousse de sapin",
    "minecraft:spruce_sign": "Pancarte en sapin",
    "minecraft:spruce_slab": "Dalle en sapin",
    "minecraft:spruce_stairs": "Escalier en sapin",
    "minecraft:spruce_trapdoor": "Trappe en sapin",
    "minecraft:spruce_wall_hanging_sign": "Pancarte suspendue en sapin murale",
    "minecraft:spruce_wall_sign": "Pancarte en sapin murale",
    "minecraft:spruce_wood": "Bois de sapin",
    "minecraft:sticky_piston": "Piston collant",
    "minecraft:stone": "Roche",
    "minecraft:stone_brick_slab": "Dalle en pierre taillée",
    "minecraft:stone_brick_stairs": "Escalier en pierre taillée",
    "minecraft:stone_brick_wall": "Muret en pierre taillée",
    "minecraft:stone_bricks": "Pierre taillée",
    "minecraft:stone_button": "Bouton en pierre",
    "minecraft:stone_pressure_plate": "Plaque de pression en pierre",
    "minecraft:stone_slab": "Dalle de roche",
    "minecraft:stone_stairs": "Escalier de roche",
    "minecraft:stonecutter": "Tailleur de pierre",
    "minecraft:stripped_acacia_log": "Bûche d'acacia écorcée",
    "minecraft:stripped_acacia_wood": "Bois d'acacia écorcé",
    "minecraft:stripped_bamboo_block": "Bloc de bambou écorcé",
    "minecraft:stripped_birch_log": "Bûche de bouleau écorcée",
    "minecraft:stripped_birch_wood": "Bois de bouleau écorcé",
    "minecraft:stripped_cherry_log": "Bûche de cerisier écorcée",
    "minecraft:stripped_cherry_wood": "Bois de cerisier écorcé",
    "minecraft:stripped_crimson_hyphae": "Hyphes carmin dénudées",
    "minecraft:stripped_crimson_stem": "Tige carmin dénudée",
    "minecraft:stripped_dark_oak_log": "Bûche de chêne noir écorcée",
    "minecraft:stripped_dark_oak_wood": "Bois de chêne noir écorcé",
    "minecraft:stripped_jungle_log": "Bûche d'acajou écorcée",
    "minecraft:stripped_jungle_wood": "Bois d'acajou écorcé",
    "minecraft:stripped_mangrove_log": "Bûche de palétuvier écorcée",
    "minecraft:stripped_mangrove_wood": "Bois de palétuvier écorcé",
    "minecraft:stripped_oak_log": "Bûche de chêne écorcée",
    "minecraft:stripped_oak_wood": "Bois de chêne écorcé",
    "minecraft:stripped_spruce_log": "Bûche de sapin écorcée",
    "minecraft:stripped_spruce_wood": "Bois de sapin écorcé",
    "minecraft:stripped_warped_hyphae": "Hyphes biscornues dénudées",
    "minecraft:stripped_warped_stem": "Tige biscornue dénudée",
    "minecraft:structure_block": "Bloc de structure",
    "minecraft:structure_void": "Vide de structure",
    "minecraft:sugar_cane": "Canne à sucre",
    "minecraft:sunflower": "Tournesol",
    "minecraft:suspicious_gravel": "Gravier suspect",
    "minecraft:suspicious_sand": "Sable suspect",
    "minecraft:sweet_berry_bush": "Buisson à baies sucrées",
    "minecraft:tall_grass": "Hautes herbes",
    "minecraft:tall_seagrass": "Hautes herbes aquatiques",
    "minecraft:target": "Cible",
    "minecraft:terracotta": "Terre cuite",
    "minecraft:tinted_glass": "Verre teinté",
    "minecraft:tnt": "TNT",
    "minecraft:torch": "Torche",
    "minecraft:torchflower": "Torche-fleur",
    "minecraft:torchflower_crop": "Plant de torche-fleur",
    "minecraft:trapped_chest": "Coffre piégé",
    "minecraft:tripwire": "Fil de déclenchement",
    "minecraft:tripwire_hook": "Crochet",
    "minecraft:tube_coral": "Corail tubulaire",
    "minecraft:tube_coral_block": "Bloc de corail tubulaire",
    "minecraft:tube_coral_fan": "Gorgone de corail tubulaire",
    "minecraft:tube_coral_wall_fan": "Gorgone de corail tubulaire murale",
    "minecraft:tuff": "Tuf",
    "minecraft:tuff_brick_slab": "Dalle en briques de tuf",
    "minecraft:tuff_brick_stairs": "Escalier en tuf taillé",
    "minecraft:tuff_brick_wall": "Muret en briques de tuf",
    "minecraft:tuff_bricks": "Briques de tuf",
    "minecraft:tuff_slab": "Dalle de tuf",
    "minecraft:tuff_stairs": "Escalier de tuf",
    "minecraft:tuff_wall": "Muret de tuf",
    "minecraft:turtle_egg": "Oeuf de tortue",
    "minecraft:twisting_vines": "Lianes tortueuses",
    "minecraft:twisting_vines_plant": "Plante de lianes tortueuses",
    "minecraft:verdant_froglight": "Grelampe verdoyante",
    "minecraft:vine": "Lianes",
    "minecraft:void_air": "Air du vide",
    "minecraft:wall_torch": "Torche murale",
    "minecraft:warped_button": "Bouton biscornu",
    "minecraft:warped_door": "Porte biscornue",
    "minecraft:warped_fence": "Barrière biscornue",
    "minecraft:warped_fence_gate": "Portillon biscornu",
    "minecraft:warped_fungus": "Champignon biscornu",
    "minecraft:warped_hanging_sign": "Pancarte suspendue biscornue",
    "minecraft:warped_hyphae": "Hyphes biscornues",
    "minecraft:warped_nylium": "Nylium biscornu",
    "minecraft:warped_planks": "Planches biscornues",
    "minecraft:warped_pressure_plate": "Plaque de pression biscornue",
    "minecraft:warped_roots": "Racines biscornues",
    "minecraft:warped_sign": "Pancarte biscornue",
    "minecraft:warped_slab": "Dalle biscornue",
    "minecraft:warped_stairs": "Escalier biscornu",
    "minecraft:warped_stem": "Tige biscornue",
    "minecraft:warped_trapdoor": "Trappe biscornue",
    "minecraft:warped_wall_hanging_sign": "Pancarte suspendue biscornue murale",
    "minecraft:warped_wall_sign": "Pancarte biscornue murale",
    "minecraft:warped_wart_block": "Bloc de verrues biscornues",
    "minecraft:water": "Eau",
    "minecraft:water_cauldron": "Chaudron d'eau",
    "minecraft:waxed_chiseled_copper": "Cuivre sculpté ciré",
    "minecraft:waxed_copper_block": "Bloc de cuivre ciré",
    "minecraft:waxed_copper_bulb": "Ampoule en cuivre cirée",
    "minecraft:waxed_copper_door": "Porte en cuivre cirée",
    "minecraft:waxed_copper_grate": "Grille en cuivre cirée",
    "minecraft:waxed_copper_trapdoor": "Trappe en cuivre cirée",
    "minecraft:waxed_cut_copper": "Cuivre taillé ciré",
    "minecraft:waxed_cut_copper_slab": "Dalle en cuivre taillé cirée",
    "minecraft:waxed_cut_copper_stairs": "Escalier en cuivre taillé ciré",
    "minecraft:waxed_exposed_chiseled_copper": "Cuivre sculpté exposé ciré",
    "minecraft:waxed_exposed_copper": "Cuivre exposé ciré",
    "minecraft:waxed_exposed_copper_bulb": "Ampoule en cuivre exposée cirée",
    "minecraft:waxed_exposed_copper_door": "Porte en cuivre exposée cirée",
    "minecraft:waxed_exposed_copper_grate": "Grille en cuivre exposée cirée",
    "minecraft:waxed_exposed_copper_trapdoor": "Trappe en cuivre exposée cirée",
    "minecraft:waxed_exposed_cut_copper": "Cuivre taillé exposé ciré",
    "minecraft:waxed_exposed_cut_copper_slab": "Dalle en cuivre taillé exposé cirée",
    "minecraft:waxed_exposed_cut_copper_stairs": "Escalier en cuivre taillé exposé ciré",
    "minecraft:waxed_oxidized_chiseled_copper": "Cuivre sculpté oxydé ciré",
    "minecraft:waxed_oxidized_copper": "Cuivre oxydé ciré",
    "minecraft:waxed_oxidized_copper_bulb": "Ampoule en cuivre oxydée cirée",
    "minecraft:waxed_oxidized_copper_door": "Porte en cuivre oxydée cirée",
    "minecraft:waxed_oxidized_copper_grate": "Grille en cuivre oxydée cirée",
    "minecraft:waxed_oxidized_copper_trapdoor": "Trappe en cuivre oxydée cirée",
    "minecraft:waxed_oxidized_cut_copper": "Cuivre taillé oxydé ciré",
    "minecraft:waxed_oxidized_cut_copper_slab": "Dalle en cuivre taillé oxydé cirée",
    "minecraft:waxed_oxidized_cut_copper_stairs": "Escalier en cuivre taillé oxydé ciré",
    "minecraft:waxed_weathered_chiseled_copper": "Cuivre sculpté érodé ciré",
    "minecraft:waxed_weathered_copper": "Cuivre érodé ciré",
    "minecraft:waxed_weathered_copper_bulb": "Ampoule en cuivre érodée cirée",
    "minecraft:waxed_weathered_copper_door": "Porte en cuivre érodée cirée",
    "minecraft:waxed_weathered_copper_grate": "Grille en cuivre érodée cirée",
    "minecraft:waxed_weathered_copper_trapdoor": "Trappe en cuivre érodée cirée",
    "minecraft:waxed_weathered_cut_copper": "Cuivre taillé érodé ciré",
    "minecraft:waxed_weathered_cut_copper_slab": "Dalle en cuivre taillé érodé cirée",
    "minecraft:waxed_weathered_cut_copper_stairs": "Escalier en cuivre taillé érodé ciré",
    "minecraft:weathered_chiseled_copper": "Cuivre sculpté érodé",
    "minecraft:weathered_copper": "Cuivre érodé",
    "minecraft:weathered_copper_bulb": "Ampoule en cuivre érodée",
    "minecraft:weathered_copper_door": "Porte en cuivre érodée",
    "minecraft:weathered_copper_grate": "Grille en cuivre érodée",
    "minecraft:weathered_copper_trapdoor": "Trappe en cuivre érodée",
    "minecraft:weathered_cut_copper": "Cuivre taillé érodé",
    "minecraft:weathered_cut_copper_slab": "Dalle en cuivre taillé érodé",
    "minecraft:weathered_cut_copper_stairs": "Escalier en cuivre taillé érodé",
    "minecraft:weeping_vines": "Lianes pleureuses",
    "minecraft:weeping_vines_plant": "Plante de lianes pleureuses",
    "minecraft:wet_sponge": "Éponge mouillée",
    "minecraft:white_banner": "Bannière blanche",
    "minecraft:white_bed": "Lit blanc",
    "minecraft:white_candle": "Bougie blanche",
    "minecraft:white_candle_cake": "Gâteau à bougie blanche",
    "minecraft:white_carpet": "Tapis blanc",
    "minecraft:white_concrete": "Béton blanc",
    "minecraft:white_concrete_powder": "Béton blanc en poudre",
    "minecraft:white_glazed_terracotta": "Terre cuite émaillée blanche",
    "minecraft:white_shulker_box": "Boîte de Shulker blanche",
    "minecraft:white_stained_glass": "Verre blanc",
    "minecraft:white_stained_glass_pane": "Vitre blanche",
    "minecraft:white_terracotta": "Terre cuite blanche",
    "minecraft:white_tulip": "Tulipe blanche",
    "minecraft:white_wool": "Laine blanche",
    "minecraft:wither_rose": "Rose de Wither",
    "minecraft:wither_skeleton_skull": "Crâne de Wither squelette",
    "minecraft:wither_skeleton_wall_skull": "Crâne de Wither squelette mural",
    "minecraft:yellow_banner": "Bannière jaune",
    "minecraft:yellow_bed": "Lit jaune",
    "minecraft:yellow_candle": "Bougie jaune",
    "minecraft:yellow_candle_cake": "Gâteau à bougie jaune",
    "minecraft:yellow_carpet": "Tapis jaune",
    "minecraft:yellow_concrete": "Béton jaune",
    "minecraft:yellow_concrete_powder": "Béton jaune en poudre",
    "minecraft:yellow_glazed_terracotta": "Terre cuite émaillée jaune",
    "minecraft:yellow_shulker_box": "Boîte de Shulker jaune",
    "minecraft:yellow_stained_glass": "Verre jaune",
    "minecraft:yellow_stained_glass_pane": "Vitre jaune",
    "minecraft:yellow_terracotta": "Terre cuite jaune",
    "minecraft:yellow_wool": "Laine jaune",
    "minecraft:zombie_head": "Tête de zombie",
    "minecraft:zombie_wall_head": "Tête de zombie murale",
    "canBreak": "Peut détruire :",
    "canPlace": "Peut se placer sur :",
    "color": "Couleur : %s",
    "disabled": "Objet désactivé",
    "durability": "Durabilité : %s / %s",
    "dyed": "Teint",
    "minecraft:acacia_boat": "Bateau en acacia",
    "minecraft:acacia_chest_boat": "Bateau de stockage en acacia",
    "minecraft:allay_spawn_egg": "Oeuf d'apparition d'Allay",
    "minecraft:amethyst_shard": "Éclat d'améthyste",
    "minecraft:angler_pottery_shard": "Tesson de poterie de pêcheur",
    "minecraft:angler_pottery_sherd": "Tesson de poterie de pêcheur",
    "minecraft:apple": "Pomme",
    "minecraft:archer_pottery_shard": "Tesson de poterie d'archer",
    "minecraft:archer_pottery_sherd": "Tesson de poterie d'archer",
    "minecraft:armor_stand": "Porte-armure",
    "minecraft:arms_up_pottery_shard": "Tesson de poterie de figure",
    "minecraft:arms_up_pottery_sherd": "Tesson de poterie de figure",
    "minecraft:arrow": "Flèche",
    "minecraft:axolotl_bucket": "Axolotl dans un seau",
    "minecraft:axolotl_spawn_egg": "Oeuf d'apparition d'axolotl",
    "minecraft:baked_potato": "Pomme de terre cuite",
    "minecraft:bamboo_chest_raft": "Radeau de stockage en bambou",
    "minecraft:bamboo_raft": "Radeau en bambou",
    "minecraft:bat_spawn_egg": "Oeuf d'apparition de chauve-souris",
    "minecraft:bee_spawn_egg": "Oeuf d'apparition d'abeille",
    "minecraft:beef": "Boeuf cru",
    "minecraft:beetroot": "Betterave",
    "minecraft:beetroot_seeds": "Graines de betterave",
    "minecraft:beetroot_soup": "Soupe de betteraves",
    "minecraft:birch_boat": "Bateau en bouleau",
    "minecraft:birch_chest_boat": "Bateau de stockage en bouleau",
    "minecraft:black_dye": "Teinture noire",
    "minecraft:blade_pottery_shard": "Tesson de poterie de lame",
    "minecraft:blade_pottery_sherd": "Tesson de poterie de lame",
    "minecraft:blaze_powder": "Poudre de Blaze",
    "minecraft:blaze_rod": "Bâton de Blaze",
    "minecraft:blaze_spawn_egg": "Oeuf d'apparition de Blaze",
    "minecraft:blue_dye": "Teinture bleue",
    "minecraft:bone": "Os",
    "minecraft:bone_meal": "Poudre d'os",
    "minecraft:book": "Livre",
    "minecraft:bow": "Arc",
    "minecraft:bowl": "Bol",
    "minecraft:bread": "Pain",
    "minecraft:brewer_pottery_shard": "Tesson de poterie d'alchimiste",
    "minecraft:brewer_pottery_sherd": "Tesson de poterie d'alchimiste",
    "minecraft:brick": "Brique",
    "minecraft:brown_dye": "Teinture marron",
    "minecraft:brush": "Pinceau",
    "minecraft:bucket": "Seau",
    "minecraft:bundle": "Sac",
    "minecraft:bundle.fullness": "%s/%s",
    "minecraft:burn_pottery_shard": "Tesson de poterie de feu",
    "minecraft:burn_pottery_sherd": "Tesson de poterie de feu",
    "minecraft:camel_spawn_egg": "Oeuf d'apparition de dromadaire",
    "minecraft:carrot": "Carotte",
    "minecraft:carrot_on_a_stick": "Carotte sur un bâton",
    "minecraft:cat_spawn_egg": "Oeuf d'apparition de chat",
    "minecraft:cauldron": "Chaudron",
    "minecraft:cave_spider_spawn_egg": "Oeuf d'apparition d'araignée venimeuse",
    "minecraft:chainmail_boots": "Bottes de mailles",
    "minecraft:chainmail_chestplate": "Cotte de mailles",
    "minecraft:chainmail_helmet": "Casque de mailles",
    "minecraft:chainmail_leggings": "Jambières de mailles",
    "minecraft:charcoal": "Charbon de bois",
    "minecraft:cherry_boat": "Bateau en cerisier",
    "minecraft:cherry_chest_boat": "Bateau de stockage en cerisier",
    "minecraft:chest_minecart": "Wagonnet de stockage",
    "minecraft:chicken": "Poulet cru",
    "minecraft:chicken_spawn_egg": "Oeuf d'apparition de poule",
    "minecraft:chorus_fruit": "Chorus",
    "minecraft:clay_ball": "Motte d'argile",
    "minecraft:clock": "Montre",
    "minecraft:coal": "Charbon",
    "minecraft:coast_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:cocoa_beans": "Fèves de cacao",
    "minecraft:cod": "Morue crue",
    "minecraft:cod_bucket": "Morue dans un seau",
    "minecraft:cod_spawn_egg": "Oeuf d'apparition de morue",
    "minecraft:command_block_minecart": "Wagonnet à bloc de commande",
    "minecraft:compass": "Boussole",
    "minecraft:cooked_beef": "Steak",
    "minecraft:cooked_chicken": "Poulet rôti",
    "minecraft:cooked_cod": "Morue cuite",
    "minecraft:cooked_mutton": "Mouton cuit",
    "minecraft:cooked_porkchop": "Côtelette de porc cuite",
    "minecraft:cooked_rabbit": "Lapin cuit",
    "minecraft:cooked_salmon": "Saumon cuit",
    "minecraft:cookie": "Cookie",
    "minecraft:copper_ingot": "Lingot de cuivre",
    "minecraft:cow_spawn_egg": "Oeuf d'apparition de vache",
    "minecraft:creeper_banner_pattern": "Motif de bannière",
    "minecraft:creeper_banner_pattern.desc": "Face de Creeper",
    "minecraft:creeper_spawn_egg": "Oeuf d'apparition de Creeper",
    "minecraft:crossbow": "Arbalète",
    "minecraft:crossbow.projectile": "Projectile :",
    "minecraft:cyan_dye": "Teinture cyan",
    "minecraft:danger_pottery_shard": "Tesson de poterie de danger",
    "minecraft:danger_pottery_sherd": "Tesson de poterie de danger",
    "minecraft:dark_oak_boat": "Bateau en chêne noir",
    "minecraft:dark_oak_chest_boat": "Bateau de stockage en chêne noir",
    "minecraft:debug_stick": "Bâton de débogage",
    "minecraft:debug_stick.empty": "%s n'a aucune propriété",
    "minecraft:debug_stick.select": "\"%s\" sélectionné (%s)",
    "minecraft:debug_stick.update": "\"%s\" à %s",
    "minecraft:diamond": "Diamant",
    "minecraft:diamond_axe": "Hache en diamant",
    "minecraft:diamond_boots": "Bottes en diamant",
    "minecraft:diamond_chestplate": "Plastron en diamant",
    "minecraft:diamond_helmet": "Casque en diamant",
    "minecraft:diamond_hoe": "Houe en diamant",
    "minecraft:diamond_horse_armor": "Armure en diamant pour cheval",
    "minecraft:diamond_leggings": "Jambières en diamant",
    "minecraft:diamond_pickaxe": "Pioche en diamant",
    "minecraft:diamond_shovel": "Pelle en diamant",
    "minecraft:diamond_sword": "Épée en diamant",
    "minecraft:disc_fragment_5": "Fragment de disque",
    "minecraft:disc_fragment_5.desc": "Disque - 5",
    "minecraft:dolphin_spawn_egg": "Oeuf d'apparition de dauphin",
    "minecraft:donkey_spawn_egg": "Oeuf d'apparition d'âne",
    "minecraft:dragon_breath": "Souffle de dragon",
    "minecraft:dried_kelp": "Algue séchée",
    "minecraft:drowned_spawn_egg": "Oeuf d'apparition de noyé",
    "minecraft:dune_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:echo_shard": "Éclat d'écho",
    "minecraft:egg": "Oeuf",
    "minecraft:elder_guardian_spawn_egg": "Oeuf d'apparition de grand gardien",
    "minecraft:elytra": "Élytres",
    "minecraft:emerald": "Émeraude",
    "minecraft:enchanted_book": "Livre enchanté",
    "minecraft:enchanted_golden_apple": "Pomme dorée enchantée",
    "minecraft:end_crystal": "Cristal de l'End",
    "minecraft:ender_dragon_spawn_egg": "Oeuf d'apparition d'Ender Dragon",
    "minecraft:ender_eye": "Oeil de l'Ender",
    "minecraft:ender_pearl": "Perle de l'Ender",
    "minecraft:enderman_spawn_egg": "Oeuf d'apparition d'Enderman",
    "minecraft:endermite_spawn_egg": "Oeuf d'apparition d'Endermite",
    "minecraft:evoker_spawn_egg": "Oeuf d'apparition d'évocateur",
    "minecraft:experience_bottle": "Fiole d'expérience",
    "minecraft:explorer_pottery_shard": "Tesson de poterie d'explorateur",
    "minecraft:explorer_pottery_sherd": "Tesson de poterie d'explorateur",
    "minecraft:eye_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:feather": "Plume",
    "minecraft:fermented_spider_eye": "Oeil d'araignée fermenté",
    "minecraft:filled_map": "Carte",
    "minecraft:fire_charge": "Boule de feu",
    "minecraft:firework_rocket": "Fusée de feu d'artifice",
    "minecraft:firework_rocket.flight": "Durée de vol :",
    "minecraft:firework_star": "Étoile de feu d'artifice",
    "minecraft:firework_star.black": "Noir",
    "minecraft:firework_star.blue": "Bleu",
    "minecraft:firework_star.brown": "Marron",
    "minecraft:firework_star.custom_color": "Personnalisée",
    "minecraft:firework_star.cyan": "Cyan",
    "minecraft:firework_star.fade_to": "S'estompe sur du",
    "minecraft:firework_star.flicker": "Étincelles",
    "minecraft:firework_star.gray": "Gris",
    "minecraft:firework_star.green": "Vert",
    "minecraft:firework_star.light_blue": "Bleu clair",
    "minecraft:firework_star.light_gray": "Gris clair",
    "minecraft:firework_star.lime": "Vert clair",
    "minecraft:firework_star.magenta": "Magenta",
    "minecraft:firework_star.orange": "Orange",
    "minecraft:firework_star.pink": "Rose",
    "minecraft:firework_star.purple": "Violet",
    "minecraft:firework_star.red": "Rouge",
    "minecraft:firework_star.shape": "Forme inconnue",
    "minecraft:firework_star.shape.burst": "Éclatement",
    "minecraft:firework_star.shape.creeper": "En forme de Creeper",
    "minecraft:firework_star.shape.large_ball": "Grosse boule",
    "minecraft:firework_star.shape.small_ball": "Petite boule",
    "minecraft:firework_star.shape.star": "En forme d'étoile",
    "minecraft:firework_star.trail": "Traînées",
    "minecraft:firework_star.white": "Blanc",
    "minecraft:firework_star.yellow": "Jaune",
    "minecraft:fishing_rod": "Canne à pêche",
    "minecraft:flint": "Silex",
    "minecraft:flint_and_steel": "Briquet",
    "minecraft:flower_banner_pattern": "Motif de bannière",
    "minecraft:flower_banner_pattern.desc": "Fleur",
    "minecraft:flower_pot": "Pot de fleurs",
    "minecraft:fox_spawn_egg": "Oeuf d'apparition de renard",
    "minecraft:friend_pottery_shard": "Tesson de poterie d'ami",
    "minecraft:friend_pottery_sherd": "Tesson de poterie d'ami",
    "minecraft:frog_spawn_egg": "Oeuf d'apparition de grenouille",
    "minecraft:furnace_minecart": "Wagonnet motorisé",
    "minecraft:ghast_spawn_egg": "Oeuf d'apparition de Ghast",
    "minecraft:ghast_tear": "Larme de Ghast",
    "minecraft:glass_bottle": "Fiole",
    "minecraft:glistering_melon_slice": "Tranche de pastèque scintillante",
    "minecraft:globe_banner_pattern": "Motif de bannière",
    "minecraft:globe_banner_pattern.desc": "Globe",
    "minecraft:glow_berries": "Baies lumineuses",
    "minecraft:glow_ink_sac": "Poche d'encre luisante",
    "minecraft:glow_item_frame": "Cadre luisant",
    "minecraft:glow_squid_spawn_egg": "Oeuf d'apparition de poulpe luisant",
    "minecraft:glowstone_dust": "Poudre lumineuse",
    "minecraft:goat_horn": "Corne de chèvre",
    "minecraft:goat_spawn_egg": "Oeuf d'apparition de chèvre",
    "minecraft:gold_ingot": "Lingot d'or",
    "minecraft:gold_nugget": "Pépite d'or",
    "minecraft:golden_apple": "Pomme dorée",
    "minecraft:golden_axe": "Hache en or",
    "minecraft:golden_boots": "Bottes en or",
    "minecraft:golden_carrot": "Carotte dorée",
    "minecraft:golden_chestplate": "Plastron en or",
    "minecraft:golden_helmet": "Casque en or",
    "minecraft:golden_hoe": "Houe en or",
    "minecraft:golden_horse_armor": "Armure en or pour cheval",
    "minecraft:golden_leggings": "Jambières en or",
    "minecraft:golden_pickaxe": "Pioche en or",
    "minecraft:golden_shovel": "Pelle en or",
    "minecraft:golden_sword": "Épée en or",
    "minecraft:gray_dye": "Teinture grise",
    "minecraft:green_dye": "Teinture verte",
    "minecraft:guardian_spawn_egg": "Oeuf d'apparition de gardien",
    "minecraft:gunpowder": "Poudre à canon",
    "minecraft:heart_of_the_sea": "Coeur de la mer",
    "minecraft:heart_pottery_shard": "Tesson de poterie de coeur",
    "minecraft:heart_pottery_sherd": "Tesson de poterie de coeur",
    "minecraft:heartbreak_pottery_shard": "Tesson de poterie de coeur brisé",
    "minecraft:heartbreak_pottery_sherd": "Tesson de poterie de coeur brisé",
    "minecraft:hoglin_spawn_egg": "Oeuf d'apparition de Hoglin",
    "minecraft:honey_bottle": "Fiole de miel",
    "minecraft:honeycomb": "Rayon de miel",
    "minecraft:hopper_minecart": "Wagonnet à entonnoir",
    "minecraft:horse_spawn_egg": "Oeuf d'apparition de cheval",
    "minecraft:host_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:howl_pottery_shard": "Tesson de poterie de loup",
    "minecraft:howl_pottery_sherd": "Tesson de poterie de loup",
    "minecraft:husk_spawn_egg": "Oeuf d'apparition de zombie momifié",
    "minecraft:ink_sac": "Poche d'encre",
    "minecraft:iron_axe": "Hache en fer",
    "minecraft:iron_boots": "Bottes en fer",
    "minecraft:iron_chestplate": "Plastron en fer",
    "minecraft:iron_golem_spawn_egg": "Oeuf d'apparition de golem de fer",
    "minecraft:iron_helmet": "Casque en fer",
    "minecraft:iron_hoe": "Houe en fer",
    "minecraft:iron_horse_armor": "Armure en fer pour cheval",
    "minecraft:iron_ingot": "Lingot de fer",
    "minecraft:iron_leggings": "Jambières en fer",
    "minecraft:iron_nugget": "Pépite de fer",
    "minecraft:iron_pickaxe": "Pioche en fer",
    "minecraft:iron_shovel": "Pelle en fer",
    "minecraft:iron_sword": "Épée en fer",
    "minecraft:item_frame": "Cadre",
    "minecraft:jungle_boat": "Bateau en acajou",
    "minecraft:jungle_chest_boat": "Bateau de stockage en acajou",
    "minecraft:knowledge_book": "Livre des connaissances",
    "minecraft:lapis_lazuli": "Lapis-lazuli",
    "minecraft:lava_bucket": "Seau de lave",
    "minecraft:lead": "Laisse",
    "minecraft:leather": "Cuir",
    "minecraft:leather_boots": "Bottes en cuir",
    "minecraft:leather_chestplate": "Tunique en cuir",
    "minecraft:leather_helmet": "Chapeau en cuir",
    "minecraft:leather_horse_armor": "Armure en cuir pour cheval",
    "minecraft:leather_leggings": "Pantalon en cuir",
    "minecraft:light_blue_dye": "Teinture bleu clair",
    "minecraft:light_gray_dye": "Teinture gris clair",
    "minecraft:lime_dye": "Teinture vert clair",
    "minecraft:lingering_potion": "Potion persistante",
    "minecraft:lingering_potion.effect.awkward": "Potion étrange persistante",
    "minecraft:lingering_potion.effect.empty": "Potion persistante non fabricable",
    "minecraft:lingering_potion.effect.fire_resistance": "Potion de résistance au feu persistante",
    "minecraft:lingering_potion.effect.harming": "Potion de dégâts persistante",
    "minecraft:lingering_potion.effect.healing": "Potion de soin persistante",
    "minecraft:lingering_potion.effect.invisibility": "Potion d'invisibilité persistante",
    "minecraft:lingering_potion.effect.leaping": "Potion de saut persistante",
    "minecraft:lingering_potion.effect.levitation": "Potion de lévitation persistante",
    "minecraft:lingering_potion.effect.luck": "Potion de chance persistante",
    "minecraft:lingering_potion.effect.mundane": "Potion banale persistante",
    "minecraft:lingering_potion.effect.night_vision": "Potion de vision nocturne persistante",
    "minecraft:lingering_potion.effect.poison": "Potion de poison persistante",
    "minecraft:lingering_potion.effect.regeneration": "Potion de régénération persistante",
    "minecraft:lingering_potion.effect.slow_falling": "Potion de chute lente persistante",
    "minecraft:lingering_potion.effect.slowness": "Potion de lenteur persistante",
    "minecraft:lingering_potion.effect.strength": "Potion de force persistante",
    "minecraft:lingering_potion.effect.swiftness": "Potion de rapidité persistante",
    "minecraft:lingering_potion.effect.thick": "Potion épaisse persistante",
    "minecraft:lingering_potion.effect.turtle_master": "Potion du Maître Tortue persistante",
    "minecraft:lingering_potion.effect.water": "Fiole d'eau persistante",
    "minecraft:lingering_potion.effect.water_breathing": "Potion de respiration aquatique persistante",
    "minecraft:lingering_potion.effect.weakness": "Potion de faiblesse persistante",
    "minecraft:llama_spawn_egg": "Oeuf d'apparition de lama",
    "minecraft:lodestone_compass": "Boussole magnétisée",
    "minecraft:magenta_dye": "Teinture magenta",
    "minecraft:magma_cream": "Crème de magma",
    "minecraft:magma_cube_spawn_egg": "Oeuf d'apparition de cube de magma",
    "minecraft:mangrove_boat": "Bateau en palétuvier",
    "minecraft:mangrove_chest_boat": "Bateau de stockage en palétuvier",
    "minecraft:map": "Carte vierge",
    "minecraft:melon_seeds": "Graines de pastèque",
    "minecraft:melon_slice": "Tranche de pastèque",
    "minecraft:milk_bucket": "Seau de lait",
    "minecraft:minecart": "Wagonnet",
    "minecraft:miner_pottery_shard": "Tesson de poterie de mineur",
    "minecraft:miner_pottery_sherd": "Tesson de poterie de mineur",
    "minecraft:mojang_banner_pattern": "Motif de bannière",
    "minecraft:mojang_banner_pattern.desc": "Symbole",
    "minecraft:mooshroom_spawn_egg": "Oeuf d'apparition de Mooshroom",
    "minecraft:mourner_pottery_shard": "Tesson de poterie de pleureur",
    "minecraft:mourner_pottery_sherd": "Tesson de poterie de pleureur",
    "minecraft:mule_spawn_egg": "Oeuf d'apparition de mule",
    "minecraft:mushroom_stew": "Soupe de champignons",
    "minecraft:music_disc_11": "Disque",
    "minecraft:music_disc_11.desc": "C418 - 11",
    "minecraft:music_disc_13": "Disque",
    "minecraft:music_disc_13.desc": "C418 - 13",
    "minecraft:music_disc_5": "Disque",
    "minecraft:music_disc_5.desc": "Samuel Åberg - 5",
    "minecraft:music_disc_blocks": "Disque",
    "minecraft:music_disc_blocks.desc": "C418 - blocks",
    "minecraft:music_disc_cat": "Disque",
    "minecraft:music_disc_cat.desc": "C418 - cat",
    "minecraft:music_disc_chirp": "Disque",
    "minecraft:music_disc_chirp.desc": "C418 - chirp",
    "minecraft:music_disc_far": "Disque",
    "minecraft:music_disc_far.desc": "C418 - far",
    "minecraft:music_disc_mall": "Disque",
    "minecraft:music_disc_mall.desc": "C418 - mall",
    "minecraft:music_disc_mellohi": "Disque",
    "minecraft:music_disc_mellohi.desc": "C418 - mellohi",
    "minecraft:music_disc_otherside": "Disque",
    "minecraft:music_disc_otherside.desc": "Lena Raine - otherside",
    "minecraft:music_disc_pigstep": "Disque",
    "minecraft:music_disc_pigstep.desc": "Lena Raine - Pigstep",
    "minecraft:music_disc_relic": "Disque",
    "minecraft:music_disc_relic.desc": "Aaron Cherof - Relic",
    "minecraft:music_disc_stal": "Disque",
    "minecraft:music_disc_stal.desc": "C418 - stal",
    "minecraft:music_disc_strad": "Disque",
    "minecraft:music_disc_strad.desc": "C418 - strad",
    "minecraft:music_disc_wait": "Disque",
    "minecraft:music_disc_wait.desc": "C418 - wait",
    "minecraft:music_disc_ward": "Disque",
    "minecraft:music_disc_ward.desc": "C418 - ward",
    "minecraft:mutton": "Mouton cru",
    "minecraft:name_tag": "Étiquette",
    "minecraft:nautilus_shell": "Coquille de nautile",
    "minecraft:nether_brick": "Brique du Nether",
    "minecraft:nether_star": "Étoile du Nether",
    "minecraft:netherite_axe": "Hache en Netherite",
    "minecraft:netherite_boots": "Bottes en Netherite",
    "minecraft:netherite_chestplate": "Plastron en Netherite",
    "minecraft:netherite_helmet": "Casque en Netherite",
    "minecraft:netherite_hoe": "Houe en Netherite",
    "minecraft:netherite_ingot": "Lingot de Netherite",
    "minecraft:netherite_leggings": "Jambières en Netherite",
    "minecraft:netherite_pickaxe": "Pioche en Netherite",
    "minecraft:netherite_scrap": "Fragments de Netherite",
    "minecraft:netherite_shovel": "Pelle en Netherite",
    "minecraft:netherite_sword": "Épée en Netherite",
    "minecraft:netherite_upgrade_smithing_template": "Modèle de forge",
    "minecraft:oak_boat": "Bateau en chêne",
    "minecraft:oak_chest_boat": "Bateau de stockage en chêne",
    "minecraft:ocelot_spawn_egg": "Oeuf d'apparition d'ocelot",
    "minecraft:orange_dye": "Teinture orange",
    "minecraft:painting": "Tableau",
    "minecraft:panda_spawn_egg": "Oeuf d'apparition de panda",
    "minecraft:paper": "Papier",
    "minecraft:parrot_spawn_egg": "Oeuf d'apparition de perroquet",
    "minecraft:phantom_membrane": "Membrane de Phantom",
    "minecraft:phantom_spawn_egg": "Oeuf d'apparition de Phantom",
    "minecraft:pig_spawn_egg": "Oeuf d'apparition de cochon",
    "minecraft:piglin_banner_pattern": "Motif de bannière",
    "minecraft:piglin_banner_pattern.desc": "Groin",
    "minecraft:piglin_brute_spawn_egg": "Oeuf d'apparition de Piglin barbare",
    "minecraft:piglin_spawn_egg": "Oeuf d'apparition de Piglin",
    "minecraft:pillager_spawn_egg": "Oeuf d'apparition de pillard",
    "minecraft:pink_dye": "Teinture rose",
    "minecraft:pitcher_pod": "Gousse de planturne",
    "minecraft:plenty_pottery_shard": "Tesson de poterie de richesse",
    "minecraft:plenty_pottery_sherd": "Tesson de poterie de richesse",
    "minecraft:poisonous_potato": "Pomme de terre empoisonnée",
    "minecraft:polar_bear_spawn_egg": "Oeuf d'apparition d'ours blanc",
    "minecraft:popped_chorus_fruit": "Chorus éclaté",
    "minecraft:porkchop": "Côtelette de porc crue",
    "minecraft:potato": "Pomme de terre",
    "minecraft:potion": "Potion",
    "minecraft:potion.effect.awkward": "Potion étrange",
    "minecraft:potion.effect.empty": "Potion non fabricable",
    "minecraft:potion.effect.fire_resistance": "Potion de résistance au feu",
    "minecraft:potion.effect.harming": "Potion de dégâts",
    "minecraft:potion.effect.healing": "Potion de soin",
    "minecraft:potion.effect.invisibility": "Potion d'invisibilité",
    "minecraft:potion.effect.leaping": "Potion de saut",
    "minecraft:potion.effect.levitation": "Potion de lévitation",
    "minecraft:potion.effect.luck": "Potion de chance",
    "minecraft:potion.effect.mundane": "Potion banale",
    "minecraft:potion.effect.night_vision": "Potion de vision nocturne",
    "minecraft:potion.effect.poison": "Potion de poison",
    "minecraft:potion.effect.regeneration": "Potion de régénération",
    "minecraft:potion.effect.slow_falling": "Potion de chute lente",
    "minecraft:potion.effect.slowness": "Potion de lenteur",
    "minecraft:potion.effect.strength": "Potion de force",
    "minecraft:potion.effect.swiftness": "Potion de rapidité",
    "minecraft:potion.effect.thick": "Potion épaisse",
    "minecraft:potion.effect.turtle_master": "Potion du Maître Tortue",
    "minecraft:potion.effect.water": "Fiole d'eau",
    "minecraft:potion.effect.water_breathing": "Potion de respiration aquatique",
    "minecraft:potion.effect.weakness": "Potion de faiblesse",
    "minecraft:pottery_shard_archer": "Tesson de poterie d'archer",
    "minecraft:pottery_shard_arms_up": "Tesson de poterie de figure",
    "minecraft:pottery_shard_prize": "Tesson de poterie de joyau",
    "minecraft:pottery_shard_skull": "Tesson de poterie de crâne",
    "minecraft:powder_snow_bucket": "Seau de neige poudreuse",
    "minecraft:prismarine_crystals": "Cristaux de prismarine",
    "minecraft:prismarine_shard": "Éclat de prismarine",
    "minecraft:prize_pottery_shard": "Tesson de poterie de joyau",
    "minecraft:prize_pottery_sherd": "Tesson de poterie de joyau",
    "minecraft:pufferfish": "Poisson-globe",
    "minecraft:pufferfish_bucket": "Poisson-globe dans un seau",
    "minecraft:pufferfish_spawn_egg": "Oeuf d'apparition de poisson-globe",
    "minecraft:pumpkin_pie": "Tarte à la citrouille",
    "minecraft:pumpkin_seeds": "Graines de citrouille",
    "minecraft:purple_dye": "Teinture violette",
    "minecraft:quartz": "Quartz du Nether",
    "minecraft:rabbit": "Lapin cru",
    "minecraft:rabbit_foot": "Patte de lapin",
    "minecraft:rabbit_hide": "Peau de lapin",
    "minecraft:rabbit_spawn_egg": "Oeuf d'apparition de lapin",
    "minecraft:rabbit_stew": "Ragoût de lapin",
    "minecraft:raiser_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:ravager_spawn_egg": "Oeuf d'apparition de ravageur",
    "minecraft:raw_copper": "Cuivre brut",
    "minecraft:raw_gold": "Or brut",
    "minecraft:raw_iron": "Fer brut",
    "minecraft:recovery_compass": "Boussole de récupération",
    "minecraft:red_dye": "Teinture rouge",
    "minecraft:redstone": "Poudre de redstone",
    "minecraft:rib_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:rotten_flesh": "Chair putréfiée",
    "minecraft:saddle": "Selle",
    "minecraft:salmon": "Saumon cru",
    "minecraft:salmon_bucket": "Saumon dans un seau",
    "minecraft:salmon_spawn_egg": "Oeuf d'apparition de saumon",
    "minecraft:scute": "Écaille de tortue",
    "minecraft:sentry_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:shaper_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:sheaf_pottery_shard": "Tesson de poterie d'épis",
    "minecraft:sheaf_pottery_sherd": "Tesson de poterie d'épis",
    "minecraft:shears": "Cisailles",
    "minecraft:sheep_spawn_egg": "Oeuf d'apparition de mouton",
    "minecraft:shelter_pottery_shard": "Tesson de poterie d'abri",
    "minecraft:shelter_pottery_sherd": "Tesson de poterie d'abri",
    "minecraft:shield": "Bouclier",
    "minecraft:shield.black": "Bouclier noir",
    "minecraft:shield.blue": "Bouclier bleu",
    "minecraft:shield.brown": "Bouclier marron",
    "minecraft:shield.cyan": "Bouclier cyan",
    "minecraft:shield.gray": "Bouclier gris",
    "minecraft:shield.green": "Bouclier vert",
    "minecraft:shield.light_blue": "Bouclier bleu clair",
    "minecraft:shield.light_gray": "Bouclier gris clair",
    "minecraft:shield.lime": "Bouclier vert clair",
    "minecraft:shield.magenta": "Bouclier magenta",
    "minecraft:shield.orange": "Bouclier orange",
    "minecraft:shield.pink": "Bouclier rose",
    "minecraft:shield.purple": "Bouclier violet",
    "minecraft:shield.red": "Bouclier rouge",
    "minecraft:shield.white": "Bouclier blanc",
    "minecraft:shield.yellow": "Bouclier jaune",
    "minecraft:shulker_shell": "Carapace de Shulker",
    "minecraft:shulker_spawn_egg": "Oeuf d'apparition de Shulker",
    "minecraft:sign": "Pancarte",
    "minecraft:silence_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:silverfish_spawn_egg": "Oeuf d'apparition de poisson d'argent",
    "minecraft:skeleton_horse_spawn_egg": "Oeuf d'apparition de cheval-squelette",
    "minecraft:skeleton_spawn_egg": "Oeuf d'apparition de squelette",
    "minecraft:skull_banner_pattern": "Motif de bannière",
    "minecraft:skull_banner_pattern.desc": "Crâne",
    "minecraft:skull_pottery_shard": "Tesson de poterie de crâne",
    "minecraft:skull_pottery_sherd": "Tesson de poterie de crâne",
    "minecraft:slime_ball": "Boule de Slime",
    "minecraft:slime_spawn_egg": "Oeuf d'apparition de Slime",
    "minecraft:smithing_template": "Modèle de forge",
    "minecraft:smithing_template.applies_to": "S'applique à :",
    "minecraft:smithing_template.armor_trim.additions_slot_description": "Ajoutez un lingot ou un cristal",
    "minecraft:smithing_template.armor_trim.applies_to": "Armure",
    "minecraft:smithing_template.armor_trim.base_slot_description": "Ajoutez une pièce d'armure",
    "minecraft:smithing_template.armor_trim.ingredients": "Lingots et cristaux",
    "minecraft:smithing_template.ingredients": "Ingrédients :",
    "minecraft:smithing_template.netherite_upgrade.additions_slot_description": "Ajoutez un lingot de Netherite",
    "minecraft:smithing_template.netherite_upgrade.applies_to": "Équipement en diamant",
    "minecraft:smithing_template.netherite_upgrade.base_slot_description": "Ajoutez une pièce d'armure, une arme ou un outil en diamant",
    "minecraft:smithing_template.netherite_upgrade.ingredients": "Lingot de Netherite",
    "minecraft:smithing_template.upgrade": "Amélioration : ",
    "minecraft:sniffer_spawn_egg": "Oeuf d'apparition de renifleur",
    "minecraft:snort_pottery_shard": "Tesson de poterie de renifleur",
    "minecraft:snort_pottery_sherd": "Tesson de poterie de renifleur",
    "minecraft:snout_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:snow_golem_spawn_egg": "Oeuf d'apparition de golem de neige",
    "minecraft:snowball": "Boule de neige",
    "minecraft:spectral_arrow": "Flèche spectrale",
    "minecraft:spider_eye": "Oeil d'araignée",
    "minecraft:spider_spawn_egg": "Oeuf d'apparition d'araignée",
    "minecraft:spire_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:splash_potion": "Potion jetable",
    "minecraft:splash_potion.effect.awkward": "Potion étrange jetable",
    "minecraft:splash_potion.effect.empty": "Potion jetable non fabricable",
    "minecraft:splash_potion.effect.fire_resistance": "Potion de résistance au feu jetable",
    "minecraft:splash_potion.effect.harming": "Potion de dégâts jetable",
    "minecraft:splash_potion.effect.healing": "Potion de soin jetable",
    "minecraft:splash_potion.effect.invisibility": "Potion d'invisibilité jetable",
    "minecraft:splash_potion.effect.leaping": "Potion de saut jetable",
    "minecraft:splash_potion.effect.levitation": "Potion de lévitation jetable",
    "minecraft:splash_potion.effect.luck": "Potion de chance jetable",
    "minecraft:splash_potion.effect.mundane": "Potion banale jetable",
    "minecraft:splash_potion.effect.night_vision": "Potion de vision nocturne jetable",
    "minecraft:splash_potion.effect.poison": "Potion de poison jetable",
    "minecraft:splash_potion.effect.regeneration": "Potion de régénération jetable",
    "minecraft:splash_potion.effect.slow_falling": "Potion de chute lente jetable",
    "minecraft:splash_potion.effect.slowness": "Potion de lenteur jetable",
    "minecraft:splash_potion.effect.strength": "Potion de force jetable",
    "minecraft:splash_potion.effect.swiftness": "Potion de rapidité jetable",
    "minecraft:splash_potion.effect.thick": "Potion épaisse jetable",
    "minecraft:splash_potion.effect.turtle_master": "Potion du Maître Tortue jetable",
    "minecraft:splash_potion.effect.water": "Fiole d'eau jetable",
    "minecraft:splash_potion.effect.water_breathing": "Potion de respiration aquatique jetable",
    "minecraft:splash_potion.effect.weakness": "Potion de faiblesse jetable",
    "minecraft:spruce_boat": "Bateau en sapin",
    "minecraft:spruce_chest_boat": "Bateau de stockage en sapin",
    "minecraft:spyglass": "Longue-vue",
    "minecraft:squid_spawn_egg": "Oeuf d'apparition de poulpe",
    "minecraft:stick": "Bâton",
    "minecraft:stone_axe": "Hache en pierre",
    "minecraft:stone_hoe": "Houe en pierre",
    "minecraft:stone_pickaxe": "Pioche en pierre",
    "minecraft:stone_shovel": "Pelle en pierre",
    "minecraft:stone_sword": "Épée en pierre",
    "minecraft:stray_spawn_egg": "Oeuf d'apparition de vagabond",
    "minecraft:strider_spawn_egg": "Oeuf d'apparition d'arpenteur",
    "minecraft:string": "Ficelle",
    "minecraft:sugar": "Sucre",
    "minecraft:suspicious_stew": "Soupe suspecte",
    "minecraft:sweet_berries": "Baies sucrées",
    "minecraft:tadpole_bucket": "Têtard dans un seau",
    "minecraft:tadpole_spawn_egg": "Oeuf d'apparition de têtard",
    "minecraft:tide_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:tipped_arrow": "Flèche à effet",
    "minecraft:tipped_arrow.effect.awkward": "Flèche à eau étrange",
    "minecraft:tipped_arrow.effect.empty": "Flèche à effet non fabricable",
    "minecraft:tipped_arrow.effect.fire_resistance": "Flèche de résistance au feu",
    "minecraft:tipped_arrow.effect.harming": "Flèche de dégâts",
    "minecraft:tipped_arrow.effect.healing": "Flèche de soin",
    "minecraft:tipped_arrow.effect.invisibility": "Flèche d'invisibilité",
    "minecraft:tipped_arrow.effect.leaping": "Flèche de saut",
    "minecraft:tipped_arrow.effect.levitation": "Flèche de lévitation",
    "minecraft:tipped_arrow.effect.luck": "Flèche de chance",
    "minecraft:tipped_arrow.effect.mundane": "Flèche à eau banale",
    "minecraft:tipped_arrow.effect.night_vision": "Flèche de vision nocturne",
    "minecraft:tipped_arrow.effect.poison": "Flèche de poison",
    "minecraft:tipped_arrow.effect.regeneration": "Flèche de régénération",
    "minecraft:tipped_arrow.effect.slow_falling": "Flèche de chute lente",
    "minecraft:tipped_arrow.effect.slowness": "Flèche de lenteur",
    "minecraft:tipped_arrow.effect.strength": "Flèche de force",
    "minecraft:tipped_arrow.effect.swiftness": "Flèche de rapidité",
    "minecraft:tipped_arrow.effect.thick": "Flèche à eau épaisse",
    "minecraft:tipped_arrow.effect.turtle_master": "Flèche du Maître Tortue",
    "minecraft:tipped_arrow.effect.water": "Flèche à eau",
    "minecraft:tipped_arrow.effect.water_breathing": "Flèche de respiration aquatique",
    "minecraft:tipped_arrow.effect.weakness": "Flèche de faiblesse",
    "minecraft:tnt_minecart": "Wagonnet à TNT",
    "minecraft:torchflower_seeds": "Graines de torche-fleur",
    "minecraft:totem_of_undying": "Totem d'immortalité",
    "minecraft:trader_llama_spawn_egg": "Oeuf d'apparition de lama de marchand",
    "minecraft:trident": "Trident",
    "minecraft:tropical_fish": "Poisson tropical",
    "minecraft:tropical_fish_bucket": "Poisson tropical dans un seau",
    "minecraft:tropical_fish_spawn_egg": "Oeuf d'apparition de poisson tropical",
    "minecraft:turtle_helmet": "Carapace de tortue",
    "minecraft:turtle_spawn_egg": "Oeuf d'apparition de tortue",
    "minecraft:vex_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:vex_spawn_egg": "Oeuf d'apparition de Vex",
    "minecraft:villager_spawn_egg": "Oeuf d'apparition de villageois",
    "minecraft:vindicator_spawn_egg": "Oeuf d'apparition de vindicateur",
    "minecraft:wandering_trader_spawn_egg": "Oeuf d'apparition de marchand ambulant",
    "minecraft:ward_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:warden_spawn_egg": "Oeuf d'apparition de Warden",
    "minecraft:warped_fungus_on_a_stick": "Champignon biscornu sur un bâton",
    "minecraft:water_bucket": "Seau d'eau",
    "minecraft:wayfinder_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:wheat": "Blé",
    "minecraft:wheat_seeds": "Graines de blé",
    "minecraft:white_dye": "Teinture blanche",
    "minecraft:wild_armor_trim_smithing_template": "Modèle de forge",
    "minecraft:witch_spawn_egg": "Oeuf d'apparition de sorcière",
    "minecraft:wither_skeleton_spawn_egg": "Oeuf d'apparition de Wither squelette",
    "minecraft:wither_spawn_egg": "Oeuf d'apparition de Wither",
    "minecraft:wolf_spawn_egg": "Oeuf d'apparition de loup",
    "minecraft:wooden_axe": "Hache en bois",
    "minecraft:wooden_hoe": "Houe en bois",
    "minecraft:wooden_pickaxe": "Pioche en bois",
    "minecraft:wooden_shovel": "Pelle en bois",
    "minecraft:wooden_sword": "Épée en bois",
    "minecraft:writable_book": "Livre et plume",
    "minecraft:written_book": "Livre écrit",
    "minecraft:yellow_dye": "Teinture jaune",
    "minecraft:zoglin_spawn_egg": "Oeuf d'apparition de Zoglin",
    "minecraft:zombie_horse_spawn_egg": "Oeuf d'apparition de cheval-zombie",
    "minecraft:zombie_spawn_egg": "Oeuf d'apparition de zombie",
    "minecraft:zombie_villager_spawn_egg": "Oeuf d'apparition de zombie-villageois",
    "minecraft:zombified_piglin_spawn_egg": "Oeuf d'apparition de Piglin zombifié"
}
