import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataSharingService {

    private background: Subject<string> = new Subject<string>();
    private map: Subject<string> = new Subject<string>();

    constructor() {
    }

    public setBackground = (background: string) => {
        localStorage.setItem('background', background);
        this.background.next(background);
    }
    public getBackground = () => this.background.asObservable();

    public setMap = (map: string) => {
        this.map.next(map);
    }
    public getMap = () => this.map.asObservable();
}
