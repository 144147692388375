import {Environment} from "./environment.model";

export const environment: Environment = {
    discordToken: 'MTI3MzIzMzQyMzc4NzYyNjU4MQ.GYKYXx.11nWDhKklp42b7MEb31tyPCRcO479Vh-Fkw_wI',//'MTA4MjU3OTc5NTc1OTU5NTYyMA.GFL8WS.i4-aXUEMEp2hajkAC3r7YHRHre5c5LVrw_mi0s',
    twitchBroadCasterId: '163324442',
    twitchClientId: 'qjt1acyylfavkmj765bjratr8w9kw8',
    twitchClientSecret: '32tz7zj3w750uct28i4wwgnynx8r46',
    twitchStreamer: 'golriver_',
    youtubeApiKey: 'AIzaSyADmxxG5BE5DECqC1dIVJNirYwwaXhkY4w',
    youtubeChannelId: 'UCha52Dw7maJjyFEu4IP6c9g',
    youtubeVodChannelId: 'UCiTti5gcrBO4wOOuDdHC8ZQ',
    youtubeStreamer: 'golriver',
};
