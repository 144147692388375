<img alt="titre comment se connecter" class="logo" src="/assets/img/comment-se-connecter.png">
<h3><b><span class="red-text">/!\ LES CRACKS NE SONT PAS AUTORISÉS /!\ </span></b> 🚫</h3>
<hr>
<h2><b>Ajoute le serveur à ton instance Minecraft</b> 🖥️</h2>
<p>1️⃣ Lance le <b>Launcher Minecraft</b> et clique sur <b>Jouer</b>🎮. Assure-toi d'être bien sur la version <b>fabric-loader-1.21.4</b>.
</p>
<p><img alt="fabric loader" src="https://golriver.fr/api/wiki/images/menu_launcher.png"></p>
<p>2️⃣ Une fois le jeu lancé, la page d'accueil de Minecraft s'ouvre.</p>
<p><img alt="select multiplayer" src="https://golriver.fr/api/wiki/images/btn_mc_multijoueur.png"></p>
<p>3️⃣ Clique sur <b>Multijoueur</b>. Ensuite, dans la page qui s'ouvre, clique sur <b>Nouveau serveur</b>.</p>
<p><img alt="add new server" src="https://golriver.fr/api/wiki/images/btn_nouveau_serveur.png"></p>
<p>4️⃣ Dans la fenêtre <b>Modification des informations du serveur</b>, entre les informations suivantes :</p>
<ul>
    <li><b>Nom du serveur</b> : <em>CHOMAGERIE</em></li>
    <li><b>Adresse du serveur</b> : <em>chomagerie.golriver.fr</em></li>
</ul>
<p>5️⃣ Puis, clique sur <b>Terminé</b>.</p>
<p><img alt="set server infos" src="https://golriver.fr/api/wiki/images/mc_connection_server_menu.png"></p>
<p>6️⃣ Tu peux maintenant rejoindre le serveur en cliquant sur <em>Chomagerie</em>. </p>
<p><img alt="list server" src="https://golriver.fr/api/wiki/images/mc_server_desc.png"></p>
<hr>
<h2><b>Lie ton compte Minecraft ⛏️ avec Discord</b> 🔗</h2>
<p>Sur discord dans n'importe quel salon, tape la commande /verify et insère le code que tu as reçu dans Minecraft.<br>
    <img alt="need to validate" src="https://golriver.fr/api/wiki/images/code_mc.png"><br>
    <img alt="discord command" src="https://golriver.fr/api/wiki/images/discord_verify.png"></p>
<hr>
<h2>🆘<b> En cas de problème de connexion</b> ⚠️</h2>
<blockquote>
    <p>Si tu rencontres un problème malgré le tutoriel, <b>merci de nous faire une capture d'écran du message
        d’erreur</b> 📸 et d'expliquer ton problème dans le canal <b><a
        href="https://discord.com/channels/747377606479052810/1117116128779567185">Discussion du serveur Discord 🗣️</a></b>.
    </p>
</blockquote>
