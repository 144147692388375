import {Component} from '@angular/core';
import {DataSharingService} from "../../../../services/data-sharing.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-comment-se-connecter',
    standalone: true,
    imports: [],
    templateUrl: './comment-se-connecter.component.html',
    styleUrls: ['./comment-se-connecter.component.scss', "../../wikis.scss"]
})
export class CommentSeConnecterComponent {
    constructor(private dataSharingService: DataSharingService, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.data.subscribe(data => {
            this.dataSharingService.setMap(data['isMap']);
        });
    }
}
