import {Component} from '@angular/core';
import {DataSharingService} from "../../../../services/data-sharing.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-qu-est-ce',
    standalone: true,
    imports: [],
    templateUrl: './qu-est-ce.component.html',
    styleUrls: ['./qu-est-ce.component.scss', '../../wikis.scss']
})
export class QuEstCeComponent {
    constructor(private dataSharingService: DataSharingService, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.data.subscribe(data => {
            this.dataSharingService.setMap(data['isMap']);
        });
    }
}
