<div id="server-edit">
    <h3>Modifier les informations du serveur</h3>
    <div class="server-edit-form">
        <div class="form-group">
            <label for="name">Nom du serveur</label>
            <div class="input-group">
                <input [value]="server?.name" disabled id="name" type="text"/>
            </div>
        </div>
        <div class="form-group">
            <label for="ip-server">Adresse/IP du serveur</label>
            <div class="input-group ip-copy-container">
                <input [value]="server?.url" disabled id="ip-server" type="text"/>
                <button (click)="playClickSound()" cdkCopyToClipboard="{{server?.url}}" class="copy-button">
                    <span class="material-symbols-outlined">content_copy</span>
                </button>
            </div>
        </div>
    </div>
    <div class="minecraft-buttons">
        <div (click)="close()" class="minecraft-button">
            <div class="title">Terminer</div>
        </div>
        <div (click)="close()" class="minecraft-button">
            <div class="title">Annuler</div>
        </div>
    </div>
</div>
