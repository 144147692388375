import {Routes} from '@angular/router';
import {HomeComponent} from '../components/home/home.component';
import {SoloComponent} from '../components/solo/solo.component';
import {YoutubeVideoComponent} from '../components/youtube-video/youtube-video.component';
import {ServeurSubSurvieComponent} from '../components/serveur-sub-survie/serveur-sub-survie.component';
import {OptionsComponent} from "../components/options/options.component";
import {RessourcePackComponent} from "../components/ressource-pack/ressource-pack.component";
import {ShadersComponent} from "../components/shaders/shaders.component";
import {OptionsBackgroundComponent} from "../components/options-background/options-background.component";
import {ServerWikiComponent} from "../components/server-wiki/server-wiki.component";
import {MapComponent} from "../components/map/map.component";
import {ServerListComponent} from "../components/server-list/server-list.component";
import {Status404Component} from "../components/status-404/status-404.component";
import {QuEstCeComponent} from "../components/wikis/survie/qu-est-ce/qu-est-ce.component";
import {QuiPeutRejoindreComponent} from "../components/wikis/survie/qui-peut-rejoindre/qui-peut-rejoindre.component";
import {ReglesComponent} from "../components/wikis/survie/regles/regles.component";
import {CreerUneInstanceComponent} from "../components/wikis/survie/creer-une-instance/creer-une-instance.component";
import {
    CommentSeConnecterComponent
} from "../components/wikis/survie/comment-se-connecter/comment-se-connecter.component";
import {LesPetitsPlusComponent} from "../components/wikis/survie/les-petits-plus/les-petits-plus.component";
import {OutilsComponent} from "../components/wikis/survie/outils/outils.component";

export const routes: Routes = [
    {path: 'home', component: HomeComponent},
    {path: 'solo', component: SoloComponent},
    {path: 'server-list', component: ServerListComponent},
    {
        path: 'survival-server-sub', component: ServeurSubSurvieComponent, children: [
            {path: 'qu-est-ce', component: QuEstCeComponent},
            {path: 'qui-peut-rejoindre', component: QuiPeutRejoindreComponent},
            {path: 'regles', component: ReglesComponent},
            {path: 'creer-une-instance', component: CreerUneInstanceComponent},
            {path: 'comment-se-connecter', component: CommentSeConnecterComponent},
            {path: 'les-petits-plus', component: LesPetitsPlusComponent},
            {path: 'outils', component: OutilsComponent},
            {path: 'wiki', component: ServerWikiComponent, data: {isMap: "no-back"}},
            {path: 'map', component: MapComponent, data: {isMap: "map"}},
            {path: '**', pathMatch: 'full', redirectTo: 'qu-est-ce'}
        ]
    },
    {path: 'vod', component: YoutubeVideoComponent},
    {path: 'options', component: OptionsComponent},
    {path: 'resource-pack', component: RessourcePackComponent},
    {path: 'shaders', component: ShadersComponent},
    {path: 'options-background', component: OptionsBackgroundComponent},
    {path: '404', component: Status404Component},
    {path: '', pathMatch: 'full', redirectTo: '/home'},
    {path: '**', pathMatch: 'full', redirectTo: '/404'}
];
