import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MainComponents} from "../main.components";

@Component({
    selector: 'app-options',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
    ],
    templateUrl: './options.component.html',
    styleUrl: './options.component.scss'
})
export class OptionsComponent extends MainComponents {

}
