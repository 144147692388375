<h2>👥<b> Qui peut rejoindre le Meilleur Serveur Multijoueurs Communautaire ?</b></h2>
<p>🎟️ Le serveur est ouvert à tous les subs : </p>
<ul>
    <li><b>Sub classique</b> 🟡</li>
    <li><b>Sub offert</b> 🎁</li>
    <li><b>Sub Prime</b> 👑</li>
</ul>
<p>⚠️ <b>Attention</b> : </p>
<ul>
    <li>Le serveur est réservé aux joueurs possédant <b>Minecraft Java</b>.</li>
    <li>Les versions crackées ou Bedrock 🚫 ne sont pas acceptées.</li>
</ul>

<p>🌟 Sur la <b>CHOMAGERIE</b>, tout le monde y trouve sa place !<br>🤝 L’entraide, la bienveillance et le
    respect sont au cœur de la communauté des <b>Chômeurs</b>. </p>
<br>
<h3>🎈 Tu y rencontreras une diversité incroyable de joueurs :</h3>
<ul>
    <li>🏰 <b>Builders fous et débutants</b></li>
    <li>⚙️ <b>Redstoneurs dingues et novices</b></li>
    <li>🏹 <b>Tryhardeurs et chilleurs</b></li>
    <li>⛏️ <b>Experts et débutants en survie</b></li>
    <li>🌋 <b>Experts et débutants en destruction de monde</b></li>
    <li>🕳️ <b>Creuseurs de trous et explorateurs</b></li>
    <li>👣 <b>Amoureux des animaux ou tueurs de chats</b> (⚔️ <em>Règle numéro 1 du serveur : On
        ne tue pas les PotiChats😿 sinon rendez-vous 1vs1 dans l’arène du serveur : le PvP est interdit
        ailleurs</em>)
    </li>
    <li>👥 <b>Joueurs en solo ou en team</b></li>
</ul>
<p>🎮 <b>Quel que soit ton niveau ou ta façon de jouer</b>, rejoins le <b>meilleur des serveurs</b> !
</p>
