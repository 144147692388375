import {Component} from '@angular/core';
import {MainComponents} from "../main.components";

@Component({
    selector: 'app-status-404',
    standalone: true,
    imports: [],
    templateUrl: './status-404.component.html',
    styleUrls: ['./status-404.component.scss']
})
export class Status404Component extends MainComponents {

    constructor() {
        super();
    }

    back = () => {
        this.playClickSound();
        window.history.back();
    };

}
