import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class BackgroundService {
    private apiUrl = 'https://golriver.fr/api/panoramas';

    constructor(
        private http: HttpClient,
    ) {
    }

    background = (): Observable<Background[]> =>
        this.http
            .get<Background[]>(`${this.apiUrl}/panorama.json`);
    backgroundDefault = (): Observable<string> =>
        this.http
            .get<Background[]>(`${this.apiUrl}/panorama.json`)
            .pipe(
                map((backgrounds: Background[]) => {
                    const bg = backgrounds.find(bg => bg.default);
                    return bg ? bg.path : '';
                })
            );
}

export interface Background {
    name: string;
    path: string;
    default: boolean;
}
