import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeHtml, SafeResourceUrl} from "@angular/platform-browser";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ServeurSubService {

    private api = 'https://api.mcsrvstat.us/3/';

    constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {

    }

    serverStat = (server: ServerName): Observable<ServerStat> => {
        return this.http.get(`${this.api}${server.url}`)
            .pipe(map((data: any) => {
                const playerList = data['players']['list']?.map((player: any) => {
                    return {
                        name: player.name,
                        icon: this.domSanitizer.bypassSecurityTrustResourceUrl(`https://minotar.net/helm/${player.name}`)
                    };
                });
                const motd = {
                    raw: data['motd']['raw'],
                    clean: data['motd']['clean'],
                    html: data['motd']['html'].map((html: string) => this.domSanitizer.bypassSecurityTrustHtml(html) as SafeHtml)
                }
                const serverStat: ServerStat = {
                    name: server.name,
                    url: server.url,
                    limit: 0 || data['players']['max'],
                    count: playerList ? playerList.length : 0,
                    players: playerList || [],
                    playersName: playerList ? playerList.map((player: any) => player.name).join('\n') : '',
                    motd,
                    icon: data['icon'] || null,
                    navigateTo: server.navigateTo,
                };
                return serverStat;
            }));
    }
}

export interface ServerStat {
    name: string;
    url: string;
    limit: number;
    count: number;
    players: {
        name: string;
        icon: SafeResourceUrl;
    }[],
    playersName: string;
    motd: { raw: string[], clean: string[], html: SafeHtml[] },
    icon: string;
    navigateTo: string;
}

export interface ServerName {
    name: string;
    url: string;
    navigateTo: string;
}
