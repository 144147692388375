import {Component} from '@angular/core';

@Component({
    selector: 'app-regles',
    standalone: true,
    imports: [],
    templateUrl: './regles.component.html',
    styleUrls: ['./regles.component.scss', "../../wikis.scss"]
})
export class ReglesComponent {

}
