<div class="solo-page">
    <div class="content-page mobile-no-scroll">
        <div *ngIf="videos && videos.length>=currentId && videos[currentId]" class="youtube-video">
            <div class="youtube-iframe">
                <iframe [src]="videos[currentId].embeddedUrl"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen height="315"
                        referrerpolicy="strict-origin-when-cross-origin"
                        title="YouTube video player" width="560"></iframe>
            </div>
            <div class="youtube-div">
                <div class="video-info">
                    <div class="youtube-resume">
                        <div [textContent]="videos[currentId].title" class="youtube-title"></div>
                        <div class="youtube-published youtube-common">
                            <span class="material-symbols-outlined">event_available</span>
                            <span [textContent]="videos[currentId].published|date:'dd/MM/yyyy'"> </span>
                        </div>
                    </div>
                    <div class="youtube-stats">
                        <div class="youtube-views youtube-common">
                            <span class="material-symbols-outlined">visibility</span>
                            <span [textContent]="videos[currentId].views"> </span>
                        </div>
                        <div class="youtube-likes youtube-common">
                            <span class="material-symbols-outlined">thumb_up</span>
                            <span [textContent]="videos[currentId].like"> </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="golriver-info">
            <div class="stat-golriver">
                <div *ngFor="let item of statData|keyvalue" class="stat-detail">
                    <div class="stat-icon">
                        <img [alt]="item.key" [src]="item.value.icon" width="40">
                    </div>
                    <div class="stat-info">
                        <div [textContent]="item.key" class="stat-title"></div>
                        <div class="stat-details">
                            <div [textContent]="item.value.count" class="stat-value"></div>
                            <div *ngIf="item.value.memberOnline" [textContent]="item.value.memberOnline + ' en ligne'"
                                 class="stat-online"></div>
                        </div>
                    </div>
                    <div *ngIf="item.value.status" class="stat-status">En live</div>
                </div>
            </div>
            <div #customScollbar *ngIf="videos && videos.length>=currentId && videos[currentId]"
                 class="video-description-container">
                <div [innerHtml]="videos[currentId].description" class="video-description"></div>
            </div>
        </div>
    </div>
    <app-hotbar (changed)="videoChanged($event)" [items]="videos"></app-hotbar>
</div>
