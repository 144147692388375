<ng-container *ngIf="(options|async) as opts">
    <ngx-view360 [class.no-blur]="noBlur" [options]="opts"/>
</ng-container>
<div class="main-page">
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <div class="footer">
        <div class="minecraft-version">Version 1.21.4 - Ce site n'est pas un site officiel de Minecraft. Nous ne sommes
            pas affiliés à Mojang ou Microsoft.
        </div>
        <div class="site-info">
            <span (click)="credits()" class="credits">Crédits</span>
            <span class="copyright"> &copy; GOLRIVER {{ year }}</span>
        </div>
    </div>
</div>
