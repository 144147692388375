import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TwitchService {

    private twitchAuthApi = 'https://id.twitch.tv/oauth2/token';
    private twitchApi = 'https://api.twitch.tv/helix/';
    private twitchChannelApi = `${this.twitchApi}users`;
    private twitchFollowerApi = `${this.twitchApi}channels/followers`;
    private twitchStreamApi = `${this.twitchApi}streams`;
    private twitchBroadcasterId = environment.twitchBroadCasterId;
    private clientId = environment.twitchClientId;
    private clientServer = environment.twitchClientSecret;
    private streamer = environment.twitchStreamer;

    constructor(private http: HttpClient) {
    }

    authentificate = (): Observable<TwitchToken> => {
        const body = {
            'grant_type': 'client_credentials',
            'client_id': this.clientId,
            'client_secret': this.clientServer
        };
        return this.http.post<TwitchToken>(this.twitchAuthApi, body);
    };

    followersCount = (token: any): Observable<TwitchStats> => {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${token}`)
            .set('Client-ID', this.clientId);
        const params = new HttpParams()
            .set('broadcaster_id', this.twitchBroadcasterId);
        const options = {params, headers};
        return this.http.get<TwitchStats>(this.twitchFollowerApi, options);
    };

    streamStatus = (token: any): Observable<TwitchStreamData> => {
        const headers = new HttpHeaders({
            'Client-Id': this.clientId,
            'Authorization': `Bearer ${token}`
        })
        const params = new HttpParams()
            .set('user_login', this.streamer);
        return this.http.get<TwitchStreamData>(this.twitchStreamApi, {headers, params});
    };

    channelInfo = (token: any) => {
        const headers = new HttpHeaders({
            'Client-Id': this.clientId,
            'Authorization': `Bearer ${token}`
        })
        const params = new HttpParams()
            .set('login', this.streamer);
        return this.http.get(this.twitchChannelApi, {headers, params})
            .pipe(map((data: any) => {
                data.data[0].description = data.data[0].description
                    .replaceAll('golriver.pro@gmail.com', '<a href="mailto:golriver.pro@gmail.com">golriver.pro@gmail.com</a>')
                    .replaceAll('\n', '<br />');
                return data
            }));
    };
}

export interface TwitchToken {
    access_token: string;
    expire_in: number;
    token_type: string;
}

export interface TwitchStats {
    data: any[];
    pagination: any;
    total: number;
}

export interface TwitchStreamDetailsData {
    game_id: string;
    game_name: string;
    id: string;
    is_mature: boolean;
    language: string;
    tag_ids: any[];
    tags: string[];
    thumbmail_url: string;
    title: string;
    type: string;
    user_id: string;
    user_login: string;
    viewer_count: number;
}

export interface TwitchStreamData {
    data: TwitchStreamDetailsData[];
    pagination: { cursor: string };
}

export interface TwitchChannelInfos {
    data: TwitchChannelDetails[];
}

export class TwitchChannelDetails {
    broadcaster_id: string = '';
    broadcaster_login: string = '';
    broadcaster_name: string = '';
    broadcaster_language: string = '';
    game_id: string = '';
    game_name: string = '';
    title: string = '';
    delay: number = 0;
    tags: string[] = [];
    content_classification_labels: string[] = [];
    is_branded_content: boolean = false;

    constructor() {
    }
}
