import {Component} from '@angular/core';
import {DataSharingService} from "../../../../services/data-sharing.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-les-petits-plus',
    standalone: true,
    imports: [],
    templateUrl: './les-petits-plus.component.html',
    styleUrls: ['./les-petits-plus.component.scss', "../../wikis.scss"]
})
export class LesPetitsPlusComponent {
    constructor(private dataSharingService: DataSharingService, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.data.subscribe(data => {
            this.dataSharingService.setMap(data['isMap']);
        });
    }
}
