import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataPackService {

    private api: string = 'https://golriver.fr/api/golrisub/data-pack';

    constructor(private http: HttpClient) {
    }

    dataPack = (): Observable<DataPack[]> => {
        return this.http.get<DataPack[]>(this.api);
    };
}

export interface DataPack {
    type: string;
    category?: string;
    cookingtime?: number;
    experience?: number;
    group?: string;
    ingredient?: string;
    result: Result;
    ingredients?: string[][];
    pattern?: string[];
    key?: { [symbol: string]: Ingredient };
}

export interface Ingredient {
    item?: string;
    tag?: string;
}

export interface Result {
    id: string;
    count?: number;
}
