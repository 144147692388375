<div class=multi-players>
    <div class="header">
        <div class="top">
            <img alt="titre du serveur chomagerie" src="assets/img/chomagerie_title.png"/>
            <div class="bottom no-display-mobile">
                <div class="text">Actuellement en ligne :</div>
                <div class="players">
                    <ng-container *ngIf="players && players.players; else noPlayer">
                        <img *ngFor="let player of players.players" [alt]="player.name" [src]="player.icon"
                             [title]="player.name"/>
                    </ng-container>
                    <ng-template #noPlayer>Aucun joueurs</ng-template>
                </div>
            </div>
            <div *ngIf="players" class="players-live-counter no-display-mobile">
                {{ players.count }}/{{ players.limit }}
            </div>
            <img alt="logo chomagerie" class="no-display-mobile" src="assets/img/chomagerie.png"/>
        </div>

    </div>
    <div class="content">
        <div class="lateral-bar">
            <div class="menu">
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'qu-est-ce']" class="menu-item"
                     routerLinkActive="selected">- Qu'est-ce que le serveur CHOMAGERIE ?
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'qui-peut-rejoindre']" class="menu-item"
                     routerLinkActive="selected">- Qui peut rejoindre le serveur ?
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'regles']" class="menu-item" routerLinkActive="selected">
                    - Règles
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'creer-une-instance']" class="menu-item"
                     routerLinkActive="selected">- Créer une instance
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'comment-se-connecter']" class="menu-item"
                     routerLinkActive="selected">- Comment se connecter ?
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'les-petits-plus']" class="menu-item"
                     routerLinkActive="selected">- Les petits plus du serveur ?
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'wiki']" class="menu-item" routerLinkActive="selected">
                    - Crafts
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'map']" class="menu-item no-display-mobile"
                     routerLinkActive="selected">- Map
                </div>
                <div (click)="playClickSound()" [routerLink]="['/survival-server-sub', 'outils']" class="menu-item" routerLinkActive="selected">
                    - Outils
                </div>
            </div>
            <div class="div-button">
                <div (click)="playClickSound()" [routerLink]="'/home'" class="button">Retour à l'accueil</div>
                <div (click)="playClickSound()" [routerLink]="'/server-list'" class="button">Déconnexion</div>
            </div>
        </div>
        <div class="details">
            <div [class.map]="(isMap|async)==='map'"
                 [class.no-background]="(isMap|async)==='no-back'" class="details-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
