<h2>📖 <b>Qu’est-ce que le serveur CHOMAGERIE ?</b></h2>
<p>Le <b>Serveur Sub Survie CHOMAGERIE</b> est un serveur Minecraft Vanilla multijoueur créé pour les abonnés
    de la chaine Twitch de <a href="https://twitch.tv/golriver_">GOLRIVER</a> 📺. </p>
<ul>
    <li>🗓️ <b>Date de lancement</b> : 14 juillet 2023</li>
    <li>🌍 <b>Type de jeu</b> : Survie Vanilla</li>
    <li>🤝 <b>Objectif</b> : Proposer une expérience communautaire basée sur l’entraide et la créativité.</li>
</ul>
<p>Rejoins une aventure où <b>tous les joueurs contribuent à bâtir un univers incroyable</b>, dans une
    ambiance fun et bienveillante. </p>
