import {Component, ViewEncapsulation} from '@angular/core';
import {ServerStat, ServeurSubService} from "../../services/serveur-sub.service";
import {combineLatest, Observable, of} from "rxjs";
import {AsyncPipe, CommonModule} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MainComponents} from "../main.components";
import {ServerEditComponent} from "../server-edit/server-edit.component";

@Component({
    selector: 'app-server-list',
    standalone: true,
    imports: [
        AsyncPipe,
        CommonModule,
        RouterLink,
        ServerEditComponent
    ],
    templateUrl: './server-list.component.html',
    styleUrl: './server-list.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class ServerListComponent extends MainComponents {
    serversUrl = [{
        name: "Serveur Sub Survie",
        url: "chomagerie.golriver.fr",
        navigateTo: "/survival-server-sub"
    }, {
        name: "Serveur Sub Créatif",
        url: "chomagerie.golriver.fr:25566",
        navigateTo: "/creative-server-sub"
    }];
    currentServer?: ServerStat;
    servers: Observable<ServerStat[]> = of([]);
    showEdit = false;

    constructor(private serverSubService: ServeurSubService) {
        super();
        this.getServerStat();
    };

    goToServer(server: ServerStat) {
        this.currentServer = server;
        this.playClickSound();
    }

    getServerStat() {
        this.servers = of([]);
        combineLatest(this.serverSubService.serverStat(this.serversUrl[0]), this.serverSubService.serverStat(this.serversUrl[1])).subscribe(([server1, server2]) => {
            this.servers = of([server1, server2]);
        });
    }

    refrechServerStat() {
        this.getServerStat();
        this.playClickSound();
    }

    toggleEdit() {
        this.showEdit = !this.showEdit;
        this.playClickSound();
    }
}
